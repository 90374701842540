import { useCallback, useRef, useState } from 'react';

/**
 * useGuardedState is a version of the useState hook which prevents the setter
 * from updating state when the value hasn't changed.
 */
const useGuardedState = (defaultValue) => {
  const [val, setter] = useState(defaultValue);
  const ref = useRef(defaultValue);
  const guardedSetter = useCallback(
    (newVal) => {
      if (newVal !== ref.current) {
        ref.current = newVal;
        setter(newVal);
      }
    },
    [ref, setter]
  );

  return [val, guardedSetter];
};

export default useGuardedState;
