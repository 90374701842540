class RecommendationItem {
  constructor({ id, parentId, type, title, image, description, rating }) {
    this._id = id;
    this._parentId = parentId;
    this._type = type;
    this._title = title;
    this._image = image;
    this._description = description;
    this._rating = rating;
  }

  // getters and setters
  get id() {
    return this._id;
  }

  set id(id) {
    this._id = id;
  }

  get parentId() {
    return this._parentId;
  }

  set parentId(parentId) {
    this._parentId = parentId;
  }

  get type() {
    return this._type;
  }

  set type(type) {
    this._type = type;
  }

  get title() {
    return this._title;
  }

  set title(title) {
    this._title = title;
  }

  get expireDate() {
    return this._expireDate;
  }

  set expireDate(expireDate) {
    this._expireDate = expireDate;
  }

  get isAvailable() {
    return this._isAvailable;
  }

  set isAvailable(isAvailable) {
    this._isAvailable = isAvailable;
  }

  get remainingDays() {
    return this._remainingDays;
  }

  set remainingDays(remainingDays) {
    this._remainingDays = remainingDays;
  }

  get image() {
    return this._image;
  }

  set image(image) {
    this._image = image;
  }

  get description() {
    return this._description;
  }

  set description(description) {
    this._description = description;
  }

  get rating() {
    return this._rating;
  }

  set rating(rating) {
    this._rating = rating;
  }
}

export default RecommendationItem;
