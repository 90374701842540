import { makeRequest } from '../common/base';
import { getConfig } from '../../configuration';

/**
 * Gets an app configuration object
 * @returns {Promise<{}>} A promise containing a JSON data object
 */
const fetchAppConfig = async () => {
  if (getConfig().useMocks) {
    return Promise.resolve(import('../mocks/appConfig.json'));
  }

  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return makeRequest('/appconfig', requestOptions, true);
};

export { fetchAppConfig };
