import { v4 as uuidv4 } from 'uuid';
import { isFeatureFlagEnabled } from '../utils/feature-flags';
import speak from '../utils/TextToSpeech';
import { getDeviceInformationForWeb } from '../utils/utils';
import APP_CONFIG from '../config/app-config.json';
import { SAMPLE_NON_DRM_DASH_URL } from '../crackle-sdk/v1/api/common/vars';
import PLAYER_AD_BREAK_INSERT_TYPES from '../enums/player-ad-break-insert-types';
import PLATFORM_CAPABILITIES from '../enums/platform-capabilities';

const KEYCODES = {
  BACK: [8, 461],
  DOWN: 40,
  ENTER: 13,
  SPACE: 32,
  EXIT: 27,
  LEFT: 37,
  MEDIA_PAUSE: 19,
  MEDIA_FAST_FORWARD: 417,
  MEDIA_PLAY: 415,
  MEDIA_PLAY_PAUSE: 463,
  MEDIA_REWIND: 412,
  MEDIA_STOP: 413,
  MEDIA_NEXT: 418,
  MEDIA_PREVIOUS: 419,
  RIGHT: 39,
  UP: 38,
  CC: null,
  SEARCH: null,
  DELETE_CHAR: null,
  ENTER_CHAR: null,
  NAV_RIGHT: null,
  NAV_LEFT: null,
  CHANNEL_UP: 427,
  CHANNEL_DOWN: 428,
  NUMBER_0: 48,
  NUMBER_1: 49,
  NUMBER_2: 50,
  NUMBER_3: 51,
  NUMBER_4: 52,
  NUMBER_5: 53,
  NUMBER_6: 54,
  NUMBER_7: 55,
  NUMBER_8: 56,
  NUMBER_9: 57,
};

let deviceInfo = {
  modelName: null,
  duid: null,
  series: null,
  firmware: null,
};

const adInfo = {
  IFA: uuidv4(),
  IFA_TYPE: null,
  LMT: '1',
};

const supportedAdInsertionMode = [
  PLAYER_AD_BREAK_INSERT_TYPES.CSAI,
  PLAYER_AD_BREAK_INSERT_TYPES.CSAIV2,
  PLAYER_AD_BREAK_INSERT_TYPES.SSAI,
];
const platformCapabilities = {
  // limit ad tracking
  [PLATFORM_CAPABILITIES.LIMIT_AD_TRACKING]: true,
  [PLATFORM_CAPABILITIES.CCPA]: true,
  [PLATFORM_CAPABILITIES.VIZBEE]: false,
  // Does the platform support automatic arial label text-to-speech
  [PLATFORM_CAPABILITIES.ARIA_LABEL]: true,
  // Does the platform supports exiting the app?
  [PLATFORM_CAPABILITIES.EXIT]: true,
  // Does the platform has a specific CC button in the remote? If not, we will use the CC button in the player
  [PLATFORM_CAPABILITIES.CC_BUTTON]: false,
  // Does the platform has a specific exit button in the remote and allow to override the default exit behavior?
  [PLATFORM_CAPABILITIES.EXIT_BUTTON]: false,
  // Does the platform support iframe srcdoc attribute for sponsors?
  [PLATFORM_CAPABILITIES.IFRAME_SRC_DOC]: true,
  // Does the platform have platformSessionId?
  [PLATFORM_CAPABILITIES.PLATFORM_SESSION_ID]: false,
};

const initializeDevice = (onLoad, onResume) => {
  if (typeof window?.Hisense_GetDeviceID === 'function') {
    deviceInfo.duid =
      window.Hisense_GetDeviceID() || window.Hisense_GetDeviceID(32);
  }

  if (typeof window?.Hisense_GetModelName === 'function') {
    deviceInfo.modelName = window.Hisense_GetModelName();
  }

  if (typeof window?.Hisense_GetFirmWareVersion === 'function') {
    deviceInfo.firmware = window.Hisense_GetFirmWareVersion();
  }

  onLoad();

  if (isTv()) {
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        // Trigger a platform launch on resume app
        if (onResume && isTv()) {
          onResume();
        }
      }
    });
  } else {
    onLoad();
  }
};

const getDeviceId = (callback) => {
  const deviceId =
    window?.Hisense_GetDeviceID() || window?.Hisense_GetDeviceID(32);

  return callback(deviceId);
};

const isTv = () => {
  return typeof window?.Hisense_GetDeviceID === 'function';
};

const isLowEndDevice = () => {
  return false;
};

const relaunchApplication = () => {
  window?.location.reload();
};

const setClosedCaptionCallback = () => void 0;

const setClosedCaptionStyleCallback = () => void 0;

const exitApplication = () => {
  window.close();
};

const supportCapability = (capability) => {
  return platformCapabilities[capability];
};

const getAdvertisementIdentifier = (callback) => {
  getAdParams((adParams) => {
    // if do not track is disable and ifa exists return ifa otherwise return a uuid
    callback(adParams.ifa);
  });
};

const getLimitAdTracking = (callback) => {
  getAdParams((adParams) => {
    callback(adParams.dnt);
  });
};

const getAdsType = (callback) => {
  getAdParams((adParams) => {
    callback(adParams.ifa_type);
  });
};

const isVoiceGuideEnabled = () => {
  let isTtsEnable = false;
  if (typeof window.Hisense_GetTTSEnable === 'function') {
    isTtsEnable = window.Hisense_GetTTSEnable();
  }

  return isTtsEnable;
};

// tts function
const tts = (text) => {
  isVoiceGuideEnabled() && speak(text);
};

/**
 * Get the Device's Model
 * @returns {String |null}
 */
const getDeviceModel = () => {
  return deviceInfo.modelName;
};

/**
 * Get device info from TV
 * @returns promise
 */
const getDeviceInformation = () => {
  return new Promise((resolve) => {
    if (!isTv()) {
      deviceInfo = getDeviceInformationForWeb();
    }
    resolve(deviceInfo);
  });
};

const getPlatformSessionId = () => null;

/**
 * Get network info from TV
 * @returns promise
 */
const getNetworkInformation = () => {
  return new Promise((resolve) => {
    // TODO: update function with Vidaa specifics
    resolve({ ip: null });
  });
};

/**
 * Get platform device name
 * @returns {String}
 */
const getPlatformDeviceName = () => {
  return `${APP_CONFIG.PLATFORM} (${getDeviceModel()})`;
};

/**
 * gets deeplink path
 * @returns {String}
 */
const getDeeplinkPath = () => {
  if (isFeatureFlagEnabled('testForceDeeplink')) {
    return '/watch/545158AE-AD7D-4325-980F-93421E200D5F?cmpid=Christmas2021';
  }

  const regexp =
    /\/((.*)\/)*watch\/([^?]+)(.*)?|\/((.*)\/)*sponsored_collections\/([^?]+)(.*)?/;

  if (document.location.pathname.match(regexp) !== null) {
    return document.location.pathname + document.location.search;
  }

  return '';
};

const getDeviceOS = () => 'VIDAA';

const getDeviceOsVersion = () =>
  deviceInfo.firmware
    ? deviceInfo.firmware
    : window?.navigator?.userAgent?.VIDAA_Version;

const getDeviceMaker = () =>
  typeof window?.Hisense_GetBrand === 'function'
    ? window.Hisense_GetBrand()
    : 'Hisense';
// could be // "Hisense"/"Vision+"/"SCHNEIDER"/"Master-G"/"Medion"

function getTTSCancelationHTML() {
  return ``;
}

const getDeviceConnectionType = () => {
  let connectionType = navigator?.connection?.effectiveType;

  if (typeof window?.Hisense_GetNetType === 'function') {
    connectionType = window?.Hisense_GetNetType();
  }

  return new Promise((resolve) => {
    resolve(connectionType);
  });
};

const getStreamSource = (streams) => {
  const dashUrl =
    streams?.dash_widevine?.url || streams?.['dash-widevine']?.url || null;
  const drm = {
    widevine: {
      LA_URL:
        streams?.dash_widevine?.drm?.keyUrl ||
        streams?.['dash-widevine']?.drm?.keyUrl ||
        null,
    },
  };
  const sourceStreamType =
    streams?.dash_widevine?.url || streams?.['dash-widevine']?.url
      ? 'dash_widevine'
      : null;

  return {
    dash: isFeatureFlagEnabled('testNoDRMSampleVideo')
      ? SAMPLE_NON_DRM_DASH_URL
      : dashUrl,
    sourceStreamType,
    drm,
  };
};

const getPlayerConfig = () => {
  return {};
};

const getKeyHintIcon = () => {
  return null;
};

const showKeyHints = () => {
  return false;
};

const getPlatformSpecificModules = () => {
  return [];
};

const getAdParams = (callback) => {
  window?.VIDAA_getAdsParameters_V2(
    { client_id: APP_CONFIG.CLIENT_ID, app_id: APP_CONFIG.APP_ID },
    (params) => {
      let adParams = {
        lat: adInfo.LMT,
        ifa: adInfo.IFA,
        ifaType: adInfo.IFA_TYPE,
        ccpa: null,
      };

      if (params.adsParams) {
        const {
          dnt,
          ifa,
          ifa_type: ifaType,
          app_bundle: appBundle,
          app_name: appName,
          app_store_url: appStoreUrl,
          brand_name: brandName,
          vauth,
          device_country: deviceCountry,
          device_osv: deviceOsv,
          device_language: deviceLanguage,
          us_privacy: usPrivacy,
          gdpr,
          gdpr_consent: gdprConsent,
        } = params.adsParams;

        adParams = {
          lat: dnt.toString(),
          ifa: dnt === 0 && ifa ? ifa : adInfo.IFA,
          ifaType: ifaType || adInfo.IFA_TYPE,
          ccpa: usPrivacy,
          app_bundle: appBundle,
          app_name: appName,
          app_store_url: appStoreUrl,
          brand_name: brandName,
          vauth,
          device_country: deviceCountry,
          device_osv: deviceOsv,
          device_language: deviceLanguage,
          ua: window?.navigator?.userAgent || '',
          gdpr: gdpr.toString(),
          gdpr_consent: gdprConsent.toString(),
        };
      }

      callback(adParams);
    }
  );
};

const isAdInsertionModeSupported = (mode) =>
  supportedAdInsertionMode.includes(mode);

export {
  getDeviceId,
  getDeviceModel,
  initializeDevice,
  isTv,
  isLowEndDevice,
  relaunchApplication,
  setClosedCaptionCallback,
  setClosedCaptionStyleCallback,
  getDeviceInformation,
  getPlatformSessionId,
  getNetworkInformation,
  exitApplication,
  getPlatformDeviceName,
  getDeeplinkPath,
  getDeviceOS,
  getDeviceOsVersion,
  getDeviceMaker,
  tts,
  getTTSCancelationHTML,
  KEYCODES,
  getDeviceConnectionType,
  getStreamSource,
  getAdvertisementIdentifier,
  getLimitAdTracking,
  getAdsType,
  getPlayerConfig,
  getKeyHintIcon,
  showKeyHints,
  getPlatformSpecificModules,
  getAdParams,
  supportCapability,
  isAdInsertionModeSupported,
};
