let lastUtterance = null;

const supportSpeechSyntesis = () => window.speechSynthesis;

const cancelLastUtterance = () => {
  if (lastUtterance) {
    lastUtterance = null;
    window.speechSynthesis.cancel();
  }
};

const speak = (text) => {
  if (!supportSpeechSyntesis()) return;
  cancelLastUtterance();
  const newUtterance = new SpeechSynthesisUtterance(text);
  lastUtterance = newUtterance;
  window.speechSynthesis.speak(newUtterance);
};

export default speak;
