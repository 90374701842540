import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';

// Icons
import { ReactComponent as BlueDiamondIcon } from '../../assets/icons/blue-diamond.svg';

// Styles
import './points-accrual-toast.scss';

function PointsAccrualToast({ contentTitle }) {
  return (
    <div className="reward-toast" data-test-id="redeemed-toast">
      <div className="reward-toast__icon">
        <BlueDiamondIcon />
      </div>
      <div className="reward-toast__info">
        <p className="reward-toast__info__text" data-test-id="toast-text">
          <Trans
            id="LOYALTY_ACCRUAL_POINTS_LINE_1"
            values={{
              contentTitle: (
                <>
                  <span>{contentTitle}</span>
                  <br />
                </>
              ),
            }}
          />
        </p>
        <p
          className="reward-toast__info__text bottom_line_text"
          data-test-id="toast-text-bottom"
        >
          <Trans id="LOYALTY_ACCRUAL_POINTS_LINE_2" />
        </p>
      </div>
    </div>
  );
}

PointsAccrualToast.propTypes = {
  contentTitle: PropTypes.string,
};

export default PointsAccrualToast;
