function timeStrToSecondNum(time) {
  // except only format 00:00:00.000
  let secondTotal = 0;
  try {
    const h = Number(time.split(':')[0]);
    const m = Number(time.split(':')[1]);
    const s = Number(time.split(':')[2].split('.')[0]);
    if (h > 0) secondTotal = h * 3600;
    if (m > 0) secondTotal += m * 60;
    if (s >= 0) secondTotal += s;
  } catch (e) {
    secondTotal = -1;
  }

  return secondTotal;
}

function vttStrToVttObj(rawText, urlPrefix) {
  /**
   * accept only format
   * ---------------------------------------
   * WEBVTT
   *
   * 00:00:00.000 --> 00:00:01.000
   * content
   *
   * 00:00:01.000 --> 00:00:02.000
   * content
   * ---------------------------------------
   */
  try {
    let linesArr = [];
    linesArr = rawText
      .split('\n')
      .map((v) => v.trim()) // remove space each of line
      .filter((v) => !!v); // filter not space

    // remove header 'WEBVTT'
    if (linesArr[0] === 'WEBVTT') linesArr.shift();

    const data = [];
    let lineIndex = 0;

    while (lineIndex < linesArr.length) {
      const spliterTime = ' --> ';
      const start = timeStrToSecondNum(
        linesArr[lineIndex].split(spliterTime)[0]
      );
      const end = timeStrToSecondNum(linesArr[lineIndex].split(spliterTime)[1]);
      const content = linesArr[lineIndex + 1];

      const image = urlPrefix + content.split('#')[0];

      const dimensions = content.split('#')[1].split('=')[1].split(',');

      const x = dimensions[0] * 1;
      const y = dimensions[1] * 1;
      const w = dimensions[2] * 1;
      const h = dimensions[3] * 1;

      if (start < 0 || end < 0)
        throw new Error('cannot convert start time or end time');

      data[start] = {
        start,
        end,
        image,
        x,
        y,
        w,
        h,
      };
      lineIndex += 2;
    }

    return data;
  } catch (e) {
    return [];
  }
}

export { vttStrToVttObj };
