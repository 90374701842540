function convertSecondsToHHMM(seconds) {
  const totalMinutes = Math.floor(seconds / 60);

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return { h: hours, m: minutes };
}

function convertSecondsToHHMMSS(seconds) {
  const totalMinutes = Math.floor(seconds / 60);

  const secs = seconds % 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return { h: hours, m: minutes, s: secs };
}

export { convertSecondsToHHMM, convertSecondsToHHMMSS };
