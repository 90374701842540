import APP_CONFIG from '../config/app-config.json';
import { isFeatureFlagEnabled } from '../utils/feature-flags';

const getVizbeeScript = () => {
  let url = null;

  if (APP_CONFIG.VIZBEE_SDK_URL !== '') {
    const protocol = isFeatureFlagEnabled('testHTTP') ? 'http' : 'https';

    url = `${protocol}://${APP_CONFIG.VIZBEE_SDK_URL}?appid=${APP_CONFIG.VIZBEE_APP_ID}`;
  }

  return url;
};

export { getVizbeeScript };
