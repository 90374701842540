import LOCAL_STORAGE from '../enums/local-storage';
import { getUrlParamValue } from './url';
import BUILD_FEATURE_FLAGS from '../config/feature-flags.json';
import APP_CONFIG from '../config/app-config.json';
import FEATURE_FLAG_DATA_TYPES from '../enums/feature-flags-data-types';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from './local-storage';

function logger(logMessage) {
  const PREFIX = `Feature Flags ERROR:`;

  console.error(`${PREFIX} ${logMessage}`);
}

// The default configuration for feature flags is created during the application's build process
// and are imported here as a constant: BUILD_FEATURE_FLAGS
// The default feature flags can be overriden in the following order:
// 1. Any flags persisted in localStorage
// 2. Any flags passed as a URL parameter (such as ?AUTH=true)

const effectiveFeatureFlags = JSON.parse(JSON.stringify(BUILD_FEATURE_FLAGS)); // deep clone multi level object

function featureExists(feature) {
  return Object.prototype.hasOwnProperty.call(effectiveFeatureFlags, feature);
}

function readLocalStorageFlags() {
  return JSON.parse(getLocalStorageItem(LOCAL_STORAGE.FLAGS)) || {};
}

function cleanLocalStorageFlags() {
  const overrideFlags = readLocalStorageFlags();
  removeLocalStorageItem(LOCAL_STORAGE.FLAGS);
  for (const feature of Object.keys(BUILD_FEATURE_FLAGS)) {
    if (overrideFlags && feature in overrideFlags) {
      // reset each flag
      effectiveFeatureFlags[feature].value = BUILD_FEATURE_FLAGS[feature].value;
    }
  }
}

// PRIORITY OF FEATURE FLAGS: URL >> LOCAL STORAGE >> APPCONFIG REQUEST >> BUILD
function overrideFeatureFlags() {
  if (APP_CONFIG.ENV !== 'production' && APP_CONFIG.ENV !== 'preprod') {
    const localStorageFeatureFlags = readLocalStorageFlags();
    for (const feature of Object.keys(BUILD_FEATURE_FLAGS)) {
      // We check if there is an override stored in localStorage
      if (localStorageFeatureFlags && feature in localStorageFeatureFlags) {
        effectiveFeatureFlags[feature].value =
          localStorageFeatureFlags[feature];
      }
      // We check if there is an override in the URL
      if (getUrlParamValue(feature) !== null) {
        effectiveFeatureFlags[feature].value = getUrlParamValue(feature);
      }

      if (
        getUrlParamValue(feature) !== null &&
        effectiveFeatureFlags[feature]?.type === 'boolean'
      ) {
        effectiveFeatureFlags[feature].value =
          getUrlParamValue(feature) === 'true';
      }
    }
  }
}

const getFeatureFlagValue = (feature) => {
  if (featureExists(feature)) {
    return effectiveFeatureFlags[feature].value;
  }
  return null;
};

function isFeatureFlagEnabled(feature) {
  if (
    featureExists(feature) &&
    effectiveFeatureFlags[feature].type === 'boolean'
  ) {
    return effectiveFeatureFlags[feature].value;
  }
  return null;
}

const validateIncomingFeatureFlag = (featureFlag) => {
  const [featureFlagName, featureFlagData] = featureFlag;

  let validate = true;

  if (
    typeof featureFlagData !== 'object' ||
    featureFlagData?.length ||
    featureFlagData === null ||
    typeof featureFlagName !== 'string'
  ) {
    logger(
      `Flags from appConfig does not have valid format: Valid format is an object of keys (features) and values (object of feature Info)`
    );
    validate = false;
    return validate;
  }

  const value = featureFlagData?.value;
  const type = featureFlagData?.type;

  if (!featureExists(featureFlagName)) {
    logger(`Flag from appConfig not matching name: "${featureFlagName}"`);
    validate = false;
    return validate;
  }

  if (type !== effectiveFeatureFlags[featureFlagName]?.type) {
    logger(
      `Flag from appConfig not matching type: value type for feature flag ${featureFlagName} is "${type}", a value of type "${effectiveFeatureFlags[featureFlagName]?.type}" was provided`
    );
  }

  switch (effectiveFeatureFlags[featureFlagName]?.type) {
    case FEATURE_FLAG_DATA_TYPES.BOOLEAN:
      if (
        ![true, false].includes(value) ||
        value === null ||
        value === undefined
      ) {
        logger(
          `Flag from appConfig does not have a valid value: feature flag value from app config is "${value}" valid values are booleans true or false`
        );
        validate = false;
      }
      break;
    case FEATURE_FLAG_DATA_TYPES.INT:
      if (typeof value !== 'number') {
        logger(
          `Flag from appConfig does not have a valid value: invalid value ${value} of type ${typeof value} supplied for flag ${featureFlagName} expected number`
        );
        validate = false;
      }
      break;
    case FEATURE_FLAG_DATA_TYPES.STRING:
      if (typeof value !== 'string') {
        logger(
          `Flag from appConfig does not have a valid value: invalid value ${value} of type ${typeof value} supplied for flag ${featureFlagName} expected string`
        );
        validate = false;
      }
      break;
    case FEATURE_FLAG_DATA_TYPES.MULTIVARIATE:
      if (!effectiveFeatureFlags[featureFlagName]?.values?.includes(value)) {
        logger(
          `Flag from appConfig does not have a valid value: feature flag value from app config is "${value}" valid values are ${effectiveFeatureFlags[featureFlagName]?.values}`
        );
        validate = false;
      }
      break;
    default:
  }

  return validate;
};

// Receives an object of feature flags from appConfig and set the necessary feature flags in local storage
const mapFeatureFlagsFromAppConfig = (featureFlags) => {
  if (
    typeof featureFlags !== 'object' ||
    featureFlags?.length ||
    featureFlags === null ||
    featureFlags === undefined
  ) {
    logger(
      `Flags from appConfig does not have valid format: Valid format is an object of keys (features) and values (feature Info)`
    );
    return;
  }

  if (Object.keys(featureFlags).length < 1) {
    logger(
      `Flags from appConfig does not have valid format: Valid format is an object of keys (features) and values (feature Info)`
    );
    return;
  }

  const featuresArray = Object.entries(featureFlags);

  // verifies every feature that comes in the coming feature flags object
  const isFormatValid = featuresArray.every((featureFlag) =>
    validateIncomingFeatureFlag(featureFlag)
  );

  if (!isFormatValid) {
    logger(
      `Validation from incoming feature flags failed, the app is going to use default feature flags config `
    );
    return;
  }

  for (const feature of featuresArray) {
    const featureName = feature[0];
    const featureInfo = feature[1];
    setFeatureFlag(featureName, featureInfo.value);
  }

  overrideFeatureFlags();
};

/* This function changes the current value of a feature flag and saves it on local storage to be used on next app startup */
export const setFeatureFlag = (feature, value) => {
  if (featureExists(feature)) {
    effectiveFeatureFlags[feature].value = value;
  }
};

function saveFeatureFlag(feature, value) {
  setFeatureFlag(feature, value);
  const localStorageFlags = readLocalStorageFlags();
  localStorageFlags[feature] = value;
  if (APP_CONFIG.ENV !== 'production' && APP_CONFIG.ENV !== 'preprod') {
    setLocalStorageItem(LOCAL_STORAGE.FLAGS, JSON.stringify(localStorageFlags));
  }
}

overrideFeatureFlags();

const FEATURE_FLAGS = effectiveFeatureFlags;

export {
  isFeatureFlagEnabled,
  getFeatureFlagValue,
  saveFeatureFlag,
  cleanLocalStorageFlags,
  FEATURE_FLAGS,
  mapFeatureFlagsFromAppConfig,
  validateIncomingFeatureFlag,
};
