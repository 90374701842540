import { useState, useEffect } from 'react';

// Styles
import './fading-background-images.scss';

// Number of end-frames for welcome/landing screen
const totalEndBackgrounds = 4;
// Random background number ranging from 1-4
const chosenNumber = Math.ceil(Math.random() * totalEndBackgrounds);

function FadingBackgroundImages() {
  const [chosenBackground, setChosenBackground] = useState(null);

  useEffect(() => {
    const importChosenBackgrounds = async () => {
      const image = await import(
        '../../assets/images/welcome_end_' + chosenNumber + '.jpg'
      );
      setChosenBackground(image.default);
    };

    importChosenBackgrounds();
  }, []);

  return (
    <div className="fading-background">
      <img src={chosenBackground} alt="welcome-end" />
    </div>
  );
}

export default FadingBackgroundImages;
