import { useRef } from 'react';

function useThrottling(delay) {
  const timeout = useRef(null);

  const throttleFunction = (callback) => {
    if (timeout.current === null) {
      callback();
      start();
    }
  };

  const start = () => {
    timeout.current = setTimeout(() => {
      clearTimeout(timeout.current);
      timeout.current = null;
    }, delay);
  };

  return throttleFunction;
}

export default useThrottling;
