import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from '@lingui/macro';
import { useInView } from 'react-intersection-observer';

// Components
import Focusable from '../spatial-navigation/focusable';

// Enums
import CONTENT_ROW_TYPES from '../../enums/content-row-types';
import ROUTES from '../../enums/routes';
import ELEMENT_TYPES from '../../enums/element-types';

// Utils
import { scaleFrom720p } from '../../utils/scale-from-720p';
import { getFallbackPoster } from '../../utils/content-images';
import { populateRouteParameters } from '../../utils/url';
import { isFeatureFlagEnabled } from '../../utils/feature-flags';

// Styles
import './see-all-card.scss';
import './see-all-card.legacy.scss';

function SeeAllCard({ className = '', rowId, collectionId, ...otherProps }) {
  const { ref } = useInView({
    triggerOnce: true,
    rootMargin: `${scaleFrom720p(100)}px ${scaleFrom720p(100)}px`,
  });

  const fallBackImage = getFallbackPoster(CONTENT_ROW_TYPES.STANDARD);
  const seeAllLink = populateRouteParameters(ROUTES.SPONSORED_COLLECTIONS, {
    collectionId,
  });

  const props = {
    className: classnames(
      'see-all-card content-card',
      CONTENT_ROW_TYPES.STANDARD,
      className
    ),
    id: `see-all-card-${rowId}`,
    to: isFeatureFlagEnabled('testSponsoredContentMocks')
      ? '/sponsored_collections/5d5fc029-4507-4f3c-9d09-68e66443436a'
      : seeAllLink,
    elementType: ELEMENT_TYPES.LINK,
  };

  return (
    <Focusable aria-label={`${t`SEE`} ${t`ALL`}`} {...props} {...otherProps}>
      <div className="see-all-card__poster-container" ref={ref}>
        <img
          aria-hidden
          src={`${fallBackImage.image}.png`}
          alt={`${t`SEE`}-${t`ALL`}`}
          width={Math.round(fallBackImage.width)}
          height={Math.round(fallBackImage.height)}
        />
      </div>
      <span
        aria-hidden
        className={classnames(
          'see-all-card__fallback-text',
          CONTENT_ROW_TYPES.STANDARD
        )}
      >
        {t`SEE`} <br />
        {t`ALL`}
      </span>
    </Focusable>
  );
}

SeeAllCard.propTypes = {
  className: PropTypes.string,
  rowId: PropTypes.number,
  collectionId: PropTypes.string,
};

export default SeeAllCard;
