/* eslint-disable no-underscore-dangle */
class SearchResult {
  constructor({ id, images, title, rating }) {
    this._id = id;
    this._images = [];
    this._title = title;
    this._rating = rating;

    images.forEach((image) => {
      this.addImage(image);
    });
  }

  // getters and setters
  get id() {
    return this._id;
  }

  set id(id) {
    this._id = id;
  }

  get images() {
    return this._images;
  }

  set images(images) {
    this._images = images;
  }

  get title() {
    return this._title;
  }

  set title(title) {
    this._title = title;
  }

  get rating() {
    return this._rating;
  }

  set rating(rating) {
    this._rating = rating;
  }

  addImage(image) {
    this._images.push(image);
  }

  removeImage(imageId) {
    const images = this._images.filter((image) => image.id === imageId);

    this._images = images;
  }

  removeImages() {
    this._images = [];
  }
}

export default SearchResult;
