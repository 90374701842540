import { useInfiniteQuery } from 'react-query';
import {
  getRatingsByRegion,
  getMetadataByLocale,
  getPosterImageForCollection,
} from '../utils/content';
import ContentResult from '../entities/contentResult';
import { fetchPlaylist } from '../dataSource/playlist';

function usePlaylist({ playlistId, pageNumber = 1, queryOptions = {} }) {
  const pageSize = 24;
  const { data, isLoading, isError, isSuccess, hasNextPage, fetchNextPage } =
    useInfiniteQuery(
      ['playlist', playlistId],
      ({ pageParam = pageNumber }) =>
        preparePlaylist(playlistId, pageSize, pageParam),
      {
        getNextPageParam: (lastPage) => {
          let nextPage = null;
          if (lastPage.pagination.pageNumber < lastPage.pagination.totalPages) {
            nextPage = lastPage.pagination.pageNumber + 1;
          }

          return nextPage;
        },
        getPreviousPageParam: (firstPage) => {
          let previousPage = null;
          if (firstPage.pagination.pageNumber > 1) {
            previousPage = firstPage.pagination.pageNumber - 1;
          }

          return previousPage;
        },
        ...queryOptions,
      }
    );

  let playlistDTO = [];

  if (!isLoading && !isError) {
    playlistDTO = data?.pages.map((page) => {
      return {
        items: page?.items.map((item) => {
          let playlistItem = {
            id: item.id,
            image: item.image,
            title: item.title,
            rating: {
              name: item.rating?.name || null,
              age: item.rating?.age || null,
            },
            parentId: item.parentId,
            type: item.type,
          };

          if (item.season && item.episode) {
            playlistItem = {
              ...playlistItem,
              season: item.season,
              episode: item.episode,
            };
          }
          return playlistItem;
        }),
        pagination: page.pagination,
      };
    });
  }

  return {
    data: playlistDTO,
    isLoading,
    isError,
    isSuccess,
    hasNextPage,
    fetchNextPage,
  };
}

const preparePlaylist = async (playlistId, pageSize, pageNumber) => {
  const data = await fetchPlaylist(playlistId, pageSize, pageNumber);

  const { items, pagination } = data;

  const result = items?.map((item) => {
    const { title } = getMetadataByLocale(item);

    const playlistItem = new ContentResult({
      id: item.id,
      title,
      rating: getRatingsByRegion(item),
      images: (item?.parent?.assets?.images || []).concat(item?.assets?.images),
      season: item?.metadata[0]?.seasonNumber,
      episode: item?.metadata[0]?.episodeNumber,
    });

    playlistItem.image = getPosterImageForCollection(playlistItem.images);
    playlistItem.parentId = item.parentId;
    playlistItem.type = item.type;
    return playlistItem;
  });

  return { items: result, pagination };
};

export { usePlaylist };
