import { useAppConfig } from './hooks/appConfig';
import { useGenres } from './hooks/genres';
import { useBrowse } from './hooks/browse';
import { useBlock } from './hooks/block';
import { usePlaylist } from './hooks/playlist';
import { useContentRow } from './hooks/content-row';
import { useEpisodeDetails } from './hooks/content';
import { useFeatureFilmDetails } from './hooks/content';
import { useMovieDetails } from './hooks/content';
import { useSerieDetails } from './hooks/content';
import { useContent } from './hooks/content';
import { useEpisodesInSeason } from './hooks/content';
import { useSearchResults } from './hooks/search';
import { useSearchSuggestions } from './hooks/search';
import {
  useUserWatchlist,
  useWatchlistActions,
  useCheckContentInWatchlist,
} from './hooks/watchlist';
import { useRecommendations } from './hooks/recommendations';
import { useVttThumbs } from './hooks/vtt-thumbs';
import { ApiError } from './error';
import { CRACKLE_API_RESPONSE_ERROR_CODES } from './enums/crackle-api-response-codes';
import { ContextProvider } from './context/ContextProvider';
import { useCurations } from './hooks/curations';

let api = {
  useEpisodesInSeason,
  useAppConfig,
  useGenres,
  useBrowse,
  useCurations,
  useBlock,
  usePlaylist,
  useContentRow,
  useEpisodeDetails,
  useFeatureFilmDetails,
  useMovieDetails,
  useSerieDetails,
  useContent,
  useSearchResults,
  useSearchSuggestions,
  useCheckContentInWatchlist,
  useUserWatchlist,
  useWatchlistActions,
  useRecommendations,
  useVttThumbs,
  ApiError,
  CRACKLE_API_RESPONSE_ERROR_CODES,
  ContextProvider,
};

export { api };
