import { useEffect } from 'react';
import { JsSpatialNavigation } from 'react-js-spatial-navigation';
import { shallow } from 'zustand/shallow';

// Configs
import { FOCUS_OPTIONS } from '../config/back-button-config';

// Enums
import { KEYCODES } from '../platform/index';
import ROUTES from '../enums/routes';
import { SETTING_PATHS } from '../enums/settings-data';

// Hooks
import useGlobalContext from './use-global-context';
import { isKey } from '../utils/utils';
import useSearch from './use-search';
import usePlayerControls from './use-players-controls';

import VizbeeWrapper from '../vizbee/VizbeeWrapper';
import { isFeatureFlagEnabled } from '../utils/feature-flags';

/**
 * Guide on how to setup what happens when you hit the back button on a new element:
 *
 * 1. Give your element that should respond to remote BACK a `back` property like below:
 *    ```
 *.     <Link
 *.       back={BACK_BUTTON_CONFIG.MY_LINK}
 *        to={url}
 *.   ```
 *
 * 2. Go into /config/back-button-config and add `MY_LINK` to `BACK_BUTTON_CONFIG`.
 *
 * 3. The value of `BACK_BUTTON_CONFIG.MY_LINK` should be something from `FOCUS_OPTIONS`.
 *    Example: hitting back on your link should focus the Menu > Home:
 *    `BACK_BUTTON_CONFIG.MY_LINK: FOCUS_OPTIONS.MENU_HOME`
 *
 * 4. If `FOCUS_OPTIONS.MENU_HOME` is already defined, it back will now function on your link as expected.
 *    If your focus is not already defined, add it into `FOCUS_OPTIONS` and also it add to the switch below.
 *
 * @param {object} navigate useNavigate
 * @param {object} location useLocation
 */
export default function useBackButton(navigate, location) {
  const {
    setShowMoreInfoScreen,
    parentalSettingsEdit,
    hoverEnabled,
    playerTraversing,
  } = useGlobalContext(
    (state) => ({
      setShowMoreInfoScreen: state.setShowMoreInfoScreen,
      parentalSettingsEdit: state.parentalSettingsEdit,
      hoverEnabled: state.hoverEnabled,
      playerTraversing: state.playerTraversing,
    }),
    shallow
  );

  const { showPlayerControls, setShowPlayerControls } = usePlayerControls();

  const { stripVisibility, setStripVisibility } = useSearch();

  useEffect(() => {
    const onKeyDown = (event) => {
      if (isKey(event.keyCode, KEYCODES.BACK)) {
        const element = event.target;
        const backConfig = (element.attributes.back || {}).value;
        const searchParams = new URLSearchParams(location.search);
        // Extracts the Tile/Mode Id from the url
        const settingsModeId = searchParams.get('mode') || 'kids';
        // Get the first grid element
        const firstGrid = document.getElementById('page0');

        let cardID;
        let contentRowFirstItem = null;
        let gridElements;
        let cardIndex;
        let setFocus;

        switch (backConfig) {
          case FOCUS_OPTIONS.ALL_EPISODES:
            navigate(-1);

            setFocus = setInterval(() => {
              if (document.querySelector('#watch-all-episodes')) {
                JsSpatialNavigation.focus('#watch-all-episodes');
                clearInterval(setFocus);
              }
            }, 15);
            break;

          case FOCUS_OPTIONS.LANDING_SCREEN:
            navigate(-1);
            break;

          case FOCUS_OPTIONS.BROWSE_FIRST_ITEM:
            // Finds the first element of the Browse Grid Page and shifts focus to it
            cardID = firstGrid.firstElementChild.id;

            if (cardID) {
              JsSpatialNavigation.focus(`#${cardID}`);
            }
            break;

          case FOCUS_OPTIONS.CONTENT_DETAILS_BACK:
            // This is to redirect to user watchlist if the item in content details has been removed
            // Flow: watchlist --> content details --> remove from watchlist -> back
            if (location.state && location.state?.action === 'remove') {
              navigate(ROUTES.HOME, { replace: true });
              navigate(location.state.prevPath);

              break;
            }
            navigate(-1);
            break;

          case FOCUS_OPTIONS.BROWSE_SORT:
            navigate(-1);
            /*
              Delay introduced to wait for page to load
              Shifts focus from first grid item to sort button
            */
            setTimeout(
              () => JsSpatialNavigation.focus('.main .browse__sort'),
              20
            );
            break;

          case FOCUS_OPTIONS.BASIC_SETTINGS:
            JsSpatialNavigation.focus('.app-submenu__settings .basic-settings');
            break;

          case FOCUS_OPTIONS.CONTENT_ROW_FIRST_ITEM:
            contentRowFirstItem = (element.parentNode.firstChild || {}).id;

            if (contentRowFirstItem) {
              JsSpatialNavigation.focus(`#${contentRowFirstItem}`);
            }
            break;

          case FOCUS_OPTIONS.CLOSE_WATCHLIST_MODAL:
            // This is empty for the event listener of the modal to override and close the modal
            break;

          case FOCUS_OPTIONS.EPISODE:
            JsSpatialNavigation.focus('.icon-button.selected');
            break;

          case FOCUS_OPTIONS.GRID_ROW_FIRST_ITEM:
            /**
             * Shifts the focus on first element of a grid row
             */
            gridElements = [...element.parentElement.children];
            cardIndex = gridElements.indexOf(element);
            contentRowFirstItem =
              cardIndex > 4 ? cardIndex - (cardIndex % 5) : 0;
            cardID = gridElements[contentRowFirstItem].id;

            JsSpatialNavigation.focus(`#${cardID}`);
            break;

          case FOCUS_OPTIONS.HERO_PLAY:
            // Force change location to homepage when we focus menu home
            if (location.pathname !== ROUTES.HOME) {
              navigate(ROUTES.HOME);
              JsSpatialNavigation.focus('.app-menu__middle .menu-home');
            }
            // Only focus "Watch Now" button if we were already on homepage
            else if (
              document.getElementsByClassName('hero__buttons watch-now')
                .length > 0
            ) {
              JsSpatialNavigation.focus('.hero__buttons .watch-now');
            } else {
              JsSpatialNavigation.focus('.focusedElement');
            }

            break;

          case FOCUS_OPTIONS.MENU_HOME:
            // Force change location to homepage when we focus menu home
            if (location.pathname !== ROUTES.HOME) {
              navigate(ROUTES.HOME);
            }

            JsSpatialNavigation.focus('.app-menu__middle .menu-home');
            break;

          case FOCUS_OPTIONS.MENU_MOVIES:
            JsSpatialNavigation.focus('.app-menu__middle .menu-movies');
            break;

          case FOCUS_OPTIONS.MENU_SHOWS:
            JsSpatialNavigation.focus('.app-menu__middle .menu-shows');
            break;

          case FOCUS_OPTIONS.MENU_SEARCH:
            JsSpatialNavigation.focus('.app-menu__middle .menu-search');
            break;

          case FOCUS_OPTIONS.MENU_WATCHLIST:
            JsSpatialNavigation.focus('.app-menu__middle .menu-watchlist');
            break;

          case FOCUS_OPTIONS.MENU_SETTINGS:
            JsSpatialNavigation.focus('.app-menu__lower .menu-settings');
            break;

          case FOCUS_OPTIONS.NUMBER_STRIP:
            navigate(-1);
            break;

          case FOCUS_OPTIONS.LETTER_STRIP_FIRST_BUTTON:
            JsSpatialNavigation.focus(
              '.letter-strip .letter-strip__numeric-btn'
            );
            break;

          case FOCUS_OPTIONS.NUMERIC_STRIP_FIRST_BUTTON:
            JsSpatialNavigation.focus('.number-strip .number-strip__abc-btn');
            break;

          case FOCUS_OPTIONS.LETTER_STRIP_SWITCH:
            setStripVisibility(!stripVisibility);
            setTimeout(
              () =>
                JsSpatialNavigation.focus(
                  '.letter-strip .letter-strip__numeric-btn'
                ),
              3
            );
            break;

          case FOCUS_OPTIONS.SEARCH_NUMERIC_KEYBOARD:
            JsSpatialNavigation.focus(
              '.number-strip-focusable.number-strip__numbers'
            );
            break;

          case FOCUS_OPTIONS.SEARCH_LETTER_KEYBOARD:
            JsSpatialNavigation.focus(
              '.letter-strip-focusable.number-strip__numbers'
            );
            break;

          case FOCUS_OPTIONS.SETTINGS_TILE:
            /**
             * Navigates to settings page
             * Shifts the focus on the previously selected Tile/Mode
             */
            switch (location.search) {
              case `?page=${SETTING_PATHS.PRIVACY_POLICY}`:
                JsSpatialNavigation.focus(`#${SETTING_PATHS.PRIVACY_POLICY}`);
                break;
              case `?page=${SETTING_PATHS.ABOUT}`:
                JsSpatialNavigation.focus(`#${SETTING_PATHS.ABOUT}`);
                break;
              case `?page=${SETTING_PATHS.TOS}`:
                JsSpatialNavigation.focus(`#${SETTING_PATHS.TOS}`);
                break;
              case `?page=${SETTING_PATHS.PRIVACY}`:
                JsSpatialNavigation.focus(`#${SETTING_PATHS.PRIVACY}`);
                break;
              case `?page=${SETTING_PATHS.CPRA}`:
                JsSpatialNavigation.focus(`#${SETTING_PATHS.CPRA}`);
                break;
              case `?page=${SETTING_PATHS.AD_SETTINGS}`:
                JsSpatialNavigation.focus(`#${SETTING_PATHS.AD_SETTINGS}`);
                break;
              default:
                navigate(ROUTES.SETTINGS, { replace: true });
                setTimeout(
                  () => JsSpatialNavigation.focus(`#${settingsModeId}`),
                  3
                );
                break;
            }
            break;

          case FOCUS_OPTIONS.SUBMENU_ACTIVE_GENRE:
            JsSpatialNavigation.focus('.app-submenu__item.active');
            break;

          case FOCUS_OPTIONS.VIDEO_PLAYER:
            /**
             * Takes User back to previous page if player controls are hidden.
             * Hides player controls on pressing "BACK", if shown.
             */
            if (!showPlayerControls) {
              // send video stop to vizbee
              isFeatureFlagEnabled('vizbee') && VizbeeWrapper.stopVideo();

              // If the user is on the same episode or movie he just go back to details page
              navigate(-1);
            } else {
              setShowPlayerControls(false);
            }
            break;

          case FOCUS_OPTIONS.CONTENT_DETAILS:
            // Return to Content-Details page after editing Parental Controls, when a media asset is blocked
            navigate(-1);
            break;

          case FOCUS_OPTIONS.CONTENT_DETAILS_YMAL:
            JsSpatialNavigation.focus('.watch-now');
            break;

          case FOCUS_OPTIONS.WATCHLIST_GRID_FIRST_ITEM:
            // Get the first watchlist grid element
            cardID =
              document.getElementById('grid1')?.firstElementChild
                ?.firstElementChild?.id;

            if (cardID) {
              JsSpatialNavigation.focus(`#${cardID}`);
            }
            break;

          case FOCUS_OPTIONS.SIGN_IN_REWARDS:
            if (location?.state?.prevPath === ROUTES.HOME) {
              navigate(-1, { state: 'points-bar' });
              break;
            }

            JsSpatialNavigation.focus('.menu-rewards');

            break;

          default:
            // default action if hover enabled
            if (hoverEnabled) {
              navigate(-1);
            }
            break;
        }
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => window.removeEventListener('keydown', onKeyDown);
  }, [
    navigate,
    location.pathname,
    location.search,
    location.state,
    showPlayerControls,
    setShowMoreInfoScreen,
    setShowPlayerControls,
    stripVisibility,
    setStripVisibility,
    parentalSettingsEdit,
    hoverEnabled,
    playerTraversing,
  ]);
}
