import { useQuery } from 'react-query';
import { getBlockBackgroundImage } from '../utils/content';
import { fetchBlock } from '../dataSource/block';

function useBlock({ blockId, pageName, queryOptions = {} }) {
  const { data, isLoading, isError, isSuccess } = useQuery(
    ['blockId', blockId],
    () => getBlock(blockId, pageName),
    {
      queryOptions,
    }
  );

  return {
    data,
    isLoading,
    isError,
    isSuccess,
  };
}

const getBlock = async (blockId, pageName) => {
  const data = await fetchBlock(blockId, pageName);

  const { title, guid, assets, description, source } = data;

  const blockSection = {
    id: guid,
    playlistId: source?.contentId,
    image: getBlockBackgroundImage(assets?.images),
    title,
    description,
  };

  return blockSection;
};

export { useBlock };
