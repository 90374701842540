import { useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

// Styles
import './menu-points-bar.scss';

// Contexts
import useGlobalContext from '../../hooks/use-global-context';

// utils
import { getSignedInUser } from '../../utils/signed-in-user';

// Hooks
import useNavbar from '../../hooks/use-navbar';

// api
import { fetchUserProfile } from '../../crackle-sdk/v1/api/user';
import { isFeatureFlagEnabled } from '../../utils/feature-flags';

function MenuPointsBar({ className }) {
  const loyaltyOptIn = useGlobalContext((state) => state.loyalty.optedIn);
  const loyaltyPoints = useGlobalContext((state) => state.loyalty.userPoints);
  const setLoyaltyUserPoints = useGlobalContext(
    (state) => state.setLoyaltyUserPoints
  );

  const { isOpen } = useNavbar();

  const userId = getSignedInUser()?.userId;

  useEffect(() => {
    if (userId) {
      const fetchUserPoints = async () => {
        if (loyaltyPoints !== null) {
          return;
        }
        const {
          userLoyaltyPoints: { points },
        } = await fetchUserProfile(userId);

        if (points || points === 0) {
          setLoyaltyUserPoints(points);
        }
      };

      fetchUserPoints();
    }
  }, [userId, loyaltyPoints, setLoyaltyUserPoints]);

  const formatPoints = (points) => {
    if (points >= 1000000) {
      return t({
        id: 'LOYALTY_POINTS_MILLION',
        values: { points: Math.floor(points / 1000000) },
      });
    }
    if (points >= 1000) {
      return t({
        id: 'LOYALTY_POINTS_THOUSAND',
        values: { points: Math.floor(points / 1000) },
      });
    }

    if (points === 1) {
      return t({
        id: 'LOYALTY_POINTS_SINGULAR',
        values: { points },
      });
    }

    return t({
      id: 'LOYALTY_POINTS_PLURAL',
      values: { points },
    });
  };

  const showMenuPointsBar = (cssClassName) => {
    if (isFeatureFlagEnabled('loyalty') && isOpen) {
      return (
        <span className={classnames(cssClassName, 'menu-points-bar')}>
          {userId && loyaltyOptIn && loyaltyPoints !== null
            ? `${formatPoints(loyaltyPoints)}`
            : t`JOIN_NOW`}
        </span>
      );
    }
    return null;
  };

  return showMenuPointsBar(className);
}

MenuPointsBar.propTypes = {
  className: PropTypes.string,
};

export default MenuPointsBar;
