// see https://github.com/luke-chang/js-spatial-navigation#events
const SPATIAL_EVENTS = {
  WILL_MOVE: 'sn:willmove',
  WILL_UNFOCUS: 'sn:willunfocus',
  UNFOCUSED: 'sn:unfocused',
  WILL_FOCUS: 'sn:willfocus',
  FOCUSED: 'sn:focused',
  NAVIGATE_FAILED: 'sn:navigatefailed',
  ENTER_DOWN: 'sn:enter-down',
  ENTER_UP: 'sn:enter-up',
};

export default SPATIAL_EVENTS;
