import { datadogRum } from '@datadog/browser-rum';
import { isFeatureFlagEnabled } from './feature-flags';
import APP_CONFIG from '../config/app-config.json';

const isEnabled = !isFeatureFlagEnabled('testDisableDatadog');

const initialize = () => {
  // Check if datadog is not already initialized
  if (!datadogRum.getInitConfiguration() && isEnabled) {
    datadogRum.init({
      applicationId: APP_CONFIG.DATADOG_APPLICATIONID,
      clientToken: APP_CONFIG.DATADOG_CLIENTTOKEN,
      site: 'datadoghq.com',
      service: APP_CONFIG.APP_NAME,
      env: APP_CONFIG.ENV,
      version: APP_CONFIG.VERSION,
      sampleRate: APP_CONFIG.ENV === 'production' ? 5 : 100,
      replaySampleRate: 100,
      trackInteractions: true,
    });
  }

  startSessionRecording();
};

const startSessionRecording = () => {
  if (isEnabled) {
    datadogRum.startSessionReplayRecording();
  }
};

const stopSessionRecording = () => {
  if (isEnabled) {
    datadogRum.stopSessionReplayRecording();
  }
};

export { initialize, startSessionRecording, stopSessionRecording };
