/* eslint-disable no-underscore-dangle */

class ImageAsset {
  constructor({ id, type, name, width, height, aspectRatio, url }) {
    this._id = id;
    this._type = type;
    this._name = name;
    this._width = width;
    this._height = height;
    this._aspectRatio = aspectRatio;
    this._url = url;
  }

  get id() {
    return this._id;
  }

  set id(id) {
    this._id = id;
  }

  get type() {
    return this._type;
  }

  set type(type) {
    this._type = type;
  }

  get name() {
    return this._name;
  }

  set name(name) {
    this._name = name;
  }

  get width() {
    return this._width;
  }

  set width(width) {
    this._width = width;
  }

  get height() {
    return this._height;
  }

  set height(height) {
    this._height = height;
  }

  get aspectRatio() {
    return this._aspectRatio;
  }

  set aspectRatio(aspectRatio) {
    this._aspectRatio = aspectRatio;
  }

  get url() {
    return this._url;
  }

  set url(url) {
    this._url = url;
  }
}

export default ImageAsset;
