import { useEffect, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { JsSpatialNavigation } from 'react-js-spatial-navigation';

// Components
import Button from '../button/button';
import FocusableSection from '../spatial-navigation/focusable-section';

// Enums
import ELEMENT_TYPES from '../../enums/element-types';
import BUTTON_TYPES from '../../enums/button-types';
import ACTIVATION_CODE_TYPES from '../../enums/activation-code-types';
import MODAL_TYPES from '../../enums/modal-types';
import MODAL_EXIT_STATUS from '../../enums/modal-exit-status';

// Icon
import { ReactComponent as CheckIcon } from '../../assets/icons/check-mark.svg';
import { ReactComponent as CheckCircleIcon } from '../../assets/icons/check-circle.svg';
import { ReactComponent as CircleBackIcon } from '../../assets/icons/circle-back.svg';

// utils
import { getSignedInUser } from '../../utils/signed-in-user';
import logger from '../../utils/logger';
import BRAND_CONFIG from '../../config/brand-config.json';
import { isKey } from '../../utils/utils';
import { KEYCODES } from '../../platform/index';

// api
import { loyaltyUserOptIn } from '../../crackle-sdk/v1/api/loyalty';

// Hooks
import useModalHandler from '../../hooks/use-modal-handler';

// contexts
import useGlobalContext from '../../hooks/use-global-context';

// Styles
import './rewards-auth-overlay.scss';

function RewardsAuthOverlay() {
  const [chosenBackground, setChosenBackground] = useState(null);

  const userId = getSignedInUser()?.userId;
  const loyaltyOptedIn = useGlobalContext((state) => state.loyalty?.optedIn);
  const setLoyaltyOptIn = useGlobalContext((state) => state.setLoyaltyOptIn);
  const { showModal, closeModal } = useModalHandler();

  const { REWARDS_TERMS_OF_SERVICE } = BRAND_CONFIG;

  useEffect(() => {
    JsSpatialNavigation.focus('@rewards-auth-focusable');
  }, []);

  useEffect(() => {
    const onKeyDown = (event) => {
      if (isKey(event.keyCode, KEYCODES.BACK)) {
        closeModal(MODAL_EXIT_STATUS.REWARDS_AUTH_OVERLAY);
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => window.removeEventListener('keydown', onKeyDown);
  }, [closeModal]);

  useEffect(() => {
    const importChosenBackgrounds = async () => {
      const image = await import('../../assets/images/welcome_end_4.jpg');
      setChosenBackground(image.default);
    };

    importChosenBackgrounds();

    return () => {
      setChosenBackground(null);
    };
  }, [userId, closeModal]);

  const handleActivateRewards = async () => {
    try {
      const response = await loyaltyUserOptIn(userId);

      response && setLoyaltyOptIn(true);

      closeModal(MODAL_EXIT_STATUS.REWARDS_ALREADY_OPT_IN);
    } catch (e) {
      logger.error('Error opting for loyalty', e);
    }
  };

  const handleCreateAccountButton = () => {
    showModal(MODAL_TYPES.AUTH, {
      codeType: ACTIVATION_CODE_TYPES.SIGNUP,
    });
  };

  const handleSignInButton = () => {
    showModal(MODAL_TYPES.AUTH, {
      codeType: ACTIVATION_CODE_TYPES.SIGNIN,
    });
  };

  const handleCancelButton = () => {
    closeModal(MODAL_EXIT_STATUS.REWARDS_AUTH_OVERLAY);
  };

  return (
    <FocusableSection sectionId="rewards-auth-focusable">
      <div className="rewards-auth">
        {/* SIGNED OUT SCREEN */}
        {!userId && (
          <>
            <div className="rewards-auth__background-image">
              <img alt="rewards-auth-background" src={chosenBackground} />
            </div>
            <div className="rewards-auth__signed-out">
              <div className="rewards-auth__signed_out__info">
                <h1 className="page-title">
                  {t`LOYALTY_ACTIVATE_FREE`}
                  <br /> {t`LOYALTY_REWARDS_BRAND`}
                </h1>
                <h3 className="page-first-middle-text">
                  {t`LOYALTY_REWARDS_SECOND_TITLE_FIRST`} <br />
                  {t`LOYALTY_REWARDS_SECOND_TITLE_SECOND`}
                </h3>
                <p className="page-second-middle-text">
                  {t`LOYALTY_REWARDS_MIDDLE_TEXT_FIRST`}
                  <br /> {t`LOYALTY_REWARDS_MIDDLE_TEXT_SECOND`}
                  <br />
                  {t`LOYALTY_REWARDS_MIDDLE_TEXT_THIRD`}
                </p>
                <p className="page-third-middle-text">
                  {t`GET_STARTED_SIGNUP_SIGNIN`}
                </p>
              </div>
              <div className="rewards-auth__signed-out__buttons">
                <Button
                  aria-label={t`CREATE_AN_ACCOUNT`}
                  data-test-id="rewards-create-account-button"
                  className="rewards-auth__signed-out__buttons__create-account"
                  elementType={ELEMENT_TYPES.BUTTON}
                  type={BUTTON_TYPES.TEXT}
                  selectionOverrides={{
                    left: '',
                    up: '',
                    down: '',
                  }}
                  onClick={handleCreateAccountButton}
                >
                  <Trans>CREATE_ACCOUNT</Trans>
                </Button>
                <Button
                  aria-label={t`SIGN_IN`}
                  data-test-id="rewards-sign-in-button"
                  className="rewards-auth__signed-out__buttons__sign-in"
                  elementType={ELEMENT_TYPES.BUTTON}
                  type={BUTTON_TYPES.TEXT}
                  selectionOverrides={{
                    up: '',
                    down: '',
                  }}
                  onClick={handleSignInButton}
                >
                  <Trans>SIGN_IN</Trans>
                </Button>
                <Button
                  aria-label={t`CANCEL`}
                  data-test-id="rewards-cancel-button"
                  className="rewards-auth__signed-out__buttons__cancel"
                  elementType={ELEMENT_TYPES.BUTTON}
                  icon={CircleBackIcon}
                  type={BUTTON_TYPES.ICONBUTTON}
                  selectionOverrides={{
                    up: '',
                    right: '',
                    down: '',
                  }}
                  onClick={handleCancelButton}
                >
                  <Trans>CANCEL</Trans>
                </Button>
              </div>
              <div className="rewards-auth__signed-out__tos">
                <span>
                  {t({
                    id: `LOYALTY_REWARDS_TOS`,
                    values: { tos_url: REWARDS_TERMS_OF_SERVICE },
                  })}
                </span>
              </div>
            </div>
          </>
        )}
        {/* SIGNED IN OPTED OUT SCREEN */}
        {userId && !loyaltyOptedIn && (
          <>
            <div className="rewards-auth__background-image">
              <img alt="rewards-background" src={chosenBackground} />
            </div>
            <div className="rewards-auth__signed-in-opted-out">
              <div className="rewards-auth__signed-in-opted-out__info">
                <h1 className="page-title">
                  {t`LOYALTY_ACTIVATE_FREE`}
                  <br /> {t`LOYALTY_REWARDS_BRAND`}
                </h1>
                <h3 className="page-first-middle-text">
                  {t`LOYALTY_WITH_REWARDS_YOU_CAN`}
                </h3>

                <ul className="rewards-benefits">
                  <li>
                    <CheckCircleIcon className="check-circle-icon" />{' '}
                    {t`LOYALTY_REWARDS_BENEFITS_FIRST`}
                  </li>
                  <li>
                    <CheckCircleIcon className="check-circle-icon" />{' '}
                    {t`LOYALTY_REWARDS_BENEFITS_SECOND`}
                  </li>

                  <li>
                    <CheckCircleIcon className="check-circle-icon" />{' '}
                    {t`LOYALTY_REWARDS_BENEFITS_THIRD`}
                  </li>
                </ul>
              </div>

              <div className="rewards-auth__signed-in-opted-out__buttons">
                <Button
                  icon={CheckIcon}
                  aria-label={t`ACTIVATE`}
                  data-test-id="rewards-activate-rewards-button"
                  className="rewards-auth__signed-in-opted-out__button__activate-rewards join-rewards"
                  elementType={ELEMENT_TYPES.BUTTON}
                  type={BUTTON_TYPES.ICONBUTTON}
                  selectionOverrides={{
                    up: '',
                    down: '',
                  }}
                  onClick={handleActivateRewards}
                >
                  <Trans>ACTIVATE</Trans>
                </Button>
                <Button
                  aria-label={t`CANCEL`}
                  data-test-id="rewards-cancel-button"
                  className="rewards-auth__signed-in-opted-out__buttons__cancel"
                  elementType={ELEMENT_TYPES.BUTTON}
                  icon={CircleBackIcon}
                  type={BUTTON_TYPES.ICONBUTTON}
                  selectionOverrides={{
                    up: '',
                    right: '',
                    down: '',
                  }}
                  onClick={handleCancelButton}
                >
                  <Trans>CANCEL</Trans>
                </Button>
              </div>
              <div className="rewards-auth__signed-in-opted-out__tos">
                <span className="tos">
                  {t({
                    id: `LOYALTY_REWARDS_TOS`,
                    values: { tos_url: REWARDS_TERMS_OF_SERVICE },
                  })}
                </span>
              </div>
            </div>
          </>
        )}
      </div>
    </FocusableSection>
  );
}
export default RewardsAuthOverlay;
