import { useState, useEffect, useRef } from 'react';
import { Trans, t } from '@lingui/macro';
import classnames from 'classnames';
import { JsSpatialNavigation } from 'react-js-spatial-navigation';

// Icons
import { ReactComponent as QuestionIcon } from '../../assets/icons/question-overlay.svg';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left.svg';
import { ReactComponent as CheckMark } from '../../assets/icons/check-mark.svg';

// Hooks
import useGlobalContext from '../../hooks/use-global-context';
import useModalHandler from '../../hooks/use-modal-handler';

// Components
import Button from '../button/button';
import FocusableSection from '../spatial-navigation/focusable-section';

// utils
import mParticle from '../../utils/mparticle';

// Styles
import './still-watching-overlay.scss';
import MODAL_EXIT_STATUS from '../../enums/modal-exit-status';

import { KEYCODES } from '../../platform/index';

import { isKey } from '../../utils/utils';

function StillWatching() {
  const { closeModal } = useModalHandler();
  const [playInitialLabel, setPlayInitialLabel] = useState(true);
  const hoverEnabled = useGlobalContext((state) => state.hoverEnabled);

  const selectedOption = useRef(null);
  const mountTimeRef = useRef(null);

  const titleText = t`ZOMBIE_MODE_STILL_WATCHING`;
  const continueButtonText = t`YES`;
  const descriptionText = t`ZOMBIE_MODE_ABANDONED_PLAYBACK`;

  // set initial focus
  useEffect(() => {
    mountTimeRef.current = Date.now();
    JsSpatialNavigation.focus('@still-watching-section');
    setPlayInitialLabel(false);

    return () => {
      const duration = Date.now() - mountTimeRef.current;

      const durationInMinutes = Math.round(
        (duration % (60 * 60 * 1000)) / (1000 * 60)
      );

      mParticle.mParticleZombieModeDuration(
        durationInMinutes,
        selectedOption.current
      );
    };
  }, []);

  return (
    <div
      className={classnames('still-watching-screen', {
        'hover-enabled': hoverEnabled,
        'hover-disabled': !hoverEnabled,
      })}
      data-test-id="still-watching-screen"
    >
      <div className="content-gradients">
        <div className="orange-gradient" />
        <div className="blue-gradient" />
      </div>
      <div className="still-watching-screen__icon">
        <QuestionIcon aria-label="Alert icon" className="alert-icon" />
      </div>

      <div className="still-watching-screen__content">
        <div className="still-watching-screen__content__left">
          <h1>
            <Trans id="ZOMBIE_MODE_STILL_WATCHING" />
          </h1>
        </div>

        <div className="still-watching-screen__content__right">
          <FocusableSection
            sectionId="still-watching-section"
            leaveForUp=""
            leaveForRight=""
            leaveForDown=""
            leaveForLeft=""
          >
            <Button
              id="still-watching-confirm-button"
              data-test-id="still-watching-confirm-button"
              aria-label={
                playInitialLabel
                  ? `${titleText} ${descriptionText} ${continueButtonText}`
                  : continueButtonText
              }
              onClick={() => {
                closeModal(MODAL_EXIT_STATUS.ZOMBIE_MODE_CONTINUE);
                selectedOption.current = 'continue';
              }}
              isFocusOnPageLoad
              isFocusOnSectionEnter
              selectionOverrides={{ up: '', left: '', right: '' }}
              onKeyDown={(e) => {
                if (isKey(e.keyCode, KEYCODES.BACK)) {
                  closeModal(MODAL_EXIT_STATUS.ZOMBIE_MODE_EXIT);
                  selectedOption.current = 'exit';
                }
              }}
            >
              <CheckMark />
              <div aria-hidden="true">
                <Trans id="YES" />
              </div>
            </Button>

            <Button
              id="still-watching-exit-button"
              data-test-id="still-watching-exit-button"
              aria-label={t({ id: 'ZOMBIE_MODE_EXIT_VIDEO' })}
              onClick={() => {
                closeModal(MODAL_EXIT_STATUS.ZOMBIE_MODE_EXIT);
                selectedOption.current = 'exit';
              }}
              selectionOverrides={{ down: '', left: '', right: '' }}
              onKeyDown={(e) => {
                if (isKey(e.keyCode, KEYCODES.BACK)) {
                  closeModal(MODAL_EXIT_STATUS.ZOMBIE_MODE_EXIT);
                  selectedOption.current = 'exit';
                }
              }}
            >
              <ArrowLeftIcon />
              <div aria-hidden="true">
                <Trans id="ZOMBIE_MODE_EXIT_VIDEO" />
              </div>
            </Button>
          </FocusableSection>
        </div>
      </div>

      <div className="still-watching-screen__footer">
        <p>
          <Trans id="ZOMBIE_MODE_ABANDONED_PLAYBACK" />
        </p>
      </div>
    </div>
  );
}

export default StillWatching;
