import PropTypes from 'prop-types';
import { Trans, t } from '@lingui/macro';
import classnames from 'classnames';

// Components
import Button from '../../components/button/button';
import FocusableSection from '../../components/spatial-navigation/focusable-section';

// Hooks
import useFocus from '../../hooks/use-focus';

// Utils
import mParticle from '../../utils/mparticle';

// Styles
import './geolocation-error.scss';

// Icons
import { ReactComponent as AlertIcon } from '../../assets/icons/alert.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left.svg';

// Contexts
import useGlobalContext from '../../hooks/use-global-context';
import { supportCapability } from '../../platform/index';
import PLATFORM_CAPABILITIES from '../../enums/platform-capabilities';

function GeolocationErrorScreen({ onPrimaryClick }) {
  const ArrowLeftIcon = ArrowLeft;

  const hoverEnabled = useGlobalContext((state) => state.hoverEnabled);

  useFocus('@geolocation-focusable');

  return (
    <div
      className={classnames('geolocation-error-screen', {
        'hover-enabled': hoverEnabled,
        'hover-disabled': !hoverEnabled,
      })}
    >
      <AlertIcon aria-label="Alert icon" className="alert-icon" />

      <div className="geolocation-error-screen__content">
        <div
          className={`geolocation-error-screen__content__left ${
            !supportCapability(PLATFORM_CAPABILITIES.EXIT) ? 'centered' : null
          }`}
        >
          <h1>
            <Trans>SERVICE</Trans>
            <br />
            <Trans>UNAVAILABLE</Trans>
          </h1>
        </div>

        <div className="geolocation-error-screen__content__right">
          {supportCapability(PLATFORM_CAPABILITIES.EXIT) ? (
            <FocusableSection sectionId="geolocation-focusable">
              <Button
                aria-label={t`EXIT`}
                className="initialFocus"
                selectionOverrides={{ up: '', down: '', right: '', left: '' }}
                onClick={() => {
                  onPrimaryClick();
                  mParticle.mParticleButtonClick({
                    buttonLabel: 'Exit',
                    buttonPosition: 1,
                    pageSection: 'Geolocation Error Screen',
                  });
                }}
                isFocusOnPageLoad
                isFocusOnSectionEnter
              >
                <ArrowLeftIcon />
                <Trans>EXIT</Trans>
              </Button>
            </FocusableSection>
          ) : null}
        </div>
      </div>

      <div className="geolocation-error-screen__footer">
        <p>
          <Trans>GEOLOCATION_ERROR_MESSAGE</Trans>
        </p>
      </div>
    </div>
  );
}

GeolocationErrorScreen.propTypes = {
  onPrimaryClick: PropTypes.func,
};

GeolocationErrorScreen.defaultProps = {
  onPrimaryClick: () => void 0,
};

export default GeolocationErrorScreen;
