import { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Trans, t } from '@lingui/macro';

// Components
import Button from '../../components/button/button';
import FocusableSection from '../../components/spatial-navigation/focusable-section';

// Config
import ERROR_TYPE from '../../config/error-type-config';
import BRAND_CONFIG from '../../config/brand-config.json';

// Utils
import mParticle from '../../utils/mparticle';

// Styles
import './error.scss';

// Icons
import { ReactComponent as AlertIcon } from '../../assets/icons/alert.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left.svg';
import { ReactComponent as CircleForwardIcon } from '../../assets/icons/circle-forward.svg';

// Hooks
import useFocus from '../../hooks/use-focus';
import useGlobalContext from '../../hooks/use-global-context';

function ErrorScreen({
  error,
  onPrimaryClick,
  onSecondaryClick,
  focus,
  primaryLabel,
  loading,
}) {
  const ArrowLeftIcon = ArrowLeft;
  const primaryFocused = focus === 'primary';
  const secondaryFocused = focus === 'secondary';
  const tryAgainLabel = t`TRY_AGAIN`;

  const [playInitialLabel, setPlayInitialLabel] = useState(true);

  const hoverEnabled = useGlobalContext((state) => state.hoverEnabled);

  const { HELPCENTER: BRAND_HELPCENTER } = BRAND_CONFIG;

  useFocus('@error-focusable');

  return (
    <div
      className={classnames('error-screen', {
        'hover-enabled': hoverEnabled,
        'hover-disabled': !hoverEnabled,
      })}
    >
      <AlertIcon aria-label="Alert icon" className="alert-icon" />

      <div className="error-screen__content">
        <div className="error-screen__content__left">
          {/* Error message must be 2 lines to ensure button alignment */}
          <h1>
            <Trans id={error.titleFirstLine} />
            <br />
            <Trans id={error.titleSecondLine} />
          </h1>
        </div>

        <div className="error-screen__content__right">
          <FocusableSection sectionId="error-focusable">
            <Button
              aria-label={
                secondaryFocused && playInitialLabel
                  ? t({
                      id: error.TTS,
                      values: {
                        focusedLabel: tryAgainLabel,
                        helpcenter: BRAND_HELPCENTER,
                      },
                    })
                  : tryAgainLabel
              }
              onClick={() => {
                onSecondaryClick();
                mParticle.mParticleButtonClick({
                  buttonLabel: 'Try Again',
                  buttonPosition: 1,
                  pageSection: 'Error Screen',
                });
              }}
              isFocusOnPageLoad={secondaryFocused}
              isFocusOnSectionEnter={secondaryFocused}
              selectionOverrides={{ up: '', left: '', right: '' }}
              onFocus={() => primaryFocused && setPlayInitialLabel(false)}
              disabled={loading}
            >
              <CircleForwardIcon />
              <Trans>TRY_AGAIN</Trans>
            </Button>

            {onPrimaryClick ? (
              <Button
                aria-label={
                  primaryFocused && playInitialLabel
                    ? t({
                        id: error.TTS,
                        values: {
                          focusedLabel: primaryLabel,
                          helpcenter: BRAND_HELPCENTER,
                        },
                      })
                    : primaryLabel
                }
                onClick={() => {
                  onPrimaryClick();
                  mParticle.mParticleButtonClick({
                    buttonLabel: 'Go Back',
                    buttonPosition: 2,
                    pageSection: 'Error Screen',
                  });
                }}
                isFocusOnPageLoad={primaryFocused}
                isFocusOnSectionEnter={primaryFocused}
                onFocus={() => secondaryFocused && setPlayInitialLabel(false)}
                selectionOverrides={{ down: '', left: '', right: '' }}
                disabled={loading}
              >
                <ArrowLeftIcon />
                {primaryLabel}
              </Button>
            ) : null}
          </FocusableSection>
        </div>
      </div>

      <div className="error-screen__footer">
        <p>
          <Trans id={error.footer} values={{ helpcenter: BRAND_HELPCENTER }} />
        </p>
      </div>
    </div>
  );
}

ErrorScreen.propTypes = {
  error: PropTypes.oneOf(Object.values(ERROR_TYPE)),
  onPrimaryClick: PropTypes.func,
  onSecondaryClick: PropTypes.func,
  focus: PropTypes.string,
  primaryLabel: PropTypes.string,
  loading: PropTypes.bool,
};

ErrorScreen.defaultProps = {
  error: ERROR_TYPE.CONNECTION_LOST,
  onPrimaryClick: () => void 0,
  onSecondaryClick: () => void 0,
  focus: 'primary',
  primaryLabel: 'Exit',
  loading: false,
};

export default ErrorScreen;
