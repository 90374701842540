import { useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Trans, t } from '@lingui/macro';
import { JsSpatialNavigation } from 'react-js-spatial-navigation';

// Components
import Button from '../button/button';
import FocusableSection from '../spatial-navigation/focusable-section';

// Config
import ERROR_TYPE from '../../config/error-type-config';
import BRAND_CONFIG from '../../config/brand-config.json';

// Enum
import MODAL_EXIT_STATUS from '../../enums/modal-exit-status';

// Styles
import './error-overlay.scss';

// utils
import { isKey } from '../../utils/utils';
import {
  KEYCODES,
  supportCapability,
  exitApplication,
} from '../../platform/index';
import PLATFORM_CAPABILITIES from '../../enums/platform-capabilities';

// Icons
import { ReactComponent as AlertIcon } from '../../assets/icons/alert.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left.svg';
import { ReactComponent as CircleForwardIcon } from '../../assets/icons/circle-forward.svg';

// Hooks
import useModalHandler from '../../hooks/use-modal-handler';

function ErrorOverlay({
  error,
  backStatus,
  showTryAgainButton,
  showGoBackButton,
  showExitButton,
  tryAgainStatus,
  goBackStatus,
  tryAgainCallback,
  goBackCallback,
}) {
  const ArrowLeftIcon = ArrowLeft;
  const tryAgainLabel = t`TRY_AGAIN`;
  const goBackLabel = t`GO_BACK`;
  const exitLabel = t`EXIT`;

  const { closeModal, closeAllModals } = useModalHandler();

  const [playInitialLabel, setPlayInitialLabel] = useState(true);

  const { HELPCENTER: BRAND_HELPCENTER } = BRAND_CONFIG;

  const renderExitButton =
    showExitButton && supportCapability(PLATFORM_CAPABILITIES.EXIT);

  useEffect(() => {
    setPlayInitialLabel(true);
    JsSpatialNavigation.focus('@error-modal-focusable');
  }, []);

  useEffect(() => {
    const onKeyDown = (event) => {
      if (isKey(event.keyCode, KEYCODES.BACK)) {
        closeModal(backStatus);
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => window.removeEventListener('keydown', onKeyDown);
  }, [closeModal, backStatus]);

  const tryAgainDownNavigation = () => {
    if (showGoBackButton) {
      return '#go-back-button';
    }

    if (renderExitButton) {
      return '#error-modal-exit-button';
    }

    return '';
  };

  return (
    <div className={classnames('error-overlay')}>
      <AlertIcon aria-label="Alert icon" className="alert-icon" />

      <div className="error-overlay__content">
        <div className="error-overlay__content__left">
          {/* Error message must be 2 lines to ensure button alignment */}
          <h1>
            <span data-test-id="error-title-first-line">
              <Trans id={error.titleFirstLine} />
            </span>
            <br />
            <span data-test-id="error-title-second-line">
              <Trans id={error.titleSecondLine} />
            </span>
          </h1>
        </div>

        <div className="error-overlay__content__right">
          <FocusableSection sectionId="error-modal-focusable">
            {showTryAgainButton && (
              <Button
                data-test-id="secondary-button"
                id="try-again-button"
                aria-label={
                  playInitialLabel
                    ? t({
                        id: error.TTS,
                        values: {
                          focusedLabel: tryAgainLabel,
                          helpcenter: BRAND_HELPCENTER,
                        },
                      })
                    : tryAgainLabel
                }
                onClick={() => {
                  closeModal(tryAgainStatus);
                  tryAgainCallback && tryAgainCallback();
                }}
                isFocusOnPageLoad
                isFocusOnSectionEnter
                selectionOverrides={{
                  up: '',
                  left: '',
                  right: '',
                  down: tryAgainDownNavigation(),
                }}
                onFocus={() => setPlayInitialLabel(false)}
              >
                <CircleForwardIcon />
                <Trans>TRY_AGAIN</Trans>
              </Button>
            )}

            {showGoBackButton && (
              <Button
                data-test-id="primary-button"
                id="go-back-button"
                icon={ArrowLeftIcon}
                aria-label={
                  playInitialLabel
                    ? t({
                        id: error.TTS,
                        values: {
                          focusedLabel: goBackLabel,
                          helpcenter: BRAND_HELPCENTER,
                        },
                      })
                    : goBackLabel
                }
                onClick={() => {
                  closeAllModals(goBackStatus);
                  goBackCallback && goBackCallback();
                }}
                onFocus={() => setPlayInitialLabel(false)}
                selectionOverrides={{ down: '', left: '', right: '' }}
              >
                {goBackLabel}
              </Button>
            )}

            {renderExitButton && (
              <Button
                data-test-id="error-modal-exit-button"
                id="error-modal-exit-button"
                aria-label={exitLabel}
                icon={ArrowLeftIcon}
                onClick={() => exitApplication()}
                onFocus={() => setPlayInitialLabel(false)}
                selectionOverrides={{ down: '', left: '', right: '' }}
              >
                {exitLabel}
              </Button>
            )}
          </FocusableSection>
        </div>
      </div>

      <div className="error-overlay__footer">
        <p data-test-id="error-footer">
          <Trans id={error.footer} values={{ helpcenter: BRAND_HELPCENTER }} />
        </p>
      </div>
    </div>
  );
}

ErrorOverlay.propTypes = {
  error: PropTypes.oneOf(Object.values(ERROR_TYPE)),
  backStatus: PropTypes.oneOf(Object.values(MODAL_EXIT_STATUS)),
  goBackStatus: PropTypes.oneOf(Object.values(MODAL_EXIT_STATUS)),
  tryAgainStatus: PropTypes.oneOf(Object.values(MODAL_EXIT_STATUS)),
  showTryAgainButton: PropTypes.bool,
  showGoBackButton: PropTypes.bool,
  showExitButton: PropTypes.bool,
  tryAgainCallback: PropTypes.func,
  goBackCallback: PropTypes.func,
};

ErrorOverlay.defualtProps = {
  showTryAgainButton: true,
  showGoBackButton: true,
  showExitButton: false,
  tryAgainCallback: () => void 0,
  goBackCallback: () => void 0,
};

export default ErrorOverlay;
