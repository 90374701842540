import { useCallback } from 'react';
import { JsSpatialNavigation } from 'react-js-spatial-navigation';
import { shallow } from 'zustand/shallow';
import useGlobalContext from './use-global-context';

function useModalHandler() {
  const {
    isModalShown,
    setIsModalShown,
    modalChange,
    setModalChange,
    modalArray,
    setModalArray,
    modal,
    setModal,
  } = useGlobalContext(
    (state) => ({
      isModalShown: state.modals.isModalShown,
      setIsModalShown: state.setIsModalShown,
      modalChange: state.modals.modalChange,
      setModalChange: state.setModalChange,
      modalArray: state.modals.modalArray,
      setModalArray: state.setModalArray,
      modal: state.modals.modal,
      setModal: state.setModal,
    }),
    shallow
  );

  const isModalOn = useCallback(() => {
    return isModalShown;
  }, [isModalShown]);

  const getModalType = useCallback(() => {
    return modal?.type || null;
  }, [modal]);

  const showModal = useCallback(
    (type, props, focusOnClose) => {
      const modalFound = modalArray.find((modal) => modal.type === type);
      if (modalFound) {
        return;
      }
      setModalChange(!modalChange);
      setIsModalShown(true);
      setModalArray([...modalArray, { type, props, focusOnClose }]);
      setModal({ type, props });

      const openModalEvent = new CustomEvent('open-modal', {
        detail: {
          type,
        },
      });

      window.dispatchEvent(openModalEvent);
    },
    [
      modalArray,
      modalChange,
      setIsModalShown,
      setModal,
      setModalArray,
      setModalChange,
    ]
  );

  const closeModal = useCallback(
    (exitStatus) => {
      let modalBeingClosed;

      if (modalArray.length > 1) {
        const currentModalArray = modalArray.slice(0, modalArray.length - 1);
        const currentModal = modalArray[modalArray.length - 2];
        modalBeingClosed = modalArray[modalArray.length - 1];
        const focusOnClose = modalBeingClosed.focusOnClose;

        setModalArray(currentModalArray);
        setModal({ type: currentModal.type, props: currentModal.props });

        if (focusOnClose) {
          JsSpatialNavigation.focus(focusOnClose);
        }
      } else {
        modalBeingClosed = modal;
        const focusOnClose = modalArray[0].focusOnClose;
        setModal(null);
        setModalChange(!modalChange);
        setModalArray([]);
        setIsModalShown(false);
        if (focusOnClose) {
          JsSpatialNavigation.focus(focusOnClose);
        }
      }

      if (modalBeingClosed) {
        const closeModalEvent = new CustomEvent('close-modal', {
          detail: {
            type: modalBeingClosed.type,
            exitStatus,
          },
        });

        window.dispatchEvent(closeModalEvent);
      }
    },
    [
      modal,
      modalArray,
      modalChange,
      setIsModalShown,
      setModal,
      setModalArray,
      setModalChange,
    ]
  );

  const closeAllModals = useCallback(
    (exitStatus) => {
      const modalBeingClosed = modalArray[modalArray.length - 1];
      setModal(null);
      setModalArray([]);
      setIsModalShown(false);

      if (modalBeingClosed) {
        const closeModalEvent = new CustomEvent('close-modal', {
          detail: {
            type: modalBeingClosed.type,
            exitStatus,
          },
        });

        window.dispatchEvent(closeModalEvent);
      }
    },
    [modalArray, setIsModalShown, setModal, setModalArray]
  );

  return {
    modal,
    showModal,
    getModalType,
    closeModal,
    closeAllModals,
    isModalOn,
    modalChange,
  };
}

export default useModalHandler;
