import { makeRequest } from '../common/base';

/**
 * Gets a list of Genres in alphabetical order by type
 * @param {string} channelType The type of channel (i.e. Shows, Movies)
 * @returns {Promise<{}>} A promise containing a JSON data object with an items property, which holds an array of genre objects.
 */
const fetchGenres = async (channelType) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return makeRequest(`/genres/${channelType}`, requestOptions);
};

export { fetchGenres };
