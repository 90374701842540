const MODAL_TYPES = {
  WATCHLIST_ADD_SERIES: 'WATCHLIST_ADD_SERIES',
  WATCHLIST_CONTENT_CLICK: 'WATCHLIST_CONTENT_CLICK',
  WATCHLIST_SIGN_OUT: 'WATCHLIST_SIGN_OUT',
  GET_YOUR_WATCHLIST: 'GET_YOUR_WATCHLIST',
  REWARDS_AUTH: 'REWARDS_AUTH',
  AUTH: 'AUTH',
  REWARDS_REDEEM: 'REWARDS_REDEEM',
  REWARDS_ADS_NOTICE: 'REWARDS_ADS_NOTICE',
  PIN_MODAL: 'PIN_MODAL',
  ERROR: 'ERROR',
  EXIT_APP: 'EXIT_APP',
  STILL_WATCHING: 'STILL_WATCHING',
  SORT: 'SORT',
  WATCHLIST_AUTH: 'WATCHLIST_AUTH',
};

export default MODAL_TYPES;
