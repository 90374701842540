import { useEffect, useState } from 'react';
import { JsSpatialNavigation } from 'react-js-spatial-navigation';
import { Trans, t } from '@lingui/macro';
import { shallow } from 'zustand/shallow';
import PropTypes from 'prop-types';
import { getDeviceInformation, KEYCODES, tts } from '../../platform/index';

// Styles
import './auth-overlay.scss';

// Enums
import BRAND_CONFIG from '../../config/brand-config.json';
import BUTTON_TYPES from '../../enums/button-types';
import ELEMENT_TYPES from '../../enums/element-types';
import MODAL_EXIT_STATUS from '../../enums/modal-exit-status';
import ACTIVATION_CODE_TYPES from '../../enums/activation-code-types';
import MODAL_TYPES from '../../enums/modal-types';

// Components
import LoadingSpinner from '../loading-spinner/loading-spinner';
import Focusable from '../spatial-navigation/focusable';
import FocusableSection from '../spatial-navigation/focusable-section';
import FadingBackgroundImages from '../fading-background-images/fading-background-images';
import Button from '../button/button';

// icon
import { ReactComponent as CircleBackIcon } from '../../assets/icons/circle-back.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/cross.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg';

// Hooks
import useInterval from '../../hooks/use-interval';
import useGlobalContext from '../../hooks/use-global-context';
import useFreePassTimer from '../../hooks/use-free-pass-timer';
import useModalHandler from '../../hooks/use-modal-handler';

// API
import {
  authenticateDevice,
  generateDeviceActivationCode,
} from '../../crackle-sdk/v1/api/devices';
import CrackleApiError from '../../crackle-sdk/v1/api/error';

// Utils
import logger from '../../utils/logger';
import mParticle from '../../utils/mparticle';
import { setSignedInUser } from '../../utils/signed-in-user';
import { getActivationTimeRemaining, isKey } from '../../utils/utils';

import { isFeatureFlagEnabled } from '../../utils/feature-flags';
import { isDOBUnderAgeLimit } from '../../utils/dates';

function AuthOverlay({ codeType, from }) {
  const {
    loyaltyOptedIn,
    setUserPrivacySettings,
    setLoyaltyOptIn,
    setUserBirthday,
  } = useGlobalContext(
    (state) => ({
      loyaltyOptedIn: state.loyalty.optedIn,
      setUserPrivacySettings: state.setUserPrivacySettings,
      setUserBirthday: state.setUserBirthday,
      setLoyaltyOptIn: state.setLoyaltyOptIn,
    }),
    shallow
  );

  // To verify if there's a free pass active
  const { checkFreePassActive } = useFreePassTimer();

  const { closeModal, closeAllModals } = useModalHandler();

  // Show States

  const [showWelcome, setShowWelcome] = useState(false);
  const [showTimeOut, setShowTimeOut] = useState(false);
  /**
   * Generate a six digit device activation code based on device id, brand and platform to
   * authenticate a device against.
   *
   * Each generated code is valid for 30 minutes.
   *
   * The DEVICE CODE is a 6-character string of letters and numbers, all upper case, without intervening spaces or the
   * letters Q (q), I (i), and O (o); these are  * omitted to avoid confusion with the numerals 0 and 1.
   */

  const [isLoading, setIsLoading] = useState(false);
  const [deviceId, setDeviceId] = useState(null);
  const [activationCode, setActivationCode] = useState('');
  const [pollingEnabled, setPollingEnabled] = useState(false);

  const ACTIVATION_CODE_TIMEOUT = 30 * 60000; // Generated activation code is valid for 30 min.
  const ACTIVATION_CODE_POLL_INTERVAL = 10000; // Every 10 seconds, poll for activation code

  // Fetch activation code
  useEffect(() => {
    let timeout = null;
    // Set initial 30 minutes.
    let activationTimeRemaining = ACTIVATION_CODE_TIMEOUT;

    const getActivationCode = async () => {
      let deviceInfo;

      try {
        deviceInfo = await getDeviceInformation();
        const dataObj = await generateDeviceActivationCode(
          deviceInfo.duid,
          deviceInfo.modelName,
          codeType
        );

        const { code, expires } = dataObj;
        setIsLoading(false);
        tts(
          [
            BRAND_CONFIG.JOIN_URL,
            `${t`SIGN_IN_DESCRIPTION_FIRST`} ${t`SIGN_IN_DESCRIPTION_SECOND`}`,
            code,
            t`SIGN_IN_DESCRIPTION_BOTTOM`,
          ].join('. ')
        );
        setActivationCode(code);
        mParticle.mParticleUserDeviceActivationCodeGenerated(code);

        // Use util to determine if time is valid.
        const timeRemaining = getActivationTimeRemaining(expires);
        // If timeRemaining is greater than 30 mins it will not be null.
        if (timeRemaining) {
          // Use ttl if there is time remaining.
          activationTimeRemaining = timeRemaining;
        }

        // Redirect to timeout screen if the user hasn't activated the account
        timeout = setTimeout(() => {
          mParticle.mParticleUserDeviceActivationCodeTimeout(code);

          setShowTimeOut(true);
        }, activationTimeRemaining);
      } catch (e) {
        setIsLoading(false);

        if (e instanceof CrackleApiError) {
          logger.error(e);
        } else {
          logger.error(
            'Error occurred while generating device activation code',
            e
          );
        }
      } finally {
        setDeviceId(deviceInfo.duid);
        setPollingEnabled(true);
      }
    };

    clearTimeout(timeout);
    setIsLoading(true);
    getActivationCode();

    return () => {
      clearTimeout(timeout);
    };
  }, [ACTIVATION_CODE_TIMEOUT, codeType]);

  useInterval(
    () => {
      const poll = async () => {
        try {
          const dataObj = await authenticateDevice(deviceId, activationCode);

          const { auth, user } = dataObj;
          const { accessToken, refreshToken } = auth;
          const { email, firstName, lastName, userId, userPrivacy, birthday } =
            user;

          setSignedInUser(
            { email, firstName, lastName, userId },
            accessToken,
            refreshToken
          );
          mParticle.mParticleLoginEvent({ email, userId });
          setPollingEnabled(false);
          if (userId) {
            // To enable all privacy restrictions when the user is under 16
            if (isDOBUnderAgeLimit(birthday)) {
              userPrivacy.doNotSell = true;
              userPrivacy.doNotShare = true;
            }
            setUserPrivacySettings(userPrivacy);
            setUserBirthday(birthday);

            if (isFeatureFlagEnabled('loyalty')) {
              setLoyaltyOptIn(user.userLoyaltyOptIn.status);
              await checkFreePassActive();
            }
          }

          // STATE TO SHOW WELCOME AND THEN CLOSE MODAL
          setShowWelcome(true);
        } catch (e) {
          if (e instanceof CrackleApiError) {
            logger.error(e);
          } else {
            logger.error('Error occurred while authenticating device', e);
          }
        }
      };

      poll();
    },
    // Interval in milliseconds or null to stop it
    pollingEnabled ? ACTIVATION_CODE_POLL_INTERVAL : null
  );

  useEffect(() => {
    JsSpatialNavigation.focus('auth-overlay');
  }, []);

  useEffect(() => {
    const onKeyDown = (event) => {
      if (isKey(event.keyCode, KEYCODES.BACK)) {
        if (codeType === ACTIVATION_CODE_TYPES.SIGNIN) {
          closeModal(MODAL_EXIT_STATUS.AUTH_SIGNED_OUT);
        } else {
          closeModal(MODAL_EXIT_STATUS.AUTH_CREATE_ACCOUNT);
        }
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => window.removeEventListener('keydown', onKeyDown);
  }, [closeModal, codeType]);

  useEffect(() => {
    // Close modal after 5 seconds
    let closeAuthModal;
    if (showWelcome) {
      JsSpatialNavigation.focus('.welcome-title');

      closeAuthModal = setTimeout(() => {
        if (from === MODAL_TYPES.GET_YOUR_WATCHLIST) {
          closeAllModals(MODAL_EXIT_STATUS.WATCHLIST_SIGN_IN);
          return;
        }
        if (loyaltyOptedIn) {
          closeAllModals(MODAL_EXIT_STATUS.AUTH_SIGNED_IN_OPT_IN);
        } else {
          closeModal(MODAL_EXIT_STATUS.AUTH_SIGNED_IN);
        }
      }, 5000);
    }

    return () => {
      clearTimeout(closeAuthModal);
    };
  }, [showWelcome, closeModal, loyaltyOptedIn, closeAllModals, from]);

  useEffect(() => {
    if (showTimeOut) {
      JsSpatialNavigation.focus('@sign-in-focusable');
    }
  }, [showTimeOut]);

  const generateWelcome = () => (
    <div aria-label="Welcome" className="auth-overlay__welcome">
      <FadingBackgroundImages />
      <div className="auth-overlay__welcome__text-container">
        <div className="title">
          <Focusable
            elementType={ELEMENT_TYPES.SPAN}
            aria-label={`${t`WELCOME`}. ${t`WELCOME_DESCRIPTION`}`}
            className="welcome-title"
          >
            {t`WELCOME`}
          </Focusable>
        </div>
        <div className="description">{t`WELCOME_DESCRIPTION`}</div>
      </div>
    </div>
  );
  const tryAgainLabel = `${t`TIMEOUT_SCREEN_TITLE`}. ${t`TIMEOUT_SCREEN_DESCRIPTION`}. ${t`TRY_AGAIN`}`;

  const generateTimeOut = () => (
    <div aria-label="Sign In Timeout" className="auth-overlay__timeout">
      <FocusableSection sectionId="timeOutText">
        <Focusable isFocusOnPageLoad isFocusOnSectionEnter>
          <div className="auth-overlay__timeout__text-container">
            <div className="title">{t`TIMEOUT_SCREEN_TITLE`}</div>
            <div className="description">{t`TIMEOUT_SCREEN_DESCRIPTION`}</div>
          </div>
        </Focusable>
      </FocusableSection>
      <div className="auth-overlay__timeout__buttons">
        <FocusableSection className="inner" sectionId="sign-in-focusable">
          <Button
            aria-label={tryAgainLabel}
            elementType={ELEMENT_TYPES.BUTTON}
            icon={CircleBackIcon}
            type={BUTTON_TYPES.ICONBUTTON}
            onClick={() => {
              setShowTimeOut(false);
            }}
            isFocusOnPageLoad
            isFocusOnSectionEnter
          >
            <Trans>TRY_AGAIN</Trans>
          </Button>
          <Button
            aria-label={t`CANCEL`}
            data-test-id="rewards-cancel-button"
            className="rewards-auth__signed-in-opted-out__buttons__cancel"
            elementType={ELEMENT_TYPES.BUTTON}
            icon={CrossIcon}
            type={BUTTON_TYPES.ICONBUTTON}
            selectionOverrides={{
              up: '',
              right: '',
              down: '',
            }}
            onClick={() => {
              if (codeType === ACTIVATION_CODE_TYPES.SIGNIN) {
                closeModal(MODAL_EXIT_STATUS.AUTH_SIGNED_OUT);
              } else {
                closeModal(MODAL_EXIT_STATUS.AUTH_CREATE_ACCOUNT);
              }
            }}
          >
            <Trans>CANCEL</Trans>
          </Button>
        </FocusableSection>
      </div>
    </div>
  );

  return (
    <>
      {!showWelcome && !showTimeOut && (
        <div aria-hidden className="auth-overlay">
          <FocusableSection aria-hidden sectionId="auth-overlay">
            <Focusable
              aria-hidden
              isFocusOnPageLoad
              isFocusOnSectionEnter
              selectionOverrides={{ down: '', up: '', left: '', right: '' }}
            >
              <PhoneIcon aria-hidden className="auth-overlay__phone" />
              <section className="auth-overlay__top">
                <div className="url">{BRAND_CONFIG.JOIN_URL}</div>
                <div className="description">
                  {t`SIGN_IN_DESCRIPTION_FIRST`}
                  <br /> {t`SIGN_IN_DESCRIPTION_SECOND`}
                </div>
                <div className="code">{activationCode}</div>
                {isLoading && (
                  <LoadingSpinner className="auth-overlayt__spinner" />
                )}
              </section>
              <section className="auth-overlay__bottom">
                <div className="auth-overlay__bottom__description">
                  {t`SIGN_IN_DESCRIPTION_BOTTOM`}
                </div>
              </section>
            </Focusable>
          </FocusableSection>
        </div>
      )}
      {showWelcome && !showTimeOut && generateWelcome()}
      {!showWelcome && showTimeOut && generateTimeOut()}
    </>
  );
}

AuthOverlay.propTypes = {
  codeType: PropTypes.string,
  from: PropTypes.string,
};

export default AuthOverlay;
