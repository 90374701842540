import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { JsSpatialNavigation } from 'react-js-spatial-navigation';

// Components
import Button from '../button/button';
import FocusableSection from '../spatial-navigation/focusable-section';

// Config
import SORT_OPTIONS from '../../config/sort-config';

// Styles
import './sort-overlay.scss';

// Enums
import ELEMENT_TYPES from '../../enums/element-types';
import CHANNEL_TYPES from '../../enums/channel-types';
import ROUTES from '../../enums/routes';
import BROWSE from '../../enums/browse';
import MODAL_EXIT_STATUS from '../../enums/modal-exit-status';

// Hooks
import useModalHandler from '../../hooks/use-modal-handler';

// Icons
import { ReactComponent as SortIcon } from '../../assets/icons/sort.svg';
import { ReactComponent as CheckCircle } from '../../assets/icons/check-circle.svg';
import { ReactComponent as EmptyCircle } from '../../assets/icons/empty-circle.svg';

// Utils
import { isKey } from '../../utils/utils';
import { KEYCODES } from '../../platform/index';
import mParticle from '../../utils/mparticle';

const allowedMovement = (options, index) => {
  return {
    left: options.id,
    right: options.id,
    up: index === 0 ? options.id : null,
    down: index === SORT_OPTIONS.length - 1 ? options.id : null,
  };
};

/**
 * Get new channel route after user selected sort order.
 *
 * @function getChannelRoute
 * @param {string} channelType - channel type like movies, shows, etc.
 * @param {string} genre - genre name
 * @param {string} optionId - sort order id(name)
 * @return {string} - new route string
 */
const getChannelRoute = (channelType, genre, optionId) => {
  const channelPath =
    channelType === CHANNEL_TYPES.SERIES ? ROUTES.TVSHOWS : ROUTES.MOVIES;
  return `${channelPath}?genre=${encodeURIComponent(genre)}&sort=${optionId}`;
};

/**
 * Get sort order modal title.
 *
 * @function getModalTitle
 * @param {string} channelType - channel type like movies, shows, etc.
 * @param {string} genre - genre name
 * @return {string} - sort order modal title
 * @example
 * All Movies, All TV Shows, Action Movies, Action TV Shows
 */
const getModalTitle = (channelType, genre) => {
  const genreTitle = genre && genre !== BROWSE.ALL ? genre : '';

  if (channelType === CHANNEL_TYPES.SERIES) {
    return genreTitle === '' ? BROWSE.ALL_TV_SHOWS : `${genreTitle}`;
  }

  return genreTitle === '' ? BROWSE.ALL_MOVIES : `${genreTitle}`;
};

function SortOverlay({ sort, genre, setSort, channelType }) {
  const CheckIcon = CheckCircle;
  const EmptyIcon = EmptyCircle;

  const navigate = useNavigate();

  const { closeModal } = useModalHandler();

  useEffect(() => {
    JsSpatialNavigation.focus('@sort-modal-focusable');
  }, []);

  useEffect(() => {
    const onKeyDown = (event) => {
      if (isKey(event.keyCode, KEYCODES.BACK)) {
        closeModal(MODAL_EXIT_STATUS.SORT_EXIT);
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => window.removeEventListener('keydown', onKeyDown);
  }, [closeModal]);

  const handleSortChange = (selectedOptionId) => {
    setSort(selectedOptionId);
    closeModal(MODAL_EXIT_STATUS.SORT_EXIT);
  };

  return (
    <div className="sort-modal">
      <SortIcon className="sort-modal__icon" />
      <div className="sort-modal__item__container">
        <div className="sort-modal__text">
          <h1>
            <Trans>SORT</Trans>
          </h1>
          <span>{getModalTitle(channelType, genre)}</span>
          <h1>
            <Trans>BY</Trans>
          </h1>
        </div>

        <FocusableSection
          className="sort-modal__options"
          sectionId="sort-modal-focusable"
        >
          {SORT_OPTIONS.map((options, index) => {
            const selected = sort === options.id;
            const SelectedIcon = selected ? CheckIcon : EmptyIcon;

            return (
              <Button
                key={options.id}
                aria-label={options.label}
                id={options.id}
                className={classnames('sort-modal__selector', {
                  selected,
                })}
                onClick={() => {
                  navigate(getChannelRoute(channelType, genre, options.id), {
                    replace: true,
                  });
                  handleSortChange(options.id);
                  mParticle.mParticleButtonClick({
                    buttonLabel: `Sort: ${options.name}`,
                    buttonPosition: index + 1,
                    pageSection: 'Sort Modal Options',
                  });
                  mParticle.mParticleSortContentView({
                    sortType: options.name,
                    categoryName: getModalTitle(channelType, genre),
                    categoryPosition: `${index + 1}`,
                    pageSection: 'Sort menu',
                    buttonLabel: `Sort: ${options.name}`,
                  });
                }}
                elementType={ELEMENT_TYPES.BUTTON}
                selectionOverrides={allowedMovement(options, index)}
                isFocusOnPageLoad={index === 0}
              >
                <label htmlFor={options.label} className="sort-modal__checkbox">
                  <input
                    type="checkbox"
                    id={options.label}
                    checked={selected}
                    onChange={() => void 0}
                  />
                  <SelectedIcon />
                  <span aria-hidden>{options.label}</span>
                </label>
              </Button>
            );
          })}
        </FocusableSection>
      </div>
    </div>
  );
}

SortOverlay.propTypes = {
  sort: PropTypes.string,
  genre: PropTypes.string,
  setSort: PropTypes.func,
  channelType: PropTypes.string,
};

export default SortOverlay;
