import { vttStrToVttObj } from '../utils/vtt';

export default function fetchVttData(urlFile) {
  const urlPrefix = urlFile?.slice(0, urlFile.lastIndexOf('/') + 1);
  if (!urlPrefix) return [];
  return new Promise((resolve, reject) => {
    fetch(urlFile)
      .then((res) => res.text())
      .then((res) => {
        const json = vttStrToVttObj(res, urlPrefix);
        if (json) resolve(json);
        else reject();
      })
      .catch(reject);
  });
}
