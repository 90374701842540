import { makeRequest } from './common/base';
import { getFromCache, setCache } from './utils/session-cache';

export const endpoints = {
  getCuratedPage: () => '/curations',
  getHomePageHeroConfiguration: () => '/curations/hero',
};

// Curations api
function fetchBlocks(page) {
  const cachedData = getFromCache(`${page}-curation`);

  if (cachedData) {
    return new Promise((resolve) => {
      resolve(cachedData);
    }).then((response) => response);
  }

  const urlSearchParams = new URLSearchParams({
    page,
  });

  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return makeRequest(
    `${endpoints.getCuratedPage()}?${urlSearchParams.toString()}`,
    requestOptions
  ).then((response) => {
    const data = response;
    setCache(`${page}-curation`, data);

    return data;
  });
}
/**
 * Gets a Hero configuration object that has information about the hero section of the page
 * @returns {Promise<{}>} A promise containing a JSON data object
 */

async function fetchHeroInfo() {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return makeRequest(endpoints.getHomePageHeroConfiguration(), requestOptions);
}

export { fetchBlocks, fetchHeroInfo };
