import { Component } from 'react';
import PropTypes from 'prop-types';
import logger from '../../utils/logger';

export default class ErrorBoundary extends Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { onError } = this.props;

    logger.error(error);
    logger.error(errorInfo);
    onError && onError();
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  onError: PropTypes.func,
};
