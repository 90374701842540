import { useEffect } from 'react';
import { Trans, t } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';
import { JsSpatialNavigation } from 'react-js-spatial-navigation';

// Components
import Button from '../../components/button/button';
import FocusableSection from '../../components/spatial-navigation/focusable-section';

// Icons
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import { ReactComponent as CircleBackIcon } from '../../assets/icons/circle-back.svg';

// Enums
import ROUTES from '../../enums/routes';
import ACTIVATION_CODE_TYPES from '../../enums/activation-code-types';
import BUTTON_TYPES from '../../enums/button-types';
import ELEMENT_TYPES from '../../enums/element-types';
import { KEYCODES } from '../../platform/index';

import { isKey } from '../../utils/utils';

// Hooks
import useModalHandler from '../../hooks/use-modal-handler';

// Styles
import './get-your-watchlist.scss';
import MODAL_EXIT_STATUS from '../../enums/modal-exit-status';

function GetYourWatchlist() {
  const navigate = useNavigate();
  const { closeModal } = useModalHandler();

  useEffect(() => {
    JsSpatialNavigation.focus('get-your-watchlist-focusable');
  }, []);

  useEffect(() => {
    const onKeyDown = (event) => {
      if (isKey(event.keyCode, KEYCODES.BACK)) {
        closeModal(MODAL_EXIT_STATUS.WATCHLIST_CANCEL);
        navigate(-1);
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => window.removeEventListener('keydown', onKeyDown);
  }, [closeModal, navigate]);

  return (
    <div className="get-your-watchlist">
      <AddIcon aria-label="AddIcon" className="add-icon" />

      <div className="get-your-watchlist__content">
        <div className="get-your-watchlist__content__left">
          <h1>
            <Trans id="WATCHLIST_GET_YOUR">
              Get your <br /> <span className="watchlist-title">Watchlist</span>
            </Trans>
          </h1>
        </div>
        <div className="get-your-watchlist__content__right">
          <FocusableSection sectionId="get-your-watchlist-focusable">
            <Button
              data-test-id="create-account"
              to={ROUTES.ACCOUNT}
              state={{
                codeType: ACTIVATION_CODE_TYPES.SIGNUP,
                last: ROUTES.WATCHLIST,
              }}
              className="get-your-watchlist__content__right__sign-up-button"
              aria-label={t`CREATE_AN_ACCOUNT`}
              type={BUTTON_TYPES.TEXTBUTTON}
              elementType={ELEMENT_TYPES.LINK}
              isFocusOnPageLoad
              isFocusOnSectionEnter
              selectionOverrides={{ up: '', left: '', right: '' }}
            >
              <div aria-hidden="true">{t`CREATE_AN_ACCOUNT`}</div>
            </Button>
            <Button
              data-test-id="sign-in"
              to={ROUTES.ACCOUNT}
              state={{
                codeType: ACTIVATION_CODE_TYPES.SIGNIN,
                last: ROUTES.WATCHLIST,
              }}
              className="get-your-watchlist__content__right__sign-in-button"
              type={BUTTON_TYPES.TEXTBUTTON}
              elementType={ELEMENT_TYPES.LINK}
              aria-label={t`SIGN_IN`}
              selectionOverrides={{ left: '', right: '' }}
            >
              <div aria-hidden="true">{t`SIGN_IN`}</div>
            </Button>
            <Button
              data-test-id="cancel"
              className="get-your-watchlist__content__right__cancel-button"
              onClick={() => {
                closeModal(MODAL_EXIT_STATUS.WATCHLIST_CANCEL);
                navigate(-1);
              }}
              aria-label={t`CANCEL`}
              selectionOverrides={{ down: '', left: '', right: '' }}
            >
              <div
                aria-hidden="true"
                className="get-your-watchlist__content__right__cancel-button__inside"
              >
                <CircleBackIcon />
                <span>{t`CANCEL`}</span>
              </div>
            </Button>
          </FocusableSection>
        </div>
      </div>
      <div className="get-your-watchlist__footer">
        <p>
          <Trans>GET_YOUR_WATCHLIST_FOOTER</Trans>
        </p>
      </div>
    </div>
  );
}

export default GetYourWatchlist;
