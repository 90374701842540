import { setClosedCaptionStyleCallback } from '../platform/index';
import logger from './logger';

const COLORS = {
  BLACK: 'black',
  WHITE: 'white',
  GREEN: 'green',
  BLUE: 'blue',
  RED: 'red',
  CYAN: 'cyan',
  YELLOW: 'yellow',
  MAGENTA: 'magenta',
};

const RGB_COLORS = {
  BLACK: '0, 0, 0',
  WHITE: '255, 255, 255',
  GREEN: '0, 255, 0',
  BLUE: '0, 0, 255',
  RED: '255, 0, 0',
  CYAN: '0, 255, 255',
  YELLOW: '255, 255, 0',
  MAGENTA: '255, 0, 255',
};

function createTextBorder(color) {
  return `${color} 2px 0px 0px, ${color} 1.75517px 0.958851px 0px, ${color} 1.0806px 1.68294px 0px, ${color} 0.141474px 1.99499px 0px, ${color} -0.832294px 1.81859px 0px, ${color} -1.60229px 1.19694px 0px, ${color} -1.97998px 0.28224px 0px, ${color} -1.87291px -0.701566px 0px, ${color} -1.30729px -1.5136px 0px, ${color} -0.421592px -1.95506px 0px, ${color} 0.567324px -1.91785px 0px, ${color} 1.41734px -1.41108px 0px, ${color} 1.92034px -0.558831px 0px`;
}

function setClosedCaptionStyle() {
  setClosedCaptionStyleCallback((tvCcStyles) => {
    logger.debug('closedCaptionStyleCallback called', tvCcStyles);
    const textSizeOptions = { Small: '1.09vw', Large: '1.95vw' };
    const colorSet = {
      Black: COLORS.BLACK,
      Blue: COLORS.BLUE,
      Cyan: COLORS.CYAN,
      Green: COLORS.GREEN,
      Magenta: COLORS.MAGENTA,
      Red: COLORS.RED,
      White: COLORS.WHITE,
      Yellow: COLORS.YELLOW,
    };

    const RgbColorSet = {
      Black: RGB_COLORS.BLACK,
      Blue: RGB_COLORS.BLUE,
      Cyan: RGB_COLORS.CYAN,
      Green: RGB_COLORS.GREEN,
      Magenta: RGB_COLORS.MAGENTA,
      Red: RGB_COLORS.RED,
      White: RGB_COLORS.WHITE,
      Yellow: RGB_COLORS.YELLOW,
    };

    const {
      background_color: backgroundColor,
      background_opacity: backgroundOpacity,
      text_color: textColor,
      text_edges_color: textEdgesColor,
      text_edges: textEdges,
      text_opacity: textOpacity,
      text_size: textSize,
      text_style: textStyle,
      window_color: windowColor,
      window_opacity: windowOpacity,
    } = tvCcStyles;

    const ccTextEdgesColor = Object.keys(colorSet).includes(textEdgesColor)
      ? colorSet[textEdgesColor]
      : 'white';

    const textEdgesOptions = {
      Raised: '2px 0px 0px var(--cc-text-edges-color)',
      Depressed: '-2px 0px 0px var(--cc-text-edges-color)',
      Uniform: createTextBorder(ccTextEdgesColor),
      'Drop Shadow': '3px 0px 0px var(--cc-text-edges-color)',
    };

    const textOpacityOptions = {
      Translucent: '50%',
      Transparent: '0',
      Flashing: 'text-blinker 1.5s step-start infinite',
    };

    const backgroundOpacityOptions = {
      Translucent: '50%',
      Transparent: '0',
      Flashing: 'background-blinker 1.5s step-start infinite',
    };

    const windowOpacityOptions = {
      Translucent: '50%',
      Transparent: '0',
      Flashing: 'window-blinker 1.5s step-start infinite',
    };

    const textStyleOptions = {
      Casual: 'Comic Sans, var(--default-font-family)',
      Computer: 'Arial, var(--default-font-family)',
      Modern: 'Sans Serif, var(--default-font-family)',
      Newsprint: 'Courier, var(--default-font-family)',
      Playbill: 'Playbill, var(--default-font-family)',
      Script: 'Century Schoolbook, var(--default-font-family)',
    };

    // Text style
    if (Object.keys(textStyleOptions).includes(textStyle)) {
      document.documentElement.style.setProperty(
        '--cc-text-style',
        textStyleOptions[textStyle]
      );
    } else {
      document.documentElement.style.setProperty(
        '--cc-text-style',
        'var(--default-font-family)'
      );
    }

    document.documentElement.style.setProperty(
      '--cc-text-capitalize',
      textStyle === 'Capitals' ? 'capitalize' : 'none'
    );

    // Font size
    document.documentElement.style.setProperty(
      '--cc-text-size',
      Object.keys(textSizeOptions).includes(textSize)
        ? textSizeOptions[textSize]
        : 'var(--default-cc-font-size)'
    );

    // Text color
    document.documentElement.style.setProperty(
      '--cc-text-color',
      Object.keys(colorSet).includes(textColor) ? colorSet[textColor] : 'white'
    );

    // Background color
    document.documentElement.style.setProperty(
      '--cc-background-color',
      Object.keys(colorSet).includes(backgroundColor)
        ? colorSet[backgroundColor]
        : 'transparent'
    );

    // Text edges(border) color
    document.documentElement.style.setProperty(
      '--cc-text-edges-color',
      ccTextEdgesColor
    );

    // Text edges(border) style
    document.documentElement.style.setProperty(
      '--cc-text-edges',
      Object.keys(textEdgesOptions).includes(textEdges)
        ? textEdgesOptions[textEdges]
        : 'transparent'
    );

    // Text opacity
    if (Object.keys(textOpacityOptions).includes(textOpacity)) {
      if (
        textOpacity === 'Flashing' &&
        backgroundOpacity !== 'Flashing' &&
        windowOpacity !== 'Flashing'
      ) {
        document.documentElement.style.setProperty(
          '--cc-text-opacity',
          'unset'
        );
        document.documentElement.style.setProperty(
          '--cc-animation',
          textOpacityOptions[textOpacity]
        );
      } else {
        document.documentElement.style.setProperty(
          '--cc-text-opacity',
          textOpacityOptions[textOpacity]
        );
      }
    } else {
      document.documentElement.style.setProperty('--cc-text-opacity', 1);
    }

    // Background opacity
    if (Object.keys(backgroundOpacityOptions).includes(backgroundOpacity)) {
      if (
        backgroundOpacity === 'Flashing' &&
        textOpacity !== 'Flashing' &&
        windowOpacity !== 'Flashing'
      ) {
        document.documentElement.style.setProperty(
          '--cc-animation-background-color',
          `rgba(${RgbColorSet[backgroundColor]}, 1)`
        );
        document.documentElement.style.setProperty(
          '--cc-animation',
          backgroundOpacityOptions[backgroundOpacity]
        );
      } else {
        document.documentElement.style.setProperty(
          '--cc-background-color',
          `rgba(${RgbColorSet[backgroundColor]}, ${backgroundOpacityOptions[backgroundOpacity]})`
        );
      }
    } else {
      document.documentElement.style.setProperty(
        '--cc-background-color',
        Object.keys(colorSet).includes(backgroundColor)
          ? colorSet[backgroundColor]
          : 'transparent'
      );
    }

    // Window color
    document.documentElement.style.setProperty(
      '--cc-window-color',
      Object.keys(colorSet).includes(windowColor)
        ? colorSet[windowColor]
        : 'transparent'
    );

    // Window opacity
    if (Object.keys(windowOpacityOptions).includes(windowOpacity)) {
      if (
        windowOpacity === 'Flashing' &&
        textOpacity !== 'Flashing' &&
        backgroundOpacity !== 'Flashing'
      ) {
        document.documentElement.style.setProperty(
          '--cc-window-opacity',
          `11.72vw solid rgba(${RgbColorSet[windowColor]}, 1)`
        );
        document.documentElement.style.setProperty(
          '--cc-animation',
          windowOpacityOptions[windowOpacity]
        );
      } else {
        document.documentElement.style.setProperty(
          '--cc-window-opacity',
          `11.72vw solid rgba(${RgbColorSet[windowColor]}, ${windowOpacityOptions[windowOpacity]})`
        );
      }
    } else {
      document.documentElement.style.setProperty('--cc-window-opacity', 'none');
    }

    // Text and background animation
    if (
      textOpacity === 'Flashing' &&
      backgroundOpacity === 'Flashing' &&
      windowOpacity !== 'Flashing'
    ) {
      document.documentElement.style.setProperty('--cc-text-opacity', 'unset');
      document.documentElement.style.setProperty(
        '--cc-animation-background-color',
        `rgba(${RgbColorSet[backgroundColor]}, 1)`
      );

      document.documentElement.style.setProperty(
        '--cc-animation',
        'text-background-blinker 1s step-start infinite'
      );
    }

    // Text and window animation
    if (
      textOpacity === 'Flashing' &&
      windowOpacity === 'Flashing' &&
      backgroundOpacity !== 'Flashing'
    ) {
      document.documentElement.style.setProperty('--cc-text-opacity', 'unset');
      document.documentElement.style.setProperty(
        '--cc-window-opacity',
        `11.72vw solid rgba(${RgbColorSet[windowColor]}, 1)`
      );

      document.documentElement.style.setProperty(
        '--cc-animation',
        'text-window-blinker 1s step-start infinite'
      );
    }

    // Window and background animation
    if (
      windowOpacity === 'Flashing' &&
      backgroundOpacity === 'Flashing' &&
      textOpacity !== 'Flashing'
    ) {
      document.documentElement.style.setProperty(
        '--cc-window-opacity',
        `11.72vw solid rgba(${RgbColorSet[windowColor]}, 1)`
      );
      document.documentElement.style.setProperty(
        '--cc-animation-background-color',
        `rgba(${RgbColorSet[backgroundColor]}, 1)`
      );

      document.documentElement.style.setProperty(
        '--cc-animation',
        'window-background-blinker 1s step-start infinite'
      );
    }

    // Text, window and background animation
    if (
      textOpacity === 'Flashing' &&
      backgroundOpacity === 'Flashing' &&
      windowOpacity === 'Flashing'
    ) {
      document.documentElement.style.setProperty('--cc-text-opacity', 'unset');
      document.documentElement.style.setProperty(
        '--cc-window-opacity',
        `11.72vw solid rgba(${RgbColorSet[windowColor]}, 1)`
      );
      document.documentElement.style.setProperty(
        '--cc-animation-background-color',
        `rgba(${RgbColorSet[backgroundColor]}, 1)`
      );

      document.documentElement.style.setProperty(
        '--cc-animation',
        'text-window-background-blinker 1s step-start infinite'
      );
    }

    // Clear up animation
    if (
      windowOpacity !== 'Flashing' &&
      backgroundOpacity !== 'Flashing' &&
      textOpacity !== 'Flashing'
    ) {
      document.documentElement.style.setProperty('--cc-animation', 'none');
    }
  });
}

export default setClosedCaptionStyle;
