/* eslint-disable no-underscore-dangle */
import Content from './content';

class Movie extends Content {
  constructor({ images, ...contentParams }) {
    super(contentParams);

    this._images = images;
  }

  get images() {
    return this._images;
  }

  set images(images) {
    this._images = images;
  }
}

export default Movie;
