import { useLocation } from 'react-router-dom';

// useCases
import { api } from '../../crackle-sdk/v2/';

// Enums
import ROUTES from '../../enums/routes';

import GenreSubMenu from '../../components/submenu/genre-submenu';
import BROWSE from '../../enums/browse';

function GenreSubMenuContainer() {
  const { pathname, search } = useLocation();
  const genresParam =
    pathname === ROUTES.MOVIES ? BROWSE.MOVIES_PARAM : BROWSE.SHOWS_PARAM;
  const hasSpecificGenre = search.includes('genre=');

  const { data: genres, isLoading } = api.useGenres({
    channelType: genresParam,
  });

  return (
    <GenreSubMenu
      pathname={pathname}
      search={search}
      genres={genres}
      isLoading={isLoading}
      hasSpecificGenre={hasSpecificGenre}
    />
  );
}

export default GenreSubMenuContainer;
