import { makeRequest } from './common/base';

const endpoints = {
  deviceAuthentication: () => '/devices/auth',
  generateDeviceActivationCode: () => '/devices/code',
  getDeviceInfoByCode: (deviceCode) => `/devices/code/${deviceCode}`,
};
/**
 * Gets device information and a six digit activation code from the Crackle API
 * @param {string} deviceId The device id
 * @param {string} deviceModel The device model
 * @param {string} codeType The type of code to get (signin / signup)
 * @returns {Promise<{}>} A promise containing a JSON data object with device information and the six digit activation code (valid within 30 minutes)
 */
const generateDeviceActivationCode = async (
  deviceId,
  deviceModel,
  codeType
) => {
  const data = JSON.stringify({
    deviceId,
    deviceModel,
    codeType,
  });

  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: data,
  };

  return makeRequest(endpoints.generateDeviceActivationCode(), requestOptions);
};

/**
 * Gets a global authentication response from the Crackle API, containing auth,
 * user, and user preferences information.
 * @param {string} deviceId The device id
 * @param {string} activationCode The six digit activation code
 * @returns {Promise<{}>} A promise containing a JSON data object with auth, user, and user preferences information
 */
const authenticateDevice = async (deviceId, activationCode) => {
  const data = JSON.stringify({
    deviceId,
    deviceActivationCode: activationCode,
  });

  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: data,
  };

  return makeRequest(endpoints.deviceAuthentication(), requestOptions);
};

/**
 * Gets device information and a six digit activation code from the Crackle API
 * @param {string} activationCode The six digit activation code
 * @returns {Promise<{}>} A promise containing a JSON data object with device information and the six digit activation code (valid within 30 minutes)
 */
const getDeviceInfoByCode = async (activationCode) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return makeRequest(
    endpoints.getDeviceInfoByCode(activationCode),
    requestOptions
  );
};

export {
  generateDeviceActivationCode,
  authenticateDevice,
  getDeviceInfoByCode,
};
