import { JsSpatialNavigation } from 'react-js-spatial-navigation';
import { memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

// Configs
import SPATIAL_NAVIGATION_CONFIG from '../../config/spatial-navigation-config';

// Contexts
import FocusableSectionContext from '../../context/focusable-section-context';

// Utils
import { noop } from '../../utils/utils';

const getDefaultElementSelector = (sectionSelector) => {
  return `${sectionSelector}.${SPATIAL_NAVIGATION_CONFIG.activeClassName}`;
};

const useSectionInitialization = (
  sectionId,
  enterTo,
  leaveFor,
  defaultElement
) => {
  useEffect(() => {
    const selector = `.${sectionId}`;

    const defaultElementSelector = getDefaultElementSelector(selector);

    const sectionOptions = {
      selector,
      enterTo: enterTo || 'default-element',
      defaultElement: defaultElement || defaultElementSelector,
      leaveFor,
    };

    JsSpatialNavigation.add(sectionId, sectionOptions);

    if (defaultElement) {
      // if defaultElement is provided, we need to focus it manually, that's why the parent component should handle a ref to indicate if the user already moved manually the focus and reset this value
      JsSpatialNavigation.focus(defaultElement);
    }

    return () => {
      JsSpatialNavigation.remove(sectionId);
    };
  }, [sectionId, enterTo, leaveFor, defaultElement]);
};

const FocusableSection = memo(
  ({
    children,
    sectionId,
    className = '',
    onAnyFocused = noop,
    onNoneFocused = noop,
    enterTo = '',
    leaveForUp,
    leaveForRight,
    leaveForDown,
    leaveForLeft,
    defaultElement,
  }) => {
    const leaveFor = {
      ...(leaveForUp !== null && { up: leaveForUp }),
      ...(leaveForRight !== null && { right: leaveForRight }),
      ...(leaveForDown !== null && { down: leaveForDown }),
      ...(leaveForLeft !== null && { left: leaveForLeft }),
    };

    useSectionInitialization(sectionId, enterTo, leaveFor, defaultElement);
    const onAnyBlurred = useMemo(
      () => (e) => e.detail.nextSectionId !== sectionId && onNoneFocused(e),
      [onNoneFocused, sectionId]
    );

    const focusableValues = useMemo(
      () => ({ sectionId, onAnyFocused, onAnyBlurred }),
      [onAnyBlurred, onAnyFocused, sectionId]
    );

    return (
      <div
        className={className}
        data-test-id={sectionId}
        id={sectionId}
        data-leave-up={leaveForUp}
        data-leave-down={leaveForDown}
      >
        <FocusableSectionContext.Provider value={focusableValues}>
          {children}
        </FocusableSectionContext.Provider>
      </div>
    );
  }
);

FocusableSection.displayName = 'FocusableSection';

FocusableSection.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onAnyFocused: PropTypes.func,
  onNoneFocused: PropTypes.func,
  sectionId: PropTypes.string,
  enterTo: PropTypes.string,
  leaveForUp: PropTypes.string,
  leaveForRight: PropTypes.string,
  leaveForDown: PropTypes.string,
  leaveForLeft: PropTypes.string,
  defaultElement: PropTypes.string,
};

FocusableSection.defaultProps = {
  leaveForUp: null,
  leaveForRight: null,
  leaveForDown: null,
  leaveForLeft: null,
  defaultElement: null,
};

export default FocusableSection;
