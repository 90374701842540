// This file is imported from react-offline-detector library, because of a problem with cache in old browsers
// check https://crackle.atlassian.net/browse/CD-3042 for more information
// file url: https://raw.githubusercontent.com/cwise89/react-detect-offline/master/src/index.js
// The change was made in line 37 in the url from xhr.open('GET', url) to xhr.open("GET", `${url}?ts=${timestamp}`)
// The code was adapted to be a functional component

import { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

const inBrowser = typeof navigator !== 'undefined';

// these browsers don't fully support navigator.onLine, so we need to use a polling backup
const unsupportedUserAgentsPattern =
  /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/;

const ping = ({ url, timeout }) => {
  return new Promise((resolve) => {
    const isOnline = () => resolve(true);
    const isOffline = () => resolve(false);

    const xhr = new XMLHttpRequest();
    const timestamp = Date.now();

    xhr.onerror = isOffline;
    xhr.ontimeout = isOffline;
    xhr.onreadystatechange = () => {
      if (xhr.readyState === xhr.HEADERS_RECEIVED) {
        if (xhr.status) {
          isOnline();
        } else {
          isOffline();
        }
      }
    };

    xhr.open('GET', `${url}?ts=${timestamp}`);
    xhr.timeout = timeout;
    xhr.send();
  });
};

const defaultPollingConfig = {
  enabled: inBrowser && unsupportedUserAgentsPattern.test(navigator.userAgent),
  url: 'https://httpbin.org/get',
  timeout: 5000,
  interval: 5000,
};

function Detector({ polling, render }) {
  // used during force health check in order to only ping health url once
  const [checkingConnection, setCheckingConnection] = useState(false);
  const [online, setOnline] = useState(
    inBrowser && typeof navigator.onLine === 'boolean' ? navigator.onLine : true
  );

  const pollingId = useRef(null);

  const goOnline = useCallback(() => {
    if (!online) {
      setOnline(true);
    }
  }, [online]);

  const goOffline = useCallback(() => {
    if (online) {
      setOnline(false);
    }
  }, [online]);

  const getPollingConfig = useCallback(() => {
    switch (polling) {
      case true:
        return defaultPollingConfig;
      case false:
        return { enabled: false };
      default:
        return { ...defaultPollingConfig, ...polling };
    }
  }, [polling]);

  useEffect(() => {
    const startPolling = () => {
      const { interval } = getPollingConfig();
      pollingId.current = setInterval(() => {
        const { url, timeout } = getPollingConfig();
        ping({ url, timeout }).then((connect) => {
          connect ? goOnline() : goOffline();
        });
      }, interval);
    };

    const stopPolling = () => {
      clearInterval(pollingId.current);
    };

    if (getPollingConfig().enabled) {
      startPolling();
    }

    window.addEventListener('online', goOnline);
    window.addEventListener('offline', goOffline);

    return () => {
      window.removeEventListener('online', goOnline);
      window.removeEventListener('offline', goOffline);
      if (pollingId.current) {
        stopPolling();
      }
    };
  }, [getPollingConfig, goOffline, goOnline, online, polling]);

  useEffect(() => {
    const forceHealthCheck = () => {
      if (!checkingConnection) {
        setCheckingConnection(true);
        const { url, timeout } = getPollingConfig();
        ping({ url, timeout }).then((connect) => {
          connect ? goOnline() : goOffline();
          setCheckingConnection(false);
        });
      }
    };

    window.addEventListener('force-health-check', forceHealthCheck);

    return () => {
      window.removeEventListener('force-health-check', forceHealthCheck);
    };
  }, [getPollingConfig, goOffline, goOnline, checkingConnection]);

  return render({ online, checking: checkingConnection });
}

Detector.propTypes = {
  polling: PropTypes.oneOfType([
    PropTypes.shape({
      url: PropTypes.string,
      interval: PropTypes.number,
      timeout: PropTypes.number,
      enabled: PropTypes.bool,
    }),
    PropTypes.bool,
  ]),
  render: PropTypes.func.isRequired,
};

Detector.defaultProps = {
  polling: true,
};

export default Detector;
