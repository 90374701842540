import { makeRequest } from '../common/base';

/**
 * Get curation info
 *
 * @param {number} pageName The name of the block page
 *
 * @returns {Promise<{}>} A promise containing a JSON data object with curation info
 */
// Curations api
async function fetchBlocks(page) {
  const urlSearchParams = new URLSearchParams({
    page,
  });

  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return makeRequest(
    `/curations?${urlSearchParams.toString()}`,
    requestOptions
  );
}

/**
 * Get content
 *
 * @param {string} playlistUrl The url for getting playlist content
 *
 * @returns {Promise<{}>} A promise containing a JSON data object with playlist content
 */
async function fetchRowContent(playlistUrl) {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return makeRequest(playlistUrl, requestOptions, false, null, '');
}

export { fetchBlocks, fetchRowContent };
