import { Link } from 'react-router-dom';
import { memo, useContext, useEffect, useMemo, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';

// Enums
import ELEMENT_TYPES from '../../enums/element-types';
import SPATIAL_EVENTS from '../../enums/spatial-events';

// Configs
import SPATIAL_NAVIGATION_CONFIG from '../../config/spatial-navigation-config';

// Contexts
import FocusableSectionContext from '../../context/focusable-section-context';

// Utils
import { noop, getScrollParent } from '../../utils/utils';
import { scaleFrom720p } from '../../utils/scale-from-720p';

const useCombinedClassNames = (
  isFocusOnPageLoad,
  isFocusOnSectionEnter,
  additionalClassName
) => {
  const sectionId = useContext(FocusableSectionContext)?.sectionId;
  return useMemo(() => {
    return classnames(sectionId, additionalClassName, {
      [SPATIAL_NAVIGATION_CONFIG.focusableClassName]: !sectionId,
      [SPATIAL_NAVIGATION_CONFIG.initialFocusClassName]: isFocusOnPageLoad,
      [SPATIAL_NAVIGATION_CONFIG.activeClassName]: isFocusOnSectionEnter,
    });
  }, [
    isFocusOnPageLoad,
    isFocusOnSectionEnter,
    additionalClassName,
    sectionId,
  ]);
};

const useEventListener = (elRef, event, callback) => {
  useEffect(() => {
    const el = elRef.current;

    if (!el) return noop;

    el.addEventListener(event, callback);

    return () => el.removeEventListener(event, callback);
  }, [elRef, event, callback]);
};

const useCombinedCallbacks = (callbacks) => {
  return useMemo(() => {
    const combinedCallback = (event) => {
      callbacks.forEach((callback) => callback(event));
    };
    return combinedCallback;
  }, callbacks);
};

const generateSelectionOverrideDataProps = (selectionOverrides) => {
  const r = {};
  const toCheck = ['up', 'down', 'right', 'left'];
  toCheck.forEach((dir) => {
    if (
      selectionOverrides[dir] !== null &&
      selectionOverrides[dir] !== undefined
    ) {
      // See https://github.com/luke-chang/js-spatial-navigation#custom-attributes
      r[`data-sn-${dir}`] = selectionOverrides[dir];
    }
  });
  return r;
};

const defaultSelectionOverrides = {
  up: null,
  down: null,
  right: null,
  left: null,
};

const Focusable = memo(
  ({
    children,
    className,
    elementType = ELEMENT_TYPES.DIV,
    isFocusOnPageLoad = false,
    isFocusOnSectionEnter = false,
    onBlur = noop,
    onFocus = noop,
    onWillFocus = noop,
    selectionOverrides = defaultSelectionOverrides,
    ...props
  }) => {
    const elRef = useRef(null);
    const classNames = useCombinedClassNames(
      isFocusOnPageLoad,
      isFocusOnSectionEnter,
      className
    );
    const sectionContext = useContext(FocusableSectionContext);
    const onAnyFocused = sectionContext?.onAnyFocused || noop;
    const combinedOnFocused = useCombinedCallbacks([onFocus, onAnyFocused]);
    const onAnyBlurred = sectionContext?.onAnyBlurred || noop;
    const combinedOnBlur = useCombinedCallbacks([onBlur, onAnyBlurred]);
    const scrollIntoView = (e) => {
      scrollIntoViewIfNeeded(e.target, {
        scrollMode: 'if-needed',
        block: 'nearest',
      });
      const safeArea = scaleFrom720p(58.667);
      if (
        window.innerHeight - e.target.getBoundingClientRect().bottom <
        safeArea
      ) {
        const scrollableParent = getScrollParent(e.target);
        scrollableParent.scrollTop +=
          safeArea -
          (window.innerHeight - e.target.getBoundingClientRect().bottom);
      }
      if (e.target.getBoundingClientRect().top < safeArea) {
        const scrollableParent = getScrollParent(e.target);
        scrollableParent.scrollTop -=
          safeArea - e.target.getBoundingClientRect().top;
      }
    };
    const combinedOnWillFocus = useCombinedCallbacks([
      scrollIntoView,
      onWillFocus,
    ]);

    useEventListener(elRef, SPATIAL_EVENTS.UNFOCUSED, combinedOnBlur);
    useEventListener(elRef, SPATIAL_EVENTS.FOCUSED, combinedOnFocused);
    useEventListener(elRef, SPATIAL_EVENTS.WILL_FOCUS, combinedOnWillFocus);

    const elementProps = {
      ref: elRef,
      className: classNames,
      tabIndex: '-1',
      ...generateSelectionOverrideDataProps(selectionOverrides),
      ...props,
    };

    switch (elementType) {
      case ELEMENT_TYPES.BUTTON:
        return (
          <button type="button" {...elementProps}>
            {children}
          </button>
        );
      case ELEMENT_TYPES.LINK:
        return <Link {...elementProps}>{children}</Link>;
      case ELEMENT_TYPES.H1:
        return <h1 {...elementProps}>{children}</h1>;
      case ELEMENT_TYPES.H2:
        return <h2 {...elementProps}>{children}</h2>;
      case ELEMENT_TYPES.H3:
        return <h3 {...elementProps}>{children}</h3>;
      case ELEMENT_TYPES.H4:
        return <h4 {...elementProps}>{children}</h4>;
      case ELEMENT_TYPES.H5:
        return <h5 {...elementProps}>{children}</h5>;
      case ELEMENT_TYPES.H6:
        return <h6 {...elementProps}>{children}</h6>;
      case ELEMENT_TYPES.P:
        return <p {...elementProps}>{children}</p>;
      case ELEMENT_TYPES.TR:
        return <tr {...elementProps}>{children}</tr>;
      case ELEMENT_TYPES.LI:
        return <li {...elementProps}>{children}</li>;
      case ELEMENT_TYPES.SPAN:
        return <span {...elementProps}>{children}</span>;
      case ELEMENT_TYPES.DIV:
      default:
        return <div {...elementProps}>{children}</div>;
    }
  }
);

Focusable.displayName = 'Focusable';

Focusable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string.isRequired,
  elementType: PropTypes.oneOf(Object.values(ELEMENT_TYPES)),
  isFocusOnPageLoad: PropTypes.bool,
  isFocusOnSectionEnter: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onWillFocus: PropTypes.func,
  selectionOverrides: PropTypes.shape({
    up: PropTypes.string,
    down: PropTypes.string,
    right: PropTypes.string,
    left: PropTypes.string,
  }),
};

export default Focusable;
