import { getDeviceInformation } from '../platform/index';
import logger from './logger';
import APP_CONFIG from '../config/app-config.json';
import { mParticleGetEventData } from './mparticle';

export default async function mParticleZombieModeStatus(
  zombieModeEnabled,
  appResumed
) {
  const deviceInfo = getDeviceInformation();
  try {
    const defaultEventData = {
      enabled: zombieModeEnabled,
      platform_version:
        `${APP_CONFIG.PLATFORM}_${APP_CONFIG.PLATFORM_ID}` || null,
      platform_launch_state: appResumed ? 'suspended' : 'initial launch',
      platform_device_id: deviceInfo?.duid || null,
      platform_customer_type: '',
      platform_customer_id: '',
    };
    window.mParticle.logEvent(
      'Zombie Mode Status',
      window.mParticle.EventType.Other,
      mParticleGetEventData(defaultEventData)
    );
  } catch (e) {
    logger.error('Error occurred sending mParticleZombieModeStatus', e);
  }
}
