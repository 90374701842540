import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useGlobalContext from './use-global-context';
import PLAY_INITIATION_TYPES from '../enums/play-initiation-types';

/**
 * Hook that exposes hero trailer functionality
 */
export default function useHeroTrailer(watchNowLink) {
  const [showHeroTrailer, setShowHeroTrailer] = useState(false);
  const [showCircleTimer, setShowCircleTimer] = useState(false);
  const [trailerTimeLeft, setTrailerTimeLeft] = useState(5);

  const navigate = useNavigate();
  const setPlayInitiationType = useGlobalContext(
    (state) => state.setPlayInitiationType
  );

  // Used to keep track of 5 second countdown timer
  const countdownTimerRef = useRef(null);

  // Used for a short delay between 5 second
  // timer coundown and sending user to full screen playback
  const timeoutRef = useRef(null);

  const resetHeroTrailer = () => {
    clearInterval(countdownTimerRef.current);
    clearTimeout(timeoutRef.current);
    setTrailerTimeLeft(5);
    setShowCircleTimer(false);
    setShowHeroTrailer(false);
  };

  useEffect(() => {
    // if we should show circle 5 second timer, between coundown setInterval
    if (showCircleTimer) {
      countdownTimerRef.current = setInterval(() => {
        setTrailerTimeLeft((time) => time - 1);
      }, 1000);
    } else {
      // reasons to clear download timer
      clearInterval(countdownTimerRef.current);
      setTrailerTimeLeft(5);
    }

    return () => {
      clearInterval(countdownTimerRef.current);
      setTrailerTimeLeft(5);
    };
  }, [showCircleTimer]);

  useEffect(() => {
    const onTrailerFinished = () => {
      setShowCircleTimer(false);
      setPlayInitiationType(PLAY_INITIATION_TYPES.AUTO_HERO);
      navigate(watchNowLink);
    };

    if (trailerTimeLeft <= 0) {
      clearInterval(countdownTimerRef.current);
      timeoutRef.current = setTimeout(onTrailerFinished, 1300);
    }
  }, [navigate, setPlayInitiationType, trailerTimeLeft, watchNowLink]);

  return {
    showHeroTrailer,
    setShowHeroTrailer,
    resetHeroTrailer,
    setShowCircleTimer,
    showCircleTimer,
    trailerTimeLeft,
  };
}
