/* eslint-disable no-underscore-dangle */
import Content from './content';

class Trailer extends Content {
  constructor({ duration, ...contentParams }) {
    super(contentParams);
    this._duration = duration;
  }

  get duration() {
    return this._duration;
  }

  set duration(duration) {
    this._duration = duration;
  }
}

export default Trailer;
