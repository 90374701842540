import { makeRequest } from '../common/base';

const endpoints = {
  userProfile: (userId) => `/users/${userId}`,
  userPreferences: (userId) => `/users/${userId}/preferences`,
  userWatchlist: (userId) => `/users/${userId}/watchlist/items`,
  userAddToWatchlist: (userId) => `/users/${userId}/watchlist`,
  userRemoveFromWatchlist: (userId, contentId) =>
    `/users/${userId}/watchlist/${contentId}`,
  isInWatchlist: (userId, contentId) =>
    `/users/${userId}/watchlist/${contentId}`,
};

/**
 * Get the user watchlist
 *
 * @param {string} userId The user ID
 *
 * @returns {Promise<{}>} A promise containing a JSON data object with the user's watchlist
 */
const getUserWatchlist = async (userId, pageSize = 24, pageNumber = 1) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  const params = {
    pageSize,
    pageNumber,
  };

  const urlSearchParams = new URLSearchParams({ ...params });

  return makeRequest(
    `${endpoints.userWatchlist(userId)}?${urlSearchParams.toString()}`,
    requestOptions
  );
};

/**
 * Adds a new item into the user watchlist
 *
 * @param {string} userId The user ID
 * @param {contentId} contentId content ID to add to the watchlist
 * *
 * @returns {Promise<any>} A promise containing a JSON data object with the action result
 */
const addToUserWatchlist = async (userId, contentId) => {
  const data = JSON.stringify({ contentId });

  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: data,
  };

  return makeRequest(endpoints.userAddToWatchlist(userId), requestOptions);
};

/**
 * Removes an item from the user watchlist
 *
 * @param {string} userId The user ID
 * @param {contentId} watchlist content ID to remove from the watchlist
 * *
 * @returns {Promise<any>} A promise containing a JSON data object with the with action result
 */
const removeFromUserWatchlist = async (userId, contentId) => {
  const requestOptions = {
    method: 'DELETE',
    redirect: 'follow',
  };

  return makeRequest(
    endpoints.userRemoveFromWatchlist(userId, contentId),
    requestOptions
  );
};

/**
 * is Content in User Watchlist
 *
 * @param {string} userId The user ID
 * @param {contentId} contentId content ID to checked if it's added to watchlist
 * *
 * @returns {Promise<any>} A promise containing a JSON data object with data about content related to user watchlist
 */
const isContentInUserWatchlist = async (userId, contentId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return makeRequest(
    endpoints.isInWatchlist(userId, contentId),
    requestOptions
  );
};

export {
  getUserWatchlist,
  addToUserWatchlist,
  removeFromUserWatchlist,
  isContentInUserWatchlist,
};
