import PropTypes from 'prop-types';
import {
  getFeatureFlagValue,
  isFeatureFlagEnabled,
  FEATURE_FLAGS,
} from '../../utils/feature-flags';
import logger from '../../utils/logger';

// with boolean feature flags, the param value could be omitted
function FeatureFlag({ name, value, children }) {
  if (!(name in FEATURE_FLAGS)) {
    logger.error(`The feature flag does not exist: ${name}`);
    return null;
  }

  const flagValue = getFeatureFlagValue(name);

  if (typeof value !== 'undefined' && flagValue === value) {
    return children;
  }
  if (typeof value === 'undefined' && isFeatureFlagEnabled(name)) {
    return children;
  }
  return null;
}

FeatureFlag.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.element.isRequired,
};

export default FeatureFlag;
