// Description: the SDK must be initialized prior to being used.
//
let SDKConfig = {
  BRAND: '',
  PLATFORM: '',
  ENV: '',
  PLATFORM_ID: '',
  API_URL_PROD: '',
  API_URL_STG: '',
  HEARTBEAT_API_URL_PROD: '',
  HEARTBEAT_API_URL_STG: '',
  logLevel: '',
  getAuthAccessToken: () => void 0,
  getAuthRefreshToken: () => void 0,
  removeAuthAccessToken: () => void 0,
  removeAuthRefreshToken: () => void 0,
  setAuthAccessToken: () => void 0,
  setAuthRefreshToken: () => void 0,
  getSessionHistory: () => void 0,
  locale: 'en-us',
  region: 'us',
  useMocks: false,
};

let SDKFeatureFlags = {
  testLoyaltyApiMock: false,
  testSponsoredContentMocks: false,
  testWatchlistMocks: false,
  testHTTP: false,
  testStgApi: false,
  showWhyItCrackles: false,
};

function setConfig(config) {
  SDKConfig.BRAND = config?.BRAND || SDKConfig.BRAND;
  SDKConfig.PLATFORM = config?.PLATFORM || SDKConfig.PLATFORM;
  SDKConfig.ENV = config?.ENV || SDKConfig.ENV;
  SDKConfig.PLATFORM_ID = config?.PLATFORM_ID || SDKConfig.PLATFORM_ID;
  SDKConfig.API_URL_PROD = config?.API_URL_PROD || SDKConfig.API_URL_PROD;
  SDKConfig.API_URL_STG = config?.API_URL_STG || SDKConfig.API_URL_STG;
  SDKConfig.HEARTBEAT_API_URL_PROD =
    config?.HEARTBEAT_API_URL_PROD || SDKConfig.HEARTBEAT_API_URL_PROD;
  SDKConfig.HEARTBEAT_API_URL_STG =
    config?.HEARTBEAT_API_URL_STG || SDKConfig.HEARTBEAT_API_URL_STG;
  SDKConfig.logLevel = config?.logLevel || 'error';
  SDKConfig.getAuthAccessToken =
    config?.getAuthAccessToken || SDKConfig.getAuthAccessToken;
  SDKConfig.getAuthRefreshToken =
    config?.getAuthRefreshToken || SDKConfig.getAuthRefreshToken;
  SDKConfig.removeAuthAccessToken =
    config?.removeAuthAccessToken || SDKConfig.removeAuthAccessToken;
  SDKConfig.removeAuthRefreshToken =
    config?.removeAuthRefreshToken || SDKConfig.removeAuthRefreshToken;
  SDKConfig.setAuthAccessToken =
    config?.setAuthAccessToken || SDKConfig.setAuthAccessToken;
  SDKConfig.setAuthRefreshToken =
    config?.setAuthRefreshToken || SDKConfig.setAuthRefreshToken;
  SDKConfig.getSessionHistory =
    config?.getSessionHistory || SDKConfig.getSessionHistory;
  SDKConfig.locale = config?.locale || SDKConfig.locale;
  SDKConfig.region = config?.region || SDKConfig.region;
  SDKConfig.useMocks = config?.useMocks || SDKConfig.useMocks;
}

function getConfig() {
  return SDKConfig;
}

function setFeatureFlags(flags) {
  SDKFeatureFlags.testLoyaltyApiMock =
    flags?.testLoyaltyApiMock || SDKFeatureFlags.testLoyaltyApiMock;
  SDKFeatureFlags.testSponsoredContentMocks =
    flags?.testSponsoredContentMocks ||
    SDKFeatureFlags.testSponsoredContentMocks;
  SDKFeatureFlags.testWatchlistMocks =
    flags?.testWatchlistMocks || SDKFeatureFlags.testWatchlistMocks;
  SDKFeatureFlags.testHTTP = flags?.testHTTP || SDKFeatureFlags.testHTTP;
  SDKFeatureFlags.testStgApi = flags?.testStgApi || SDKFeatureFlags.testStgApi;
  SDKFeatureFlags.showWhyItCrackles =
    flags?.showWhyItCrackles || SDKFeatureFlags.showWhyItCrackles;
}

function getFeatureFlags() {
  return SDKFeatureFlags;
}

export { setConfig, getConfig, setFeatureFlags, getFeatureFlags };
