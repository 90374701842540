import logger from '../utils/logger';

export default class CrackleVizbeeDeeplinkHandler {
  //-----------------------------------------------------------
  // Implement this 'onDeeplink' method for your application
  //-----------------------------------------------------------

  /**
   * This method is invoked when a deeplink is received from the mobile device.
   * Specifically, the deeplink is a command to start a specific video.
   *
   * @param { JSON object } videoInfo
   *              - guid (string) video content id
   *              - title (string) video content title
   *              - imgUrl (string) video content imageUrl
   *              - isLive (string) video content type (LIVE or VOD)
   *              - videoUrl (string) video content url
   *              - startTime (number) video start time in milliseconds - optional
   *              - subtitle (string) video content subtitle - optional
   *              - desc (string) video content description - optional
   */
  static onDeeplink = (videoInfo) => {
    const FUNC_LOG_PREFIX = 'CrackleVizbeeDeeplinkHandler::onDeeplink -';
    logger.debug(`${FUNC_LOG_PREFIX}`);
    if (!videoInfo) {
      logger.warn(`${FUNC_LOG_PREFIX} invalid videoInfo, so doing nothing`);
      return;
    }

    if (typeof videoInfo.guid != 'string') {
      logger.warn(
        `${FUNC_LOG_PREFIX} videoInfo.guid must be a string, so doing nothing`
      );
      return;
    }

    const { guid, startTime } = videoInfo;
    const mediaId = guid.split(':').pop();
    if (!mediaId) {
      logger.warn(`${FUNC_LOG_PREFIX} INVALID videoInfo.guid format`);
      return;
    }

    let vizbeeStartTime = 0;
    if (!isNaN(Math.round(startTime / 1000))) {
      vizbeeStartTime = Math.round(startTime / 1000);
    }

    const eventType = 'vizbee-deeplink';
    const deeplinkData = { mediaId, startTime: vizbeeStartTime };
    window.dispatchEvent(
      new CustomEvent('vizbee-command', {
        detail: { type: eventType, data: deeplinkData },
      })
    );
  };
}
