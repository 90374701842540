import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, t } from '@lingui/macro';
import { JsSpatialNavigation } from 'react-js-spatial-navigation';
import FocusableSection from '../spatial-navigation/focusable-section';

// Icons
import { ReactComponent as BlueDiamondIcon } from '../../assets/icons/blue-diamond.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check-mark.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/cross.svg';

// components
import Button from '../button/button';
import LoadingSpinner from '../loading-spinner/loading-spinner';

// Api
import { redeemReward } from '../../crackle-sdk/v1/api/loyalty';

// utils
import logger from '../../utils/logger';
import { getSignedInUser } from '../../utils/signed-in-user';

// Hooks
import useModalHandler from '../../hooks/use-modal-handler';

// config
import BRAND_CONFIG from '../../config/brand-config.json';

// utils
import { isKey } from '../../utils/utils';
import { KEYCODES } from '../../platform/index';
import mParticle from '../../utils/mparticle';

// Enums
import ELEMENT_TYPES from '../../enums/element-types';
import BUTTON_TYPES from '../../enums/button-types';
import MODAL_EXIT_STATUS from '../../enums/modal-exit-status';
import TOAST_TYPES from '../../enums/toast-types';
import MODAL_TYPES from '../../enums/modal-types';
import ERROR_TYPE from '../../config/error-type-config';

// hooks
import useFreePassTimer from '../../hooks/use-free-pass-timer';
import useGlobalContext from '../../hooks/use-global-context';
import useToast from '../../hooks/use-toast';

// Styles
import './rewards-overlay.scss';
import { isFeatureFlagEnabled } from '../../utils/feature-flags';
import REWARD_TYPES from '../../enums/reward-types';

function RewardsOverlay({
  rewardId,
  title,
  type,
  rewardPoints,
  image,
  description,
  updateUserPoints,
}) {
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const { closeModal, showModal } = useModalHandler();
  const { checkFreePassActive } = useFreePassTimer();

  const userId = getSignedInUser()?.userId;
  const userEmail = getSignedInUser()?.email;

  const setLoyaltyFreePassActive = useGlobalContext(
    (state) => state.setLoyaltyFreePassActive
  );

  const REDEEMED_AD_FREE_VISIBLE_TIME = 3000;

  useEffect(() => {
    JsSpatialNavigation.focus('rewards-overlay-redeem-focusable');
  }, []);

  useEffect(() => {
    const onKeyDown = (event) => {
      if (isKey(event.keyCode, KEYCODES.BACK) && !loading) {
        closeModal(MODAL_EXIT_STATUS.REWARDS_REDEEM_CLOSE);
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => window.removeEventListener('keydown', onKeyDown);
  }, [closeModal, loading]);

  const { REWARDS_TERMS_OF_SERVICE } = BRAND_CONFIG;

  const updatePointsAndShowToast = () => {
    updateUserPoints(rewardPoints);
    showToast(
      TOAST_TYPES.REDEEMED_REWARD,
      {
        rewardType: type,
        rewardTitle: title,
      },
      REDEEMED_AD_FREE_VISIBLE_TIME
    );
  };

  const redeem = async () => {
    setLoading(true);
    const response = await redeemReward(userId, rewardId);

    if (response) {
      if (
        type === REWARD_TYPES.FREE_PASS ||
        type === REWARD_TYPES.SPONSORED_FREE_PASS
      ) {
        setLoyaltyFreePassActive(true);
      }

      await checkFreePassActive();
      updatePointsAndShowToast();
      closeModal(MODAL_EXIT_STATUS.SUCCESSFUL_REDEEM);

      mParticle.mParticleLoyaltyRedeemReward({
        rewardType: type,
        rewardPoints,
        userId,
        userEmail,
      });
    }
  };

  const handleRedeemButton = async () => {
    try {
      if (
        isFeatureFlagEnabled('testLoyaltyForceRedeemError') &&
        isFeatureFlagEnabled('testLoyaltyApiMock')
      ) {
        throw new Error();
      }
      redeem();
    } catch (e) {
      showModal(MODAL_TYPES.ERROR, {
        error: ERROR_TYPE.REDEMPTION_ERROR,
        backStatus: MODAL_EXIT_STATUS.TRY_AGAIN_REWARD,
        showGoBackButton: true,
        showTryAgainButton: true,
        goBackStatus: MODAL_EXIT_STATUS.GO_BACK_REDEMPTION_ERROR,
        tryAgainStatus: MODAL_EXIT_STATUS.TRY_AGAIN_REWARD,
      });
      logger.error('Error redeeming reward', e);
    }
  };

  const handleCancelButton = () => {
    closeModal(MODAL_EXIT_STATUS.REWARDS_REDEEM_CLOSE);
  };

  return (
    <div className="rewards-overlay">
      {loading ? (
        <FocusableSection
          sectionId="rewards-overlay-redeem-focusable"
          className="rewards-overlay__container"
          leaveForUp=""
          leaveForRight=""
          leaveForDown=""
          leaveForLeft=""
        >
          <Button className="invisible-button">
            <LoadingSpinner />
          </Button>
        </FocusableSection>
      ) : (
        <>
          <div className="rewards-overlay__container">
            <div className="rewards-overlay__container__icon">
              <BlueDiamondIcon />
            </div>
            <div className="rewards-overlay__container__title">
              <h2>{title}</h2>
            </div>
            <div className="rewards-overlay__container__image">
              <img src={image} alt="rewards-overlay" />
            </div>
            <div className="rewards-overlay__container__description">
              <p>{description}</p>
            </div>
            <div className="rewards-overlay__container__buttons">
              <FocusableSection
                sectionId="rewards-overlay-redeem-focusable"
                className="rewards-overlay__container__buttons"
                leaveForUp=""
                leaveForRight=""
                leaveForDown=""
                leaveForLeft=""
              >
                <Button
                  aria-label={t`REDEEM`}
                  data-test-id="redeem-button"
                  className="rewards-overlay__buttons__redeem"
                  elementType={ELEMENT_TYPES.BUTTON}
                  type={BUTTON_TYPES.TEXT}
                  icon={CheckIcon}
                  onClick={handleRedeemButton}
                >
                  <Trans>REDEEM</Trans>
                </Button>
                <Button
                  aria-label={t`CANCEL`}
                  data-test-id="cancel-button"
                  className="rewards-overlay__container__buttons__cancel"
                  elementType={ELEMENT_TYPES.BUTTON}
                  type={BUTTON_TYPES.TEXT}
                  icon={CrossIcon}
                  onClick={handleCancelButton}
                >
                  <Trans>CANCEL</Trans>
                </Button>
              </FocusableSection>
            </div>
          </div>
          <div className="rewards-overlay__tos">
            <p>
              {t({
                id: `LOYALTY_REWARDS_TOS`,
                values: {
                  tos_url: REWARDS_TERMS_OF_SERVICE,
                },
              })}
            </p>
          </div>
        </>
      )}
    </div>
  );
}

RewardsOverlay.propTypes = {
  rewardId: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  rewardPoints: PropTypes.number,
  image: PropTypes.string,
  description: PropTypes.string,
  updateUserPoints: PropTypes.func,
};

export default RewardsOverlay;
