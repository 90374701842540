import { useQuery } from 'react-query';
import { fetchRecommendations } from '../dataSource/recommendations';
import RecommendationItem from '../entities/recommendation';
import {
  getMetadataByLocale,
  getPosterImageForGrid,
  getRatingsByRegion,
} from '../utils/content';

function useRecommendations({
  contentType,
  mediaId,
  queryOptions = { enabled: false },
}) {
  const recommendations = useQuery(
    [contentType, mediaId],
    () => getRecommendations(contentType, mediaId),
    queryOptions
  );

  return recommendations;
}

export { useRecommendations };

async function getRecommendations(channelType, mediaId) {
  const data = await fetchRecommendations(channelType, mediaId);

  const results = data?.map((item) => {
    const image = getPosterImageForGrid(item?.assets?.images);

    const { title } = getMetadataByLocale(item);

    const rating = getRatingsByRegion(item);

    return new RecommendationItem({
      id: item?.id,
      parentId: item?.parentId,
      type: item?.type,
      title: title,
      image: image,
      description:
        item?.metadata?.[0]?.mediumDescription ||
        item?.metadata?.[0]?.shortDescription ||
        null,
      rating: rating,
    });
  });

  return results;
}

export { getRecommendations };
