import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

// Components
import Button from '../../components/button/button';

// Config
import { BACK_BUTTON_CONFIG } from '../../config/back-button-config';

// Enums
import BUTTON_TYPES from '../../enums/button-types';

// Styles
import './pin-confirm.scss';

function PinConfirm({
  buttonIcon,
  description,
  icon: IconComponent,
  buttonId,
  onClick,
  title,
}) {
  return (
    <div className="pin-confirm">
      <IconComponent className="pin-confirm__icon" />
      <div className="pin-confirm__save-info">
        <h1>{title}</h1>
        <Button
          className="initialFocus pin-confirm__button"
          aria-label={`${title}. ${description}. ${t({
            id: 'PRESS_SELECT_TO',
            values: { action: 'CONTINUE' },
          })}`}
          back={BACK_BUTTON_CONFIG.PIN_SETTINGS}
          type={BUTTON_TYPES.ICONBUTTON}
          icon={buttonIcon}
          id={buttonId}
          onClick={onClick}
          selectionOverrides={{
            right: '',
            up: '',
            left: '',
            down: '',
          }}
        >
          Okay
        </Button>
      </div>
      <p className="pin-confirm__description">{description}</p>
    </div>
  );
}

PinConfirm.propTypes = {
  buttonIcon: PropTypes.elementType,
  description: PropTypes.string,
  icon: PropTypes.elementType,
  buttonId: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

export default PinConfirm;
