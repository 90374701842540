import classnames from 'classnames';
import PropTypes from 'prop-types';

// Styles
import './progress-bar.scss';

function ProgressBar({ progress, type, className }) {
  return (
    <div className={classnames('progress-bar', type, className)}>
      <div style={{ width: `${progress}%` }} />
    </div>
  );
}

ProgressBar.propTypes = {
  className: PropTypes.string,
  progress: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export default ProgressBar;
