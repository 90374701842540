import CrackleApiError from '../crackle-sdk/v1/api/error';
import { setUserPreferences } from '../crackle-sdk/v1/api/user';
import LOCAL_STORAGE from '../enums/local-storage';
import PARENTAL_CONTROLS from '../enums/parental-controls';
import { getLocalStorageItem } from './local-storage';
import logger from './logger';
import { setParentalControlsHistory } from './session-history';
/**
 * These functions fetch the stored values of parental controls values
 * It also provides fallback values in-case we fail to fetch the stored values
 */
const getMode = () => {
  const storedValues = getLocalStorageItem(
    LOCAL_STORAGE.PARENTAL_CONTROLS_HISTORY
  );

  if (storedValues) {
    const parentalControlHistory = JSON.parse(storedValues);
    return parentalControlHistory[PARENTAL_CONTROLS.MODE_KEY];
  }

  return PARENTAL_CONTROLS.GROWN_UPS;
};

const getPIN = () => {
  const storedValues = getLocalStorageItem(
    LOCAL_STORAGE.PARENTAL_CONTROLS_HISTORY
  );

  if (storedValues) {
    const parentalControlHistory = JSON.parse(storedValues);
    return parentalControlHistory[PARENTAL_CONTROLS.PIN_KEY];
  }

  return '';
};

const getLock = () => {
  const storedValues = getLocalStorageItem(
    LOCAL_STORAGE.PARENTAL_CONTROLS_HISTORY
  );

  if (storedValues) {
    const parentalControlHistory = JSON.parse(storedValues);
    return parentalControlHistory[PARENTAL_CONTROLS.LOCK_KEY];
  }

  return false;
};

/**
 * These functions allow us to set the values of parental controls in local storage
 */
const setMode = (mode) => {
  setParentalControlsHistory(PARENTAL_CONTROLS.MODE_KEY, mode);
};

const setPIN = (enteredPin) => {
  setParentalControlsHistory(PARENTAL_CONTROLS.PIN_KEY, enteredPin);
};

const setLock = (boolean) => {
  setParentalControlsHistory(PARENTAL_CONTROLS.LOCK_KEY, boolean);
};

const isPINSet = () => {
  const PIN = getPIN();
  return PIN.length > 0;
};

/**
 * Checks if content is complaint with current parental controls mode
 * @param {string} rating Rating of an asset
 * @returns {boolean}
 */
const checkIfBlocked = (rating) => {
  // Get region to determine compliancy
  const clientRegion = getLocalStorageItem(LOCAL_STORAGE.CLIENT_REGION);

  const parentalMode = getMode();

  let checkRating = rating;

  if (rating && rating.length > 0 && rating[0].rating) {
    // find rating associated to region
    const filteredRating =
      rating &&
      rating.filter((option) => {
        return option.region === clientRegion;
      });
    if (filteredRating && filteredRating.length > 0) {
      checkRating = filteredRating[0].rating;
    }
  }

  if (clientRegion && checkRating) {
    if (parentalMode === PARENTAL_CONTROLS.KIDS) {
      return PARENTAL_CONTROLS.KIDS_ALLOWED_RATINGS.indexOf(checkRating) === -1;
    }

    if (parentalMode === PARENTAL_CONTROLS.TEENS) {
      return (
        PARENTAL_CONTROLS.TEENS_ALLOWED_RATINGS.indexOf(checkRating) === -1
      );
    }
  }

  if (!checkRating || checkRating?.length === 0) {
    if (parentalMode === PARENTAL_CONTROLS.GROWN_UPS) {
      return false;
    }
    return true;
  }

  return false;
};

const getMaxAge = (parentalControlType) => {
  let maxAge;

  if (parentalControlType === PARENTAL_CONTROLS.KIDS) {
    maxAge = 7;
  } else if (parentalControlType === PARENTAL_CONTROLS.TEENS) {
    maxAge = 14;
  } else {
    maxAge = 100;
  }

  return maxAge;
};

const isLockActive = () => {
  return getLock();
};

const savePinToApi = async (userId, parentalControlType, pin) => {
  try {
    const maxAge = getMaxAge(parentalControlType);
    const enabled = parentalControlType !== PARENTAL_CONTROLS.GROWN_UPS;
    await setUserPreferences(userId, enabled, pin, maxAge);
  } catch (e) {
    if (e instanceof CrackleApiError) {
      logger.error(e);
    } else {
      logger.error('Error occurred while setting user preferences', e);
    }
  }
};

const parentalControls = {
  checkIfBlocked,
  isPINSet,
  getMode,
  getPIN,
  getLock,
  setLock,
  setMode,
  setPIN,
  isLockActive,
  savePinToApi,
};

export default parentalControls;
