import { useQuery } from 'react-query';
import { fetchGenres } from '../dataSource/genres';
import Genre from '../entities/genre';

function useGenres({ channelType, queryOptions = {} }) {
  const { data, isLoading, isError, isSuccess } = useQuery(
    ['channelType', channelType],
    () => getGenres(channelType),
    {
      queryOptions,
    }
  );

  if ((!isSuccess || isError) && !isLoading) {
    return {
      data: [],
      isLoading,
    };
  }

  return {
    data,
    isLoading,
  };
}
const getGenres = async (channelType) => {
  const data = await fetchGenres(channelType);

  return data.map((genre) => {
    const generatedGenre = new Genre({
      id: genre.id,
      label: genre.label,
      name: genre.name,
      type: genre.type,
    });

    return {
      id: generatedGenre.id,
      label: generatedGenre.label,
      name: generatedGenre.name,
      type: generatedGenre.type,
    };
  });
};

export { useGenres };
