import { JsSpatialNavigation } from 'react-js-spatial-navigation';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

import { tts, supportCapability, isTv } from '../../platform/index';

// Configs
import SPATIAL_NAVIGATION_CONFIG from '../../config/spatial-navigation-config';

// Hooks
import useBackButton from '../../hooks/use-back-button';
import useCursor from '../../hooks/use-cursor';
import PLATFORM_CAPABILITIES from '../../enums/platform-capabilities';
import SPATIAL_EVENTS from '../../enums/spatial-events';

function SpatialNavigation({ children, className }) {
  // Put global keybound hooks in here so we have access to context

  const navigate = useNavigate();
  const location = useLocation();

  useBackButton(navigate, location);
  useCursor();

  useEffect(() => {
    const onUnfocus = (e) => {
      // force focus onto empty item when nextElement is not defined (and the active element is the body of the html) to avoid TTS say "Document well"
      if (
        !e.detail.nextElement &&
        document.activeElement.tagName.toUpperCase() === 'BODY'
      ) {
        document.getElementById('aria-hidden-el')?.focus();
      }
      // force empty string to avoid TTS say "Document well"
      tts(' ');
    };
    const onFocusElement = (e) => {
      tts(e.target.getAttribute('aria-label'));
    };

    const onNavigationNotPossible = () => {
      tts(t`TTS_NAVIGATION_NOT_POSSIBLE`);
    };

    JsSpatialNavigation.init();
    JsSpatialNavigation.add(SPATIAL_NAVIGATION_CONFIG);

    if (!supportCapability(PLATFORM_CAPABILITIES.ARIA_LABEL) && isTv()) {
      window.addEventListener(SPATIAL_EVENTS.FOCUSED, onFocusElement);
    }

    window.addEventListener(
      SPATIAL_EVENTS.NAVIGATE_FAILED,
      onNavigationNotPossible
    );
    window.addEventListener(SPATIAL_EVENTS.UNFOCUSED, onUnfocus);

    return () => {
      JsSpatialNavigation.uninit();
      window.removeEventListener(
        SPATIAL_EVENTS.NAVIGATE_FAILED,
        onNavigationNotPossible
      );
      window.removeEventListener(SPATIAL_EVENTS.UNFOCUSED, onUnfocus);
      if (!supportCapability(PLATFORM_CAPABILITIES.ARIA_LABEL) && isTv()) {
        window.removeEventListener(SPATIAL_EVENTS.FOCUSED, onFocusElement);
      }
    };
  }, []);

  useEffect(() => {
    const initialFocusElement = document.querySelector(
      `.${SPATIAL_NAVIGATION_CONFIG.initialFocusClassName}`
    );

    JsSpatialNavigation.focus(
      initialFocusElement || `.${SPATIAL_NAVIGATION_CONFIG.focusableClassName}`
    );
  }, [location]);

  return <div className={className}>{children}</div>;
}
SpatialNavigation.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default SpatialNavigation;
