import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';

// Icons
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import { ReactComponent as RemoveIcon } from '../../assets/icons/remove.svg';

// Styles
import './watchlist-toast.scss';

function ToastWatchlist({ isInWatchlist, contentTitle }) {
  return (
    <div className="watchlist-toast">
      {isInWatchlist ? (
        <>
          <AddIcon />
          <p className="highlight">{contentTitle}</p>
          <p>
            <Trans id="WATCHLIST_ADDED" />
          </p>
        </>
      ) : (
        <>
          <RemoveIcon />
          <p className="highlight">{contentTitle}</p>
          <p>
            <Trans id="WATCHLIST_REMOVED" />
          </p>
        </>
      )}
    </div>
  );
}

ToastWatchlist.propTypes = {
  isInWatchlist: PropTypes.bool,
  contentTitle: PropTypes.string,
};

export default ToastWatchlist;
