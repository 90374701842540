/**
 * Check if current season button should be focus on section
 * enter
 *
 * @param {object} location Current page URL
 * @param {number} index Current season button index
 * @param {string} seasonId Season id
 * @return {boolean} Current season button focuts status
 */
function shouldFocusOnSectionEnter(location, index, seasonId) {
  const urlParams = new URLSearchParams(location.search);

  const querySeasonId = urlParams.has('seasonId')
    ? urlParams.get('seasonId')
    : '';

  if (
    (querySeasonId && querySeasonId === seasonId) ||
    (!querySeasonId && index === 0)
  )
    return true;

  return false;
}

/**
 * Get parameter value from query string
 *
 * @param {object} location Current page location
 * @param {string} paramName Parameter name to get value for
 * @return {string} Parameter value or empty string
 */
function getParam(location, paramName) {
  const urlParams = new URLSearchParams(location.search);
  return urlParams.has(paramName) ? urlParams.get(paramName) : '';
}

/**
 * Check if current season button should be focus on page load
 *
 * @param {object} location Current page URL
 * @param {number} index Current season button index
 * @param {string} seasonId Season id
 * @return {boolean} Current season button focuts status
 */
function shouldFocusOnPageLoad(location, index, seasonId) {
  const urlParams = new URLSearchParams(location.search);

  if (urlParams.has('episodeId')) return false;

  return shouldFocusOnSectionEnter(location, index, seasonId);
}

/**
 * Navigate to other season page
 *
 * @param {object} navigate Current history object
 * @param {object} location Current location object
 * @param {string} seasonLink All episodes page link
 * @param {string} seasonId Season id
 * @return {boolean} Current season button active status
 */
function navigateToSeason(navigate, location, seasonLink, seasonId) {
  const fromEpisode = getParam(location, 'fromEpisode');
  const params = new URLSearchParams({
    seasonId,
    ...(fromEpisode && { fromEpisode }),
  });

  return navigate(`${seasonLink}?${params.toString()}`, { replace: true });
}

/**
 * Select(set focus to) an episode
 *
 * @param {object} episode Episode data object
 * @return {string} Episode string
 */
function selectEpisode(navigate, location, seasonId, episodeId) {
  if (location.search.includes(episodeId)) return;

  const params = new URLSearchParams({
    seasonId,
    episodeId,
  });

  navigate(`${location.pathname}?${params.toString()}`, { replace: true });
}

/**
 * Check if current season button should has class name `selected`
 *
 * @param {object} location Location object
 * @param {string} seasonId Current season id
 * @param {number} index Current season button index number
 * @return {string} Episode string
 */
function isCurrentSeason(location, seasonId, index) {
  if (location.search === '' && index === 0) return true;
  if (location.search.includes(seasonId)) return true;
  return false;
}

/**
 * Get the thumbnail image which has the most closest width
 *
 * @param {array} images Thumbnail images array
 * @param {number} width Preferred image with
 * @param {string} aspectRatio Image aspect ratio, `16:9` or `4:3`
 * @param {string} nameContains Text to be found in image name
 * @return {object} Single image object(JSON)
 */
function getThumbnail(images, width, aspectRatio, nameContains = '') {
  return images
    .filter((item) => item.aspectRatio === aspectRatio)
    .filter(
      (item) => nameContains === '' || item.name.indexOf(nameContains) >= 0
    )
    .sort((a, b) => Math.abs(a.width - width) - Math.abs(b.width - width))[0];
}

export {
  shouldFocusOnSectionEnter,
  shouldFocusOnPageLoad,
  navigateToSeason,
  getParam,
  getThumbnail,
  selectEpisode,
  isCurrentSeason,
};
