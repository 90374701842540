import { useInfiniteQuery } from 'react-query';
import { fetchBrowse } from '../dataSource/browse';
import {
  getRatingsByRegion,
  getMetadataByLocale,
  getPosterImageForGrid,
} from '../utils/content';
import ContentResult from '../entities/contentResult';

function useBrowse({
  channelType,
  genre,
  pageNumber = 1,
  sort,
  queryOptions = {},
}) {
  const pageSize = 60;
  const { data, isLoading, isError, isSuccess, hasNextPage, fetchNextPage } =
    useInfiniteQuery(
      ['browse', channelType, genre, sort],
      ({ pageParam = pageNumber }) =>
        prepareBrowse(channelType, pageParam, genre, sort, pageSize),
      {
        getNextPageParam: (lastPage) => {
          let nextPage = null;
          if (lastPage.pagination.pageNumber < lastPage.pagination.totalPages) {
            nextPage = lastPage.pagination.pageNumber + 1;
          }

          return nextPage;
        },
        getPreviousPageParam: (firstPage) => {
          let previousPage = null;
          if (firstPage.pagination.pageNumber > 1) {
            previousPage = firstPage.pagination.pageNumber - 1;
          }

          return previousPage;
        },
        ...queryOptions,
      }
    );

  let browseDTO = [];

  if (!isLoading && !isError) {
    browseDTO = data?.pages.map((page) => {
      return {
        items: page?.items.map((item) => {
          return {
            id: item.id,
            image: item.image,
            title: item.title,
            rating: {
              name: item.rating?.name || null,
              age: item.rating?.age || null,
            },
          };
        }),
        pagination: page.pagination,
      };
    });
  }

  return {
    data: browseDTO,
    isLoading,
    isError,
    isSuccess,
    hasNextPage,
    fetchNextPage,
  };
}

const prepareBrowse = async (
  channelType,
  pageNumber,
  genre,
  sort,
  pageSize = 60
) => {
  const data = await fetchBrowse(
    channelType,
    pageNumber,
    genre,
    sort,
    pageSize
  );

  const result = data?.items?.map((item) => {
    const { title } = getMetadataByLocale(item);

    const browseItem = new ContentResult({
      id: item.id,
      title,
      rating: getRatingsByRegion(item),
      images: item.assets.images,
    });

    browseItem.image = getPosterImageForGrid(browseItem.images);
    return browseItem;
  });

  return { items: result, pagination: data?.pagination };
};

export { useBrowse };
