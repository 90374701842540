/* eslint-disable no-underscore-dangle */

class VideoAsset {
  constructor({ id, type, width, height, aspectRatio, duration }) {
    this._id = id;
    this._type = type;
    this._width = width;
    this._height = height;
    this._aspectRatio = aspectRatio;
    this._duration = duration;
  }

  get id() {
    return this._id;
  }

  set id(id) {
    this._id = id;
  }

  get type() {
    return this._type;
  }

  set type(type) {
    this._type = type;
  }

  get width() {
    return this._width;
  }

  set width(width) {
    this._width = width;
  }

  get height() {
    return this._height;
  }

  set height(height) {
    this._height = height;
  }

  get aspectRatio() {
    return this._aspectRatio;
  }

  set aspectRatio(aspectRatio) {
    this._aspectRatio = aspectRatio;
  }

  get duration() {
    return this._duration;
  }

  set duration(duration) {
    this._duration = duration;
  }

  get url() {
    return this._url;
  }

  set url(url) {
    this._url = url;
  }
}

export default VideoAsset;
