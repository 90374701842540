import { getConfig, getFeatureFlags } from '../../configuration';
import { SAMPLE_NON_DRM_DASH_URL } from '../common/vars';

const CONFIG = getConfig();

const flags = getFeatureFlags();

export default function getStreamForCurrent(streams) {
  switch (CONFIG.PLATFORM) {
    case 'lg':
      return getStreamSourceLg(streams);
    case 'playstation':
      return getStreamSourcePlaystation(streams);
    case 'samsung':
      return getStreamSourceSamsung(streams);
    case 'vidaa':
      return getStreamSourceVidaa(streams);
    case 'vizio':
      return getStreamSourceVizio(streams);
    case 'xbox':
      return getStreamSourceXbox(streams);
    default:
      break;
  }
}

function getStreamSourceXbox(streams) {
  const dashUrl =
    streams?.dash_playready?.url || streams?.['dash-playready']?.url || null;

  const drm = {
    playready: {
      LA_URL:
        streams?.dash_playready?.drm?.key_url ||
        streams?.['dash-playready']?.drm?.keyUrl ||
        streams?.dash_playready?.drm?.keyUrl ||
        null,
    },
  };
  const sourceStreamType =
    streams?.dash_playready?.url || streams?.['dash-playready']?.url
      ? 'dash_playready'
      : null;

  return {
    dash: flags['testNoDRMSampleVideo'] ? SAMPLE_NON_DRM_DASH_URL : dashUrl,
    sourceStreamType,
    drm,
  };
}

function getStreamSourceVizio(streams) {
  const dashUrl =
    streams?.dash_widevine?.url || streams?.['dash-widevine']?.url || null;
  const drm = {
    widevine: {
      LA_URL:
        streams?.dash_widevine?.drm?.keyUrl ||
        streams?.['dash-widevine']?.drm?.keyUrl ||
        null,
    },
  };
  const sourceStreamType =
    streams?.dash_widevine?.url || streams?.['dash-widevine']?.url
      ? 'dash_widevine'
      : null;

  return {
    dash: flags['testNoDRMSampleVideo'] ? SAMPLE_NON_DRM_DASH_URL : dashUrl,
    sourceStreamType,
    drm,
  };
}

function getStreamSourceVidaa(streams) {
  const dashUrl =
    streams?.dash_widevine?.url || streams?.['dash-widevine']?.url || null;
  const drm = {
    widevine: {
      LA_URL:
        streams?.dash_widevine?.drm?.keyUrl ||
        streams?.['dash-widevine']?.drm?.keyUrl ||
        null,
    },
  };
  const sourceStreamType =
    streams?.dash_widevine?.url || streams?.['dash-widevine']?.url
      ? 'dash_widevine'
      : null;

  return {
    dash: flags['testNoDRMSampleVideo'] ? SAMPLE_NON_DRM_DASH_URL : dashUrl,
    sourceStreamType,
    drm,
  };
}

function getStreamSourceSamsung(streams) {
  const dashUrl =
    streams?.dash_widevine?.url || streams?.['dash-widevine']?.url || null;
  const drm = {
    widevine: {
      LA_URL:
        streams?.dash_widevine?.drm?.keyUrl ||
        streams?.['dash-widevine']?.drm?.keyUrl ||
        null,
    },
  };
  const sourceStreamType =
    streams?.dash_widevine?.url || streams?.['dash-widevine']?.url
      ? 'dash_widevine'
      : null;

  return {
    dash: flags['testNoDRMSampleVideo'] ? SAMPLE_NON_DRM_DASH_URL : dashUrl,
    sourceStreamType,
    drm,
  };
}

function getStreamSourcePlaystation(streams) {
  const dashUrl =
    streams?.dash_playready?.url || streams?.['dash-playready']?.url || null;

  const drm = {
    playready: {
      utf8message: true,
      plaintextChallenge: true,
      mediaKeySystemConfig: {
        sessionTypes: ['temporary'],
      },
      LA_URL:
        streams?.dash_playready?.drm?.key_url ||
        streams?.['dash-playready']?.drm?.keyUrl ||
        streams?.dash_playready?.drm?.keyUrl ||
        null,
    },
  };
  const sourceStreamType =
    streams?.dash_playready?.url || streams?.['dash-playready']?.url
      ? 'dash_playready'
      : null;

  return {
    dash: flags['testNoDRMSampleVideo'] ? SAMPLE_NON_DRM_DASH_URL : dashUrl,
    sourceStreamType,
    drm,
  };
}

function getStreamSourceLg(streams) {
  const dashUrl =
    streams?.dash_widevine?.url || streams?.['dash-widevine']?.url || null;
  const drm = {
    widevine: {
      LA_URL:
        streams?.dash_widevine?.drm?.keyUrl ||
        streams?.['dash-widevine']?.drm?.keyUrl ||
        null,
    },
  };
  const sourceStreamType =
    streams?.dash_widevine?.url || streams?.['dash-widevine']?.url
      ? 'dash_widevine'
      : null;

  return {
    dash: flags['testNoDRMSampleVideo'] ? SAMPLE_NON_DRM_DASH_URL : dashUrl,
    sourceStreamType,
    drm,
  };
}
