const Rows = {
  standard: {
    id: 7,
    name: 'standard',
    visibleElements: 6.7,
  },
  details: {
    id: 6,
    name: 'details',
    visibleElements: 2.21,
  },
  originals: {
    id: 5,
    name: 'originals',
    visibleElements: 3.33,
  },
};

export default Rows;
