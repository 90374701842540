import { useQuery } from 'react-query';
import { fetchBlocks } from '../dataSource/curations';
import Block from '../entities/block';

function useCurations({ page, queryOptions = {} }) {
  const { data, isLoading, isError, isSuccess } = useQuery(
    ['page', page],
    () => getCurations(page),
    {
      queryOptions,
    }
  );

  return {
    data: (!isSuccess || isError) && !isLoading ? [] : data,
    isLoading,
    isError,
    isSuccess,
  };
}

async function getCurations(page) {
  const data = await fetchBlocks(page);

  return data?.page?.blocks?.map((block) => {
    const generatedBlock = new Block({
      id: block.id,
      title: block.title,
      description: block.description,
      guid: block.guid,
      images: block.assets?.images,
      url: block.source?.url,
      type: block.type,
      displayStyle: block.displayStyle?.name,
    });

    return {
      id: generatedBlock.id,
      title: generatedBlock.title,
      guid: generatedBlock.guid,
      url: generatedBlock.url,
      displayStyle: generatedBlock.displayStyle,
    };
  });
}

export { useCurations };
