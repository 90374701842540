const ELEMENT_TYPES = {
  DIV: 'div',
  LINK: 'link',
  BUTTON: 'button',
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
  TR: 'tr',
  LI: 'li',
  P: 'p',
  SPAN: 'span',
};

export default ELEMENT_TYPES;
