import { useCallback, useMemo } from 'react';
import useGlobalContext from './use-global-context';

function useToast() {
  const { toast, setToast } = useGlobalContext((state) => ({
    toast: state.toast,
    setToast: state.setToast,
  }));

  const VISIBLE_TIME = 5000;

  const showToast = useCallback(
    (type, props, visibleTime = VISIBLE_TIME) => {
      setToast({ type, props, visibleTime });
    },
    [setToast]
  );

  const toastValues = useMemo(() => ({ toast, showToast }), [showToast, toast]);

  return toastValues;
}

export default useToast;
