import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Trans, t } from '@lingui/macro';

// components
import LoadingSpinner from '../loading-spinner/loading-spinner';

// context
import useGlobalContext from '../../hooks/use-global-context';

// hooks
import useFreePassTimer from '../../hooks/use-free-pass-timer';

// Style
import './home-free-pass.scss';

function HomeFreePass({ className }) {
  const freePassActive = useGlobalContext((state) => state.adFreePass.active);
  const endTime = useGlobalContext((state) => state.adFreePass.endTime);

  const { roundedTime, showTile, shortTimeUnit } = useFreePassTimer();

  const generateTile = () => (
    <div
      className={classnames('home-free-pass', className)}
      data-test-id="home-free-pass"
    >
      <span data-test-id="free-pass-tile-text">
        <span className="home-free-pass__bold-text">{t`LOYALTY_HOME_FREE_PASS_ACTIVE`}</span>{' '}
        <Trans
          id="LOYALTY_HOME_FREE_PASS_LEFT"
          values={{ timeLeft: `${roundedTime}${shortTimeUnit}` }}
        />
      </span>
    </div>
  );

  if ((freePassActive && !endTime) || !freePassActive) {
    return null;
  }

  return showTile ? (
    generateTile()
  ) : (
    <div className={classnames('loading-placeholder', className)}>
      <LoadingSpinner />
    </div>
  );
}

HomeFreePass.propTypes = {
  className: PropTypes.string,
};

HomeFreePass.defaultProps = {
  className: '',
};

export default HomeFreePass;
