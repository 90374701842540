const BROWSE = {
  ALL: 'all',
  ALL_TV_SHOWS: 'All TV Shows',
  ALL_MOVIES: 'All Movies',
  HOME: 'Home',
  MOVIES: 'Movies',
  MOVIES_PARAM: 'Movies',
  TV_SHOWS: 'TV Shows',
  SHOWS_PARAM: 'Shows',
};

export default BROWSE;
