const FOCUS_OPTIONS = {
  ALL_EPISODES: 'FOCUS_ALL_EPISODES',
  BASIC_SETTINGS: 'FOCUS_BASIC_SETTINGS',
  BROWSE_FIRST_ITEM: 'FOCUS_BROWSE_FIRST_ITEM',
  BROWSE_SORT: 'FOCUS_BROWSE_SORT',
  CONTENT_DETAILS: 'FOCUS_CONTENT_DETAILS',
  CONTENT_DETAILS_BACK: 'FOCUS_CONTENT_DETAILS_BACK',
  CONTENT_ROW_FIRST_ITEM: 'FOCUS_CONTENT_ROW_FIRST_ITEM',
  CLOSE_WATCHLIST_MODAL: 'CLOSE_WATCHLIST_MODAL',
  EPISODE: 'EPISODE',
  GRID_ROW_FIRST_ITEM: 'GRID_ROW_FIRST_ITEM',
  HERO_PLAY: 'FOCUS_HERO_PLAY',
  LANDING_SCREEN: 'FOCUS_LANDING_SCREEN',
  MENU_HOME: 'FOCUS_MENU_HOME',
  MENU_MOVIES: 'FOCUS_MENU_MOVIES',
  MENU_WATCHLIST: 'FOCUS_MENU_WATCHLIST',
  MENU_SHOWS: 'FOCUS_MENU_SHOWS',
  MENU_SEARCH: 'FOCUS_MENU_SEARCH',
  MENU_SETTINGS: 'FOCUS_MENU_SETTINGS',
  MORE_INFO_SCREEN: 'MORE_INFO_SCREEN',
  NUMBER_STRIP: 'FOCUS_NUMBER_STRIP',
  LETTER_STRIP_FIRST_BUTTON: 'FOCUS_LETTER_STRIP_FIRST_BUTTON',
  NUMERIC_STRIP_FIRST_BUTTON: 'FOCUS_NUMERIC_STRIP_FIRST_BUTTON',
  LETTER_STRIP_SWITCH: 'FOCUS_LETTER_STRIP_SWITCH',
  SEARCH_NUMERIC_KEYBOARD: 'FOCUS_SEARCH_NUMERIC_KEYBOARD',
  SEARCH_LETTER_KEYBOARD: 'FOCUS_SEARCH_LETTER_KEYBOARD',
  SIGN_IN_REWARDS: 'FOCUS_SIGN_IN_REWARDS',
  SETTINGS_TILE: 'FOCUS_SETTINGS_TILE',
  SUBMENU_ACTIVE_GENRE: 'FOCUS_SUBMENU_ACTIVE_GENRE',
  VIDEO_PLAYER: 'FOCUS_VIDEO_PLAYER',
  WATCHLIST_GRID_FIRST_ITEM: 'WATCHLIST_GRID_FIRST_ITEM',
  CONTENT_DETAILS_YMAL: 'CONTENT_DETAILS_YMAL',
};

const BACK_BUTTON_CONFIG = {
  ALL_EPISODES: FOCUS_OPTIONS.ALL_EPISODES,
  BROWSE_FIRST_ITEM: FOCUS_OPTIONS.SUBMENU_ACTIVE_GENRE,
  BROWSE_SORT_MODAL: FOCUS_OPTIONS.BROWSE_SORT,
  CONTENT_DETAILS: FOCUS_OPTIONS.CONTENT_DETAILS,
  CONTENT_ROW_FIRST_ITEM: FOCUS_OPTIONS.HERO_PLAY,
  CONTENT_ROW_NOT_FIRST_ITEM: FOCUS_OPTIONS.CONTENT_ROW_FIRST_ITEM,
  CLOSE_WATCHLIST_MODAL: FOCUS_OPTIONS.CLOSE_WATCHLIST_MODAL,
  DETAILS_PAGE_BUTTON: FOCUS_OPTIONS.CONTENT_DETAILS_BACK,
  EPISODE: FOCUS_OPTIONS.EPISODE,
  GRID_FIRST_ITEM: FOCUS_OPTIONS.BROWSE_FIRST_ITEM,
  GRID_NOT_FIRST_ITEM: FOCUS_OPTIONS.GRID_ROW_FIRST_ITEM,
  HERO_MORE_INFO: FOCUS_OPTIONS.HERO_PLAY,
  MENU_CHANNELS: FOCUS_OPTIONS.MENU_HOME,
  MENU_HOME: FOCUS_OPTIONS.HERO_PLAY,
  MENU_MOVIES: FOCUS_OPTIONS.MENU_HOME,
  MENU_NEW_LEAVING_SOON: FOCUS_OPTIONS.MENU_HOME,
  MENU_SEARCH: FOCUS_OPTIONS.MENU_SEARCH,
  MENU_SETTINGS: FOCUS_OPTIONS.MENU_HOME,
  MENU_SHOWS: FOCUS_OPTIONS.MENU_HOME,
  MENU_WATCHLIST: FOCUS_OPTIONS.MENU_WATCHLIST,
  MENU_ITEM: FOCUS_OPTIONS.MENU_HOME,
  MENU_REWARDS: FOCUS_OPTIONS.MENU_HOME,
  MORE_INFO_SCREEN: FOCUS_OPTIONS.MORE_INFO_SCREEN,
  SETTINGS_SUBMENU: FOCUS_OPTIONS.MENU_SETTINGS,
  PIN_SETTINGS: FOCUS_OPTIONS.SETTINGS_TILE,
  PIN_VERIFICATION: FOCUS_OPTIONS.NUMBER_STRIP,
  SEARCH_LETTER_STRIP_FIRST_BUTTON: FOCUS_OPTIONS.LETTER_STRIP_FIRST_BUTTON,
  SEARCH_NUMERIC_STRIP_FIRST_BUTTON: FOCUS_OPTIONS.NUMERIC_STRIP_FIRST_BUTTON,
  SEARCH_LETTER_STRIP_SWITCH: FOCUS_OPTIONS.LETTER_STRIP_SWITCH,
  SEARCH_RESULTS_ITEM_LETTER_STRIP: FOCUS_OPTIONS.LETTER_STRIP_FIRST_BUTTON,
  SEARCH_RESULTS_ITEM_NUMERIC_STRIP: FOCUS_OPTIONS.NUMERIC_STRIP_FIRST_BUTTON,
  SEARCH_NUMERIC_KEYBOARD: FOCUS_OPTIONS.SEARCH_NUMERIC_KEYBOARD,
  SEARCH_LETTER_KEYBOARD: FOCUS_OPTIONS.SEARCH_LETTER_KEYBOARD,
  SETTINGS_PAGE: FOCUS_OPTIONS.SETTINGS_TILE,
  SIGN_IN_REWARDS: FOCUS_OPTIONS.SIGN_IN_REWARDS,
  SIGN_IN_SIGN_UP: FOCUS_OPTIONS.LANDING_SCREEN,
  SUBMENU_MOVIES: FOCUS_OPTIONS.MENU_MOVIES,
  SUBMENU_SHOWS: FOCUS_OPTIONS.MENU_SHOWS,
  PLAYER_CONTROLS: FOCUS_OPTIONS.VIDEO_PLAYER,
  PARENTAL_CONTROLS: FOCUS_OPTIONS.BASIC_SETTINGS,
  WATCHLIST_GRID_FIRST_ITEM: FOCUS_OPTIONS.WATCHLIST_GRID_FIRST_ITEM,
  YMAL: FOCUS_OPTIONS.CONTENT_DETAILS_YMAL,
};

export { FOCUS_OPTIONS, BACK_BUTTON_CONFIG };
