import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Hooks
import useGlobalContext from '../../hooks/use-global-context';

// Utils
import { getAdParams, supportCapability } from '../../platform/index';
import logger from '../../utils/logger';

// enums
import PLATFORM_CAPABILITIES from '../../enums/platform-capabilities';
import SPONSORED_AD_SIZE from '../../enums/sponsored-ad-size';

// Styles
import './sponsored-ads.scss';

// Enums
import PRIVACY_OPTIONS from '../../enums/ccpa';
import { getGPPString, getPrivacyOptions } from '../../utils/privacy';
import { getSignedInUser } from '../../utils/signed-in-user';
import { isValidUrl, replaceUrlParams } from '../../utils/url';

function SponsoredAdView({
  slotParams,
  className,
  size = SPONSORED_AD_SIZE.MEDIUM,
}) {
  const [loading, setLoading] = useState(true);
  const [htmlDoc, setHtmlDoc] = useState(null);
  // ref for platforms that do not support iframe srcdoc attribute
  const iframeRef = useRef(null);
  const adFreePassActive = useGlobalContext((state) => state.adFreePass.active);
  const sponsoredAdsUrl = useGlobalContext((state) => state.sponsoredAdsUrl);
  const doNotSell = useGlobalContext(
    (state) => state.userPrivacySettings.doNotSell
  );
  const doNotShare = useGlobalContext(
    (state) => state.userPrivacySettings.doNotShare
  );

  const gppString = getGPPString({ doNotSell, doNotShare });

  const urlParams = { ...slotParams, gpp: gppString };

  const supportIframeSrcDoc = supportCapability(
    PLATFORM_CAPABILITIES.IFRAME_SRC_DOC
  );

  const ccpaOption = doNotSell
    ? PRIVACY_OPTIONS.NP_OO_NOOT
    : PRIVACY_OPTIONS.NP_NOOT;

  const newUrl =
    sponsoredAdsUrl && replaceUrlParams(sponsoredAdsUrl, urlParams);

  const adUrl = newUrl || sponsoredAdsUrl;

  function validSponsoredAdUrl(url) {
    return isValidUrl(url);
  }

  function getResizeStyle() {
    return `<style type="text/css">
      body {zoom: ${window.innerWidth / 3840}; overflow: hidden;} 
      </style>`;
  }

  const userId = getSignedInUser()?.userId;

  useEffect(() => {
    const getIframe = async (adParams) => {
      try {
        const privacy = getPrivacyOptions(
          doNotSell,
          doNotShare,
          adParams,
          userId
        );

        const gppString = getGPPString(privacy);

        const urlParams = { ...slotParams, gpp: gppString };

        const newUrl =
          sponsoredAdsUrl && replaceUrlParams(sponsoredAdsUrl, urlParams);

        const adUrl = newUrl || sponsoredAdsUrl;

        if (!validSponsoredAdUrl(adUrl)) {
          logger.debug('Sponsored Ad Url is not valid', adUrl);
          return;
        }

        const response = await fetch(
          `${adUrl}&_fw_us_privacy=${adParams.ccpa || ccpaOption}`
        );

        let srcHtml = await response.text();

        srcHtml = srcHtml.replace('</body>', `${getResizeStyle()}</body>`);

        // For tts to read Loading instead of "Advertisement" This does not read all the times
        // but to avoid the times it does read, we are replacing it with Loading
        srcHtml = srcHtml.replace(
          '<title>Advertisement</title>',
          `<title>Loading.</title>`
        );

        if (supportIframeSrcDoc) {
          setHtmlDoc(srcHtml);
        } else {
          const sponsorDoc = iframeRef.current.contentWindow.document;

          sponsorDoc.open();
          sponsorDoc.write(srcHtml);
          sponsorDoc.close();
        }

        setLoading(false);
      } catch (err) {
        logger.debug('Sponsored Ad Not Loaded', err);
      }
    };

    // just fetch the ad url if it was not fetched yet
    if (loading) {
      validSponsoredAdUrl(adUrl) && getAdParams(getIframe);
    }
  }, [
    ccpaOption,
    doNotSell,
    doNotShare,
    loading,
    slotParams,
    sponsoredAdsUrl,
    supportIframeSrcDoc,
    userId,
  ]);

  return (
    !adFreePassActive &&
    validSponsoredAdUrl(sponsoredAdsUrl) && (
      <div
        className={classnames(
          'sponsored-ad',
          {
            mediumPlaceholder: loading && size === SPONSORED_AD_SIZE.MEDIUM,
            largePlaceholder: loading && size === SPONSORED_AD_SIZE.LARGE,
          },
          className
        )}
      >
        {adUrl && (!supportIframeSrcDoc || htmlDoc) && (
          <iframe
            ref={!supportIframeSrcDoc ? iframeRef : null}
            srcDoc={supportIframeSrcDoc ? htmlDoc : null}
            title={`ad-${slotParams.slid}`}
            onError={() => {
              if (supportIframeSrcDoc) {
                setHtmlDoc(null);
              } else {
                const sponsorDoc = iframeRef.current.contentWindow.document;

                sponsorDoc.open();
                sponsorDoc.write('');
                sponsorDoc.close();
              }
            }}
            id={slotParams.slid}
            className={classnames('sponsored-ad__frame', {
              'sponsored-ad__frame__medium': size === SPONSORED_AD_SIZE.MEDIUM,
              'sponsored-ad__frame__large': size === SPONSORED_AD_SIZE.LARGE,
            })}
          />
        )}
      </div>
    )
  );
}

SponsoredAdView.propTypes = {
  slotParams: PropTypes.shape({
    caid: PropTypes.string,
    row: PropTypes.string,
    series: PropTypes.string,
    type: PropTypes.string,
    hero: PropTypes.string,
    slid: PropTypes.string.isRequired,
  }),
  className: PropTypes.string,
  size: PropTypes.string,
};

export default SponsoredAdView;
