import { useEffect, useCallback } from 'react';
import { useNavigate, generatePath } from 'react-router-dom';
import logger from '../utils/logger';
import ROUTES from '../enums/routes';

function CrackleVizbeeDeeplink() {
  const navigate = useNavigate();

  const handleVizbeeDeeplink = useCallback(
    (deeplinkData) => {
      const FUNC_LOG_PREFIX = 'CrackleVizbeeDeeplink::handleVizbeeDeeplink -';
      if (!deeplinkData || !deeplinkData.mediaId) {
        logger.warn(`${FUNC_LOG_PREFIX} INVALID deeplinkData, doing nothing`);
        return;
      }
      logger.debug(`${FUNC_LOG_PREFIX} mediaId=${deeplinkData.mediaId}`);
      const { mediaId, startTime } = deeplinkData;

      // home page
      const homePath = generatePath(ROUTES.HOME);
      navigate(`${homePath}`, { urlPath: `${homePath}` });

      // details page
      const detailsPath = generatePath(ROUTES.DETAILS, { mediaId });
      navigate(`${detailsPath}`, { urlPath: `${detailsPath}` });

      // watch page
      let watchPath = `${generatePath(ROUTES.WATCH, {
        mediaId,
      })}?deeplink=true`;
      if (startTime && startTime > 0) {
        watchPath = `${watchPath}&startTime=${startTime}`;
      }
      navigate(`${watchPath}`, { urlPath: `${watchPath}` });
      logger.debug(
        `${FUNC_LOG_PREFIX} pushed watchPath=${watchPath} to history`
      );
    },
    [navigate]
  );

  useEffect(() => {
    const onVizbeeDeeplink = (e) => {
      const eventDetails = e && e.detail;
      if (
        eventDetails &&
        eventDetails.type === 'vizbee-deeplink' &&
        eventDetails.data
      ) {
        handleVizbeeDeeplink(eventDetails.data);
      }
    };

    window.addEventListener('vizbee-command', onVizbeeDeeplink);

    return () => {
      window.removeEventListener('vizbee-command', onVizbeeDeeplink);
    };
  }, [handleVizbeeDeeplink]);

  return null;
}

export default CrackleVizbeeDeeplink;
