// Config
import logger from '../../utils/logger';
import UserWatchlist from '../entities/userWatchlist';
import {
  addToUserWatchlist,
  removeFromUserWatchlist,
  isContentInUserWatchlist,
  getUserWatchlist,
} from '../dataSource/watchlist';
import CONTENT_TYPES from '../enums/contentTypes';
import { getPosterImageForGrid, getDurationSeconds } from '../utils/content';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

function useCheckContentInWatchlist({ userId, contentId }) {
  const queryClient = useQueryClient();

  const { data, isLoading: isLoading } = useQuery({
    queryKey: ['watchlist', userId, contentId],
    queryFn: () => isContentInUserWatchlist(userId, contentId),
    enabled: Boolean(userId && contentId),
    retry: false,
    onError: (error) => {
      // if the error is NOT about content not found log it
      if (error?.code !== 101) {
        logger('There was an error checking content: ', error);
      }

      queryClient.setQueryData(['watchlist', userId, contentId], false);

      return false;
    },
  });

  return {
    isInWatchlist: Boolean(data && data?.contentId),
    isLoading,
  };
}

function useUserWatchlist({
  userId,
  pageNumber = 1,
  pageSize = 24,
  queryOptions = { enabled: false },
}) {
  const userList = useInfiniteQuery(
    ['watchlist', userId],
    ({ pageParam = pageNumber }) =>
      fetchUserWatchlist(userId, pageParam, pageSize),
    {
      getNextPageParam: (lastPage) => {
        let nextPage = null;
        if (lastPage.pagination.pageNumber < lastPage.pagination.totalPages) {
          nextPage = lastPage.pagination.pageNumber + 1;
        }

        return nextPage;
      },
      getPreviousPageParam: (firstPage) => {
        let previousPage = null;
        if (firstPage.pagination.pageNumber > 1) {
          previousPage = firstPage.pagination.pageNumber - 1;
        }

        return previousPage;
      },
      ...queryOptions,
    }
  );

  const {
    data,
    isLoading,
    isError,
    error,
    isSuccess,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    fetchPreviousPage,
    isFetchingPreviousPage,
    refetch,
  } = userList;

  let watchlistResultsDTO = {};

  if (!isLoading && !isError) {
    watchlistResultsDTO = {
      pageData: data?.pages.map((page) => {
        return {
          [`page${page?.pagination?.pageNumber}`]: page?.items,
          pageNumber: page?.pagination?.pageNumber,
        };
      }),
      totalPages: data?.pages?.[0].pagination?.totalPages,
    };
  }

  return {
    data: watchlistResultsDTO,
    isLoading,
    isError,
    error,
    isSuccess,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    fetchPreviousPage,
    isFetchingPreviousPage,
    refetch,
  };
}

function useWatchlistActions(
  userId,
  contentId,
  onContentAdded,
  onContentRemoved
) {
  const queryClient = useQueryClient();

  const { isInWatchlist, isLoading: checkingInWatchlist } =
    useCheckContentInWatchlist({
      userId,
      contentId,
    });
  const { isLoading: isLoadingAddingContent, mutate: addContent } = useMutation(
    {
      mutationFn: ({ userId, contentId }) =>
        addToUserWatchlist(userId, contentId),
      onSuccess: () => {
        onContentAdded && onContentAdded();

        // this resets cache for isContentInUserWatchlist and List
        // which triggers new calls to update values of list
        queryClient.resetQueries({
          queryKey: ['watchlist', userId],
        });
      },
    }
  );

  const { isLoading: isLoadingRemovingContent, mutate: removeContent } =
    useMutation({
      mutationFn: ({ userId, contentId }) =>
        removeFromUserWatchlist(userId, contentId),
      onSuccess: () => {
        onContentRemoved && onContentRemoved();

        // this resets cache for isContentInUserWatchlist and List
        // which triggers new calls to update values of list
        queryClient.removeQueries(['watchlist', userId], {
          exact: true,
        });
        queryClient.resetQueries({
          queryKey: ['watchlist', userId],
        });
      },
    });

  function addToWatchlist() {
    addContent({ userId, contentId });
  }

  function removeFromWatchlist() {
    removeContent({ userId, contentId });
  }

  return {
    isLoading:
      isLoadingRemovingContent || isLoadingAddingContent || checkingInWatchlist,
    addToWatchlist,
    removeFromWatchlist,
    isInWatchlist,
  };
}

export { useUserWatchlist, useWatchlistActions, useCheckContentInWatchlist };

async function fetchUserWatchlist(userId, pageSize, pageNumber) {
  const data = await getUserWatchlist(userId, pageNumber, pageSize);

  const { items, pagination } = data;

  // map through raw items and output a formatted item
  const results = await Promise.all(
    items
      .filter((item) => {
        return (
          item?.type !== CONTENT_TYPES.TRAILER &&
          item?.type !== CONTENT_TYPES.MOVIE &&
          item?.available
        );
      })
      .map(async (item, itemIndex) => {
        const image = getPosterImageForGrid(item?.parentImages);

        const {
          id,
          title,
          seasonId,
          seriesId,
          type,
          parentId,
          episodeNumber,
          seasonNumber,
          parentTitle,
          releaseDate,
          rating,
          ratingAge,
          original,
          exclusive,
        } = item;

        const resultItem = new UserWatchlist({
          id,
          title,
          type,
          parentId,
          episodeNumber,
          seasonNumber,
          seasonId,
          seriesId,
          image: image,
          isAvailable: item?.available,
          duration: getDurationSeconds(item),
          parentTitle,
          releaseDate,
          rating,
          ratingAge,
          original,
          exclusive,
          previousContentId: itemIndex === 0 ? null : items[itemIndex - 1]?.id,
        });

        return resultItem;
      })
  );

  return {
    items: results,
    pagination: {
      pageNumber: pagination?.pageNumber,
      totalPages: pagination?.totalPages,
    },
  };
}
