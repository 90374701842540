import { makeRequest } from '../common/base';

/**
 * Gets information for a specific piece of content by its id
 * @param {string} id The mediaId of the content to get information for
 * @returns {Promise<{}>} A promise containing a JSON data object
 */
const fetchContentById = async (id) => {
  return makeRequest(`/content/${id}`);
};

/**
 * Gets the streams for a specific piece of content
 * @param {string} playbackContentId The contentId to get playback streams for
 * @returns {Promise<{}>} A promise containing a JSON data object
 */
const fetchContentVod = async (contentId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  const neededHeader = 'x-crackle-media-session';

  return makeRequest(`/playback/vod/${contentId}`, requestOptions, false, {
    playerMediaSessionId: neededHeader,
  });
};

/**
 * Gets the trailer of a given content id
 * @param {string} id The id of the content to find trailer for
 * @returns {Promise<{}>} A promise containing a JSON data object
 */
const fetchTrailer = async (id) => {
  return makeRequest(`/content/${id}/trailer`);
};

/**
 * Gets the immediate playable children of a Series or Movie Channel by id
 * @param {string} id The mediaId of the content to find children for
 * @returns {Promise<{}>} A promise containing a JSON data object
 */
const fetchFirstPlayableMedia = async (id) => {
  return makeRequest(`/content/${id}/media`);
};

/**
 * Gets a list of the child content related to a specific piece of content by its id. (i.e. episodes in a season, seasons in a series)
 * @param {string} id The mediaId of the content to find children for
 * @returns {Promise<{}>} A promise containing a JSON data object
 */
const fetchContentChildren = async (id) => {
  return makeRequest(`/content/${id}/children`);
};

export {
  fetchContentById,
  fetchContentVod,
  fetchTrailer,
  fetchFirstPlayableMedia,
  fetchContentChildren,
};
