import logger from '../utils/logger';

export default class VizbeeMediaConverter {
  //--------------
  // Public APIs
  //--------------

  static getVizbeeVideoInfo = (videoInfo) => {
    if (!videoInfo || !videoInfo.mediaId) {
      logger.warn(
        `VizbeeMediaConverter::getVizbeeVideoInfo - INVALID videoInfo`
      );
      return undefined;
    }
    logger.info(
      `VizbeeMediaConverter::getVizbeeVideoInfo - videoInfo=${videoInfo}`
    );
    const vInfo = new window.vizbee.continuity.messages.VideoInfo();
    vInfo.guid = `category:vod::media:${videoInfo.mediaId}`;

    if (videoInfo.contentType === 'Full Episode') {
      vInfo.title = videoInfo.episodeTitle;
    } else {
      vInfo.title = videoInfo.title;
    }
    vInfo.imgUrl = videoInfo.mobileContentPoster;
    vInfo.isLive = false; // there is no LIVE content as of now
    vInfo.videoUrl = videoInfo.dash || videoInfo.hls || '';

    return vInfo;
  };
}
