import { useEffect } from 'react';
import { JsSpatialNavigation } from 'react-js-spatial-navigation';

const useFocus = (selector) => {
  useEffect(() => {
    JsSpatialNavigation.focus(selector);
  }, [selector]);
};

export default useFocus;
