/* eslint-disable no-underscore-dangle */

import Stream from './stream';

class Vod {
  constructor({ contentId, mode, playerMediaSessionId, mediaId, sidecar }) {
    this._contentId = contentId;
    this._mode = mode;
    this._streams = {};
    this._playerMediaSessionId = playerMediaSessionId;
    this._mediaId = mediaId;
    this._sidecar = sidecar;
  }

  // getters and setters
  get contentId() {
    return this._contentId;
  }

  set contentId(contentId) {
    this._contentId = contentId;
  }

  get mode() {
    return this._mode;
  }

  set mode(mode) {
    this._mode = mode;
  }

  get streams() {
    return this._streams;
  }

  set streams(streams) {
    this._streams = streams;
  }

  addStream(stream) {
    this._streams = Object.assign({}, this._streams, {
      [stream.type]: new Stream(stream),
    });
  }

  removeStream(stream) {
    delete this._streams[stream?.type];
  }

  get playerMediaSessionId() {
    return this._playerMediaSessionId;
  }

  set playerMediaSessionId(playerMediaSessionId) {
    this._playerMediaSessionId = playerMediaSessionId;
  }

  get mediaId() {
    return this._mediaId;
  }

  set mediaId(mediaId) {
    this._mediaId = mediaId;
  }

  get sidecar() {
    return this._sidecar;
  }

  set sidecar(sidecar) {
    this._sidecar = sidecar;
  }
}

export default Vod;
