const LOCAL_STORAGE = {
  AUTH_ACCESS_TOKEN: 'auth-access-token',
  AUTH_REFRESH_TOKEN: 'auth-refresh-token',
  AD_FORGIVENESS: 'ad-forgiveness',
  AD_CONFIGURATION: 'ad-configuration',
  CCPA_OPTION: 'ccpa-option',
  PRIVACY_SETTINGS: 'privacy-settings',
  LAT_OVERRIDE_CONSENT: 'lat-override-consent',
  CLIENT_REGION: 'client-region',
  PARENTAL_CONTROLS_HISTORY: 'parental-controls-history',
  SESSION_HISTORY: 'session-history',
  SIGNED_IN_USER: 'signed-in-user',
  SORT_HISTORY: 'sort-history',
  FLAGS: 'flags',
  DEVICE_INFO_DEV: 'device-info-dev',
  DEEP_LINK: {
    CONTENT_ID: 'deep-link-content-id',
    ACTION: 'deep-link-action',
  },
};

export default LOCAL_STORAGE;
