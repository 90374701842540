const ERROR_TYPE = {
  CONNECTION_LOST: {
    titleFirstLine: 'CONNECTION_ERROR_FIRST_LINE',
    titleSecondLine: 'CONNECTION_ERROR_SECOND_LINE',
    footer: 'CONNECTION_ERROR_FOOTER',
    TTS: 'CONNECTION_ERROR_DESCRIPTION',
  },
  PLAYBACK: {
    titleFirstLine: 'PLAYBACK_ERROR_FIRST_LINE',
    titleSecondLine: 'PLAYBACK_ERROR_SECOND_LINE',
    footer: 'PLAYBACK_ERROR_FOOTER',
    TTS: 'PLAYBACK_ERROR_DESCRIPTION',
  },
  API: {
    titleFirstLine: 'API_ERROR_FIRST_LINE',
    titleSecondLine: 'API_ERROR_SECOND_LINE',
    footer: 'API_ERROR_FOOTER',
    TTS: 'API_ERROR_DESCRIPTION',
  },
  APP_LOAD: {
    titleFirstLine: 'APP_LOAD_ERROR_FIRST_LINE',
    titleSecondLine: 'APP_LOAD_ERROR_SECOND_LINE',
    footer: 'APP_LOAD_ERROR_FOOTER',
    TTS: 'APP_LOAD_ERROR_DESCRIPTION',
  },
  REDEMPTION_ERROR: {
    titleFirstLine: 'LOYALTY_REDEMPTION_ERROR_FIRST_LINE',
    titleSecondLine: 'LOYALTY_REDEMPTION_ERROR_SECOND_LINE',
    footer: 'LOYALTY_REDEMPTION_ERROR_FOOTER',
    TTS: 'LOYALTY_REDEMPTION_ERROR_DESCRIPTION',
  },
  CONTENT_UNAVAILABLE: {
    titleFirstLine: 'CONTENT_UNAVAILABLE_FIRST_LINE',
    titleSecondLine: 'CONTENT_UNAVAILABLE_SECOND_LINE',
    footer: 'CONTENT_UNAVAILABLE_FOOTER',
    TTS: 'CONTENT_UNAVAILABLE_DESCRIPTION',
  },
  VIDEO_EXPIRED: {
    titleFirstLine: 'VIDEO_EXPIRED_ERROR_FIRST_LINE',
    titleSecondLine: 'VIDEO_EXPIRED_ERROR_SECOND_LINE',
    footer: 'VIDEO_EXPIRED_ERROR_FOOTER',
    TTS: 'VIDEO_EXPIRED_ERROR_DESCRIPTION',
  },
};

export default ERROR_TYPE;
