const CONTENT_TYPES = {
  EPISODE: 'Full Episode',
  FEATURED_FILM: 'Feature Film',
  MOVIE: 'Movie',
  SERIES: 'Series',
  TRAILER: 'Trailer',
};

const IMAGE_TYPES = {
  KEYART: 'keyart',
  THUMBNAIL: 'thumbnail',
};

export { CONTENT_TYPES, IMAGE_TYPES };
