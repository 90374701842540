import { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import useGlobalContext from '../../hooks/use-global-context';
import WatchlistToast from '../../components/watchlist-toast/watchlist-toast';
import RedeemedRewardToast from '../../components/loyalty-toasts/redeemed-reward-toast';
import PointsAccrualToast from '../../components/loyalty-toasts/points-accrual-toast';
import TOAST_TYPES from '../../enums/toast-types';
import { isFeatureFlagEnabled } from '../../utils/feature-flags';

// Styles
import './toast.scss';

function ToastContainer() {
  const [isVisible, setIsVisible] = useState(false);
  const hideToastTimeOut = useRef(null);
  const toast = useGlobalContext((state) => state.toast);

  const loyaltyToastsEnable = isFeatureFlagEnabled('loyaltyToasts');

  // array of toasts types from loyalty feature
  const loyaltyToasts = [
    TOAST_TYPES.REDEEMED_REWARD,
    TOAST_TYPES.LOYALTY_POINTS_ACCRUAL,
  ];

  // when toast set start time out
  useEffect(() => {
    if (toast) {
      setIsVisible(true);
      hideToastTimeOut.current = setTimeout(() => {
        setIsVisible(false);
      }, toast.visibleTime);
    }

    return () => {
      clearTimeout(hideToastTimeOut.current);
      setIsVisible(false);
    };
  }, [toast]);

  function getToastComponent() {
    let toastComponent = null;
    if (!loyaltyToastsEnable && loyaltyToasts.includes(toast.type)) {
      return toastComponent;
    }
    switch (toast.type) {
      case TOAST_TYPES.WATCHLIST:
        toastComponent = <WatchlistToast {...toast.props} />;
        break;
      case TOAST_TYPES.REDEEMED_REWARD:
        toastComponent = <RedeemedRewardToast {...toast.props} />;
        break;
      case TOAST_TYPES.LOYALTY_POINTS_ACCRUAL:
        toastComponent = <PointsAccrualToast {...toast.props} />;
        break;
      default:
        toastComponent = null;
    }

    return toastComponent;
  }

  return (
    <div
      className={classnames('toast-container', { visible: isVisible })}
      aria-atomic={true}
      aria-hidden={!isVisible}
      aria-live={'assertive'}
    >
      {toast && getToastComponent()}
    </div>
  );
}

export default ToastContainer;
