import CONTENT_ROW_TYPES from '../enums/content-row-types';
import APP_CONFIG from '../config/app-config.json';
import { scaleFrom720p } from './scale-from-720p';

/*
 *  Finds an image with exactly the given width & height, on the content itself or on the parent.
 */
export function findAssetImage(content, width, height) {
  return (
    content?.assets?.images?.find(
      (i) => i.width === width && i.height === height
    )?.url ||
    content?.parent?.assets?.images?.find(
      (i) => i.width === width && i.height === height
    )?.url ||
    null
  );
}

// Returns the appropriate image for different row types
export function getPosterImage(contentType, content) {
  const screenWidth = window.innerWidth;
  if (content?.assets?.images) {
    switch (contentType) {
      case CONTENT_ROW_TYPES.STANDARD:
        if (screenWidth > 1920 && findAssetImage(content, 520, 790)) {
          return findAssetImage(content, 520, 790);
        }
        if (screenWidth > 1920 && findAssetImage(content, 800, 1200)) {
          return findAssetImage(content, 800, 1200);
        }
        if (screenWidth > 1280 && findAssetImage(content, 320, 480)) {
          return findAssetImage(content, 320, 480);
        }
        return findAssetImage(content, 220, 330);

      case CONTENT_ROW_TYPES.DETAILS:
        if (screenWidth > 1920 && findAssetImage(content, 1920, 1080)) {
          return findAssetImage(content, 1920, 1080);
        }
        if (screenWidth > 1280 && findAssetImage(content, 806, 453)) {
          return findAssetImage(content, 806, 453);
        }
        return findAssetImage(content, 587, 330);

      case CONTENT_ROW_TYPES.ORIGINALS:
        if (screenWidth > 1280 && findAssetImage(content, 1024, 1024)) {
          return findAssetImage(content, 1024, 1024);
        }
        return findAssetImage(content, 460, 460);

      default:
        return null;
    }
  }
  return null;
}
/*
 *  Finds the first available image on the content itself or on the parent.
 */
function findAvailableAssetImage(content) {
  return content?.assets?.images?.find((i) => i?.url)?.url || null;
}

export function getMobilePosterImage(contentType, content) {
  let posterImage = getPosterImage(contentType, content);
  if (!posterImage) {
    posterImage = findAvailableAssetImage(content);
  }
  return posterImage;
}

// Returns the appropiate image for the channel according to the screenSize
export function getChannelPosterImage(content) {
  const screenWidth = window.innerWidth;
  if (content && content?.assets?.images) {
    if (
      screenWidth > 1920 &&
      content?.assets?.images?.find((i) => i.width === 520 && i.height === 790)
        ?.length
    ) {
      return content.assets.images.find(
        (i) => i.width === 520 && i.height === 790
      ).url;
    }
    if (
      screenWidth > 1280 &&
      content?.assets?.images?.find((i) => i.width === 320 && i.height === 480)
        ?.length
    ) {
      return content.assets.images.find(
        (i) => i.width === 320 && i.height === 480
      ).url;
    }
    return content?.assets?.images?.find(
      (i) => i.width === 220 && i.height === 330
    )?.url;
  }

  return null;
}

// Returns appropriate fall-back image for different row types
export function getFallbackPoster(type) {
  switch (type) {
    case CONTENT_ROW_TYPES.STANDARD:
      return {
        image: `${APP_CONFIG.PUBLIC_URL}static/media/fallback_standard-v1`,
        width: scaleFrom720p(160),
        height: scaleFrom720p(250.7),
      };

    case CONTENT_ROW_TYPES.DETAILS:
      return {
        image: `${APP_CONFIG.PUBLIC_URL}static/media/fallback_wide-v1`,
        width: scaleFrom720p(512),
        height: scaleFrom720p(250.7),
      };

    case CONTENT_ROW_TYPES.ORIGINALS:
      return {
        image: `${APP_CONFIG.PUBLIC_URL}static/media/fallback_square-v1`,
        width: scaleFrom720p(336),
        height: scaleFrom720p(336),
      };

    default:
      return {
        image: `${APP_CONFIG.PUBLIC_URL}static/media/fallback_standard`,
        width: scaleFrom720p(160),
        height: scaleFrom720p(250.7),
      };
  }
}
