import BRAND_CONFIG from './brand-config.json';

const PIN_CONFIG = {
  error: {
    title: 'Invalid PIN',
    description: `Your PIN did not match the one on file. Need help? Contact ${BRAND_CONFIG.SUPPORT_EMAIL}`,
    resetDescription: `Your Reset PIN was not valid. Need help? Contact ${BRAND_CONFIG.SUPPORT_EMAIL}`,
  },
  saved: {
    title: 'PIN Saved',
    description: `Remember not to forget your PIN. If you do, contact ${BRAND_CONFIG.SUPPORT_EMAIL}`,
  },
  lock: {
    title: 'Set your PIN',
    description:
      'In order to protect your content settings, please set a unique PIN. If you’d like to change your content settings later, you will need this PIN.',
    saveButton: 'Save PIN',
    backButton: 'Cancel',
  },
  verification: {
    title: 'Are you Sure?',
    description: 'Let’s be sure this is the right PIN to store.',
    saveButton: 'Yes, Save PIN',
    backButton: 'Edit PIN',
  },
  unlock: {
    title: 'Unlock Content Settings',
    description: `Please enter your unique PIN. Need help? Contact ${BRAND_CONFIG.SUPPORT_EMAIL}`,
    saveButton: 'Submit PIN',
    backButton: 'Cancel',
  },
  success: {
    title: 'PIN Successful',
    description:
      'Your Content Settings are temporarily  unlocked. Once you exit Settings, your PIN will re-lock.',
    resetDescription:
      'Your Parental PIN has been successful reset, and all Parental Controls have been returned to the Grown Up option.',
  },
  reset: {
    title: 'Reset your PIN',
  },
};

export default PIN_CONFIG;
