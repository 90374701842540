import LOCAL_STORAGE from '../enums/local-storage';
import { setLocalStorageItem, getLocalStorageItem } from './local-storage';

/**
 * Make sure our local session object is setup for us
 */
const initAdForgiveness = () => {
  // Reset ad forgiveness every time when user launch the app
  setLocalStorageItem(LOCAL_STORAGE.AD_FORGIVENESS, 0);
};

/**
 * Get current ad forgiveness value
 *
 * @return {int} Number of seconds accumulated for ad forgiveness
 */
const getAdForgiveness = () => {
  return getLocalStorageItem(LOCAL_STORAGE.AD_FORGIVENESS);
};

/**
 * Set ad forgiveness value
 *
 * @param seconds {int} Number of seconds that have passed since last ad played
 */
const setAdForgiveness = (seconds) => {
  setLocalStorageItem(LOCAL_STORAGE.AD_FORGIVENESS, seconds);
};

export { getAdForgiveness, initAdForgiveness, setAdForgiveness };
