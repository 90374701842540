import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import Button from '../button/button';
import BUTTON_TYPES from '../../enums/button-types';

// Icons
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import { ReactComponent as CheckMarkIcon } from '../../assets/icons/check-mark.svg';

function WatchlistButton({
  isInWatchlist,
  inWatchlistIcon,
  notInWatchlistIcon,
  inWatchlistLabel,
  notInWatchlistLabel,
  ...props
}) {
  return (
    <Button
      data-test-id="add-to-watchlist-button"
      icon={isInWatchlist ? inWatchlistIcon : notInWatchlistIcon}
      aria-label={isInWatchlist ? inWatchlistLabel : notInWatchlistLabel}
      type={BUTTON_TYPES.ICONBUTTON}
      {...props}
    >
      {isInWatchlist ? inWatchlistLabel : notInWatchlistLabel}
    </Button>
  );
}

WatchlistButton.propTypes = {
  isInWatchlist: PropTypes.bool,
  inWatchlistIcon: PropTypes.elementType,
  notInWatchlistIcon: PropTypes.elementType,
  inWatchlistLabel: PropTypes.string,
  notInWatchlistLabel: PropTypes.string,
};

WatchlistButton.defaultProps = {
  isInWatchlist: false,
  inWatchlistIcon: CheckMarkIcon,
  notInWatchlistIcon: AddIcon,
  inWatchlistLabel: t`IN_WATCHLIST`,
  notInWatchlistLabel: t`WATCHLIST_ADD`,
};

export default WatchlistButton;
