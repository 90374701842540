import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { useRef, useEffect, useState } from 'react';
import { tts } from '../../platform/index';
import { api } from '../../crackle-sdk/v2';
import { JsSpatialNavigation } from 'react-js-spatial-navigation';

// Hooks
import useGlobalContext from '../../hooks/use-global-context';
import useHeroTrailer from '../../hooks/use-hero-trailer';

// Enums
import { CONTENT_TYPES } from '../../enums/content-types';
import DIRECTIONS from '../../enums/directions';
import ROUTES from '../../enums/routes';
import SESSION_HISTORY_TYPES from '../../enums/session-history';
import USER_PLAYBACK_STATE from '../../enums/user-playback-state';
import MODAL_TYPES from '../../enums/modal-types';

// Utils
import {
  getCreditMarker,
  getDurationSeconds,
  getEpisodeAndSeasonLabel,
} from '../../utils/content-details';
import { getSessionHistory } from '../../utils/session-history';
import { getTrailerAutoplayTimeOut } from '../../utils/utils';
import { populateRouteParameters } from '../../utils/url';
import parentalControls from '../../utils/parental-controls';
import { isFeatureFlagEnabled } from '../../utils/feature-flags';

// Styles
import 'react-circular-progressbar/dist/styles.css';

import Hero from '../../components/hero/hero';

function HeroContainer({
  data,
  onSelectHeroContent,
  initiallyCollapsed,
  heroDownMovement,
}) {
  const {
    mediaContentId = '',
    trailerContentId = '',
    rating = '',
    season = null,
    episode = null,
    parentId = '',
    type: mediaType = '',
    watchAgainSeries = false,
    duration = 0,
  } = data || {};
  const setPlayInitiationType = useGlobalContext(
    (state) => state.setPlayInitiationType
  );
  const hoverEnabled = useGlobalContext((state) => state.hoverEnabled);
  const heroRef = useRef(null);
  const [heroBannerLoaded, setHeroBannerLoaded] = useState(false);

  const [isHeroCollapsed, setIsHeroCollapsed] = useState(
    initiallyCollapsed && Boolean(document.getElementById(`content-row-0`))
  );
  const [isWatchNowFocused, setIsWatchNowFocused] = useState(false);

  const [durationProgress, setDurationProgress] = useState(null);
  const [durationPercent, setDurationPercent] = useState(null);
  const [creditMarker, setCreditMarker] = useState(null);
  const [label, setLabel] = useState('');

  const isBlocked = parentalControls.checkIfBlocked(rating);
  const creditsMarkFixed = 5;

  const moreInfoLink = populateRouteParameters(ROUTES.DETAILS, {
    mediaId: mediaContentId,
  });
  const watchNowLink = `${populateRouteParameters(ROUTES.WATCH, {
    mediaId: mediaContentId,
  })}${isBlocked ? ROUTES.PINSETTINGS : ''}`;

  const { trailer: newSourceData } = api.useContent({
    id: trailerContentId,
    queryOptions: {
      enabled: Boolean(trailerContentId),
    },
  });

  const {
    showHeroTrailer,
    setShowHeroTrailer,
    resetHeroTrailer,
    setShowCircleTimer,
    showCircleTimer,
    trailerTimeLeft,
  } = useHeroTrailer(watchNowLink);

  useEffect(() => {
    const onModalClose = (e) => {
      const { type } = e.detail;
      if (type === MODAL_TYPES.EXIT_APP) {
        JsSpatialNavigation.focus('.watch-now');
      }
    };

    window.addEventListener('close-modal', onModalClose);

    return () => window.removeEventListener('close-modal', onModalClose);
  }, []);

  useEffect(() => {
    if (isHeroCollapsed) {
      resetHeroTrailer();
    } else {
      focusedElementHero();
    }
  }, [isHeroCollapsed, resetHeroTrailer]);

  useEffect(() => {
    function setVideoInfo() {
      const assetType =
        mediaType === CONTENT_TYPES.EPISODE ||
        mediaType === CONTENT_TYPES.SERIES
          ? SESSION_HISTORY_TYPES.TV
          : SESSION_HISTORY_TYPES.MOVIE;
      let savedAsset;
      switch (assetType) {
        case SESSION_HISTORY_TYPES.MOVIE:
          savedAsset = getSessionHistory(
            SESSION_HISTORY_TYPES.MOVIE,
            mediaContentId
          );
          break;

        case SESSION_HISTORY_TYPES.TV:
          savedAsset = getSessionHistory(
            SESSION_HISTORY_TYPES.TV,
            mediaContentId.toLowerCase(),
            season,
            episode,
            parentId
          );
          break;

        default:
          savedAsset = null;
          break;
      }

      let userPlaybackState = USER_PLAYBACK_STATE.NOT_STARTED;

      if (!savedAsset) {
        setDurationProgress(0);
      } else {
        const assetDuration = duration || getDurationSeconds(data);
        const percent = (savedAsset.progress / assetDuration) * 100;

        // if duration is present in the hero data, it's mediaEndTime from history session, same as credit marker.
        const credit =
          duration || getCreditMarker(data) || savedAsset.mediaEndTime;

        setDurationProgress(savedAsset.progress);
        setDurationPercent(percent);
        setCreditMarker(credit);

        if (savedAsset.progress) {
          userPlaybackState = USER_PLAYBACK_STATE.IN_PROGRESS;

          if (savedAsset.progress >= credit) {
            userPlaybackState = USER_PLAYBACK_STATE.COMPLETED;
          }
        }
      }

      const seasonEpisodeLabel = getEpisodeAndSeasonLabel(episode, season);

      switch (userPlaybackState) {
        case USER_PLAYBACK_STATE.IN_PROGRESS:
          seasonEpisodeLabel
            ? setLabel(
                t({
                  id: 'RESUME_CONTENT',
                  values: { content: seasonEpisodeLabel },
                })
              )
            : setLabel(t`RESUME`);
          break;
        case USER_PLAYBACK_STATE.COMPLETED:
          seasonEpisodeLabel
            ? setLabel(
                t({
                  id: 'WATCH_AGAIN_CONTENT',
                  values: { content: seasonEpisodeLabel },
                })
              )
            : setLabel(t`WATCH_AGAIN`);
          break;
        default:
          seasonEpisodeLabel
            ? setLabel(
                t({
                  id: 'WATCH_NOW_CONTENT',
                  values: { content: seasonEpisodeLabel },
                })
              )
            : setLabel(t`WATCH_NOW`);
      }
    }

    if (mediaContentId) {
      setVideoInfo();
    }
  }, [
    data,
    duration,
    episode,
    mediaContentId,
    mediaType,
    parentId,
    season,
    watchAgainSeries,
  ]);

  useEffect(() => {
    const timeoutLength = getTrailerAutoplayTimeOut();
    const shouldTrailerStart =
      !isBlocked &&
      mediaContentId &&
      isWatchNowFocused &&
      heroBannerLoaded &&
      !isHeroCollapsed;

    const autoPlayTimeout = setTimeout(() => {
      if (shouldTrailerStart) {
        if (!isFeatureFlagEnabled('testDisableHeroAutoplay')) {
          if (
            trailerContentId &&
            !isFeatureFlagEnabled('testForceHeroAutoplay')
          ) {
            setShowHeroTrailer(true);
          }
        }
      }
    }, timeoutLength);

    return () => {
      tts(' ');
      clearTimeout(autoPlayTimeout);
    };
  }, [
    isWatchNowFocused,
    heroBannerLoaded,
    isBlocked,
    setShowHeroTrailer,
    mediaContentId,
    isHeroCollapsed,
    trailerContentId,
  ]);

  function focusedElementHero() {
    if (
      !document
        .querySelector(`.watch-now`)
        ?.classList?.contains('focusedElement')
    ) {
      document
        .querySelector('.focusedElement')
        ?.classList.remove('focusedElement');
      document.querySelector(`.watch-now`)?.classList?.add('focusedElement');
    }
  }

  function handleToggleCollapse(status) {
    setIsHeroCollapsed(status);
    if (status) {
      document
        .getElementById('rows-container')
        ?.classList.add('hero-collapsed');
    } else {
      document
        .getElementById('rows-container')
        ?.classList.remove('hero-collapsed');
    }
  }

  function onNoneFocused(event) {
    setIsWatchNowFocused(false);
    resetHeroTrailer();
    if (event.detail.direction !== DIRECTIONS.LEFT && !isHeroCollapsed) {
      handleToggleCollapse(true);
    }
  }

  function onAnyFocused() {
    if (isHeroCollapsed) {
      heroRef.current.scrollIntoView(false);
      handleToggleCollapse(false);

      // scroll to top of the container (specially older browsers)
      if (document.getElementById('base-container'))
        document.getElementById('base-container').scrollTop = 0;
    }
  }

  return (
    <Hero
      data={data}
      onSelectHeroContent={onSelectHeroContent}
      isHeroCollapsed={isHeroCollapsed}
      setHeroBannerLoaded={setHeroBannerLoaded}
      label={label}
      setPlayInitiationType={setPlayInitiationType}
      isBlocked={isBlocked}
      durationProgress={durationProgress}
      durationPercent={durationPercent}
      creditMarker={creditMarker}
      trailerSource={newSourceData}
      showHeroTrailer={showHeroTrailer}
      resetHeroTrailer={resetHeroTrailer}
      trailerTimeLeft={trailerTimeLeft}
      setShowCircleTimer={setShowCircleTimer}
      showCircleTimer={showCircleTimer}
      watchNowLink={watchNowLink}
      setIsWatchNowFocused={setIsWatchNowFocused}
      isWatchNowFocused={isWatchNowFocused}
      focusedElementHero={focusedElementHero}
      onAnyFocused={onAnyFocused}
      onNoneFocused={onNoneFocused}
      hoverEnabled={hoverEnabled}
      moreInfoLink={moreInfoLink}
      creditsMarkFixed={creditsMarkFixed}
      heroDownMovement={heroDownMovement}
      ref={heroRef}
    />
  );
}

HeroContainer.propTypes = {
  data: PropTypes.shape({
    backgroundImage: PropTypes.string,
    description: PropTypes.string.isRequired,
    genres: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })
    ).isRequired,
    mediaContentId: PropTypes.string,
    rating: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    whyItCrackles: PropTypes.string,
  }),
  onSelectHeroContent: PropTypes.func,
  initiallyCollapsed: PropTypes.bool,
  heroDownMovement: PropTypes.func,
};

export default HeroContainer;
