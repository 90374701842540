import LOCAL_STORAGE from '../enums/local-storage';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from './local-storage';

/**
 * Retrieves the auth accessToken from local storage
 * @returns {string} Auth accessToken
 */
const getAuthAccessToken = () =>
  getLocalStorageItem(LOCAL_STORAGE.AUTH_ACCESS_TOKEN);

/**
 * Retrieves the auth refreshToken from local storage
 * @returns {string} Auth refreshToken
 */
const getAuthRefreshToken = () =>
  getLocalStorageItem(LOCAL_STORAGE.AUTH_REFRESH_TOKEN);

/**
 * Removes the auth accessToken entry from local storage
 * @returns {null}
 */
const removeAuthAccessToken = () => {
  removeLocalStorageItem(LOCAL_STORAGE.AUTH_ACCESS_TOKEN);
};

/**
 * Removes the auth refreshToken entry from local storage
 * @returns {null}
 */
const removeAuthRefreshToken = () => {
  removeLocalStorageItem(LOCAL_STORAGE.AUTH_REFRESH_TOKEN);
};

/**
 * Saves the auth accessToken in local storage
 * @param {string} accessToken The auth access token to save
 */
const setAuthAccessToken = (accessToken) =>
  setLocalStorageItem(LOCAL_STORAGE.AUTH_ACCESS_TOKEN, accessToken);

/**
 * Saves the auth refreshToken in local storage
 * @param {string} refreshToken The auth refresh token to save
 */
const setAuthRefreshToken = (refreshToken) =>
  setLocalStorageItem(LOCAL_STORAGE.AUTH_REFRESH_TOKEN, refreshToken);

export {
  getAuthAccessToken,
  getAuthRefreshToken,
  removeAuthAccessToken,
  removeAuthRefreshToken,
  setAuthAccessToken,
  setAuthRefreshToken,
};
