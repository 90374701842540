/* eslint-disable no-underscore-dangle */
class Genre {
  constructor({ id, name, label, type }) {
    this._id = id;
    this._name = name;
    this._label = label;
    this._type = type;
  }

  // getters and setters
  get id() {
    return this._id;
  }

  set id(id) {
    this._id = id;
  }

  get name() {
    return this._name;
  }

  set name(name) {
    this._name = name;
  }

  get label() {
    return this._label;
  }

  set label(label) {
    this._label = label;
  }

  get type() {
    return this._type;
  }

  set type(type) {
    this._type = type;
  }
}

export default Genre;
