const SORT_OPTIONS = [
  {
    id: 'latest',
    label: 'Latest',
    name: 'Latest',
  },
  {
    id: 'alpha-asc',
    label: 'Alphabetical (A-Z)',
    name: 'Alphabetical',
  },
  {
    id: 'alpha-desc',
    label: 'Reverse Alpha (Z-A)',
    name: 'Reverse Alpha',
  },
];

export default SORT_OPTIONS;
