import { useQuery } from 'react-query';
import fetchVttData from '../dataSource/vtt';

/* Hook for parsing VTT files to use the thumbnails in the video scrubber (aka Trick Play) */
function useVttThumbs({ thumbnailsUrl, queryOptions = {} }) {
  const { data, isLoading, isError } = useQuery(
    ['thumbnailsUrl', thumbnailsUrl],
    () => fetchVttData(thumbnailsUrl),
    {
      queryOptions,
    }
  );

  if ((isError && !isLoading) || thumbnailsUrl === '') {
    return { data: null, getCurrentThumb: null, withThumb: false };
  }

  return {
    data,
    withThumb: !!data?.length,
    getCurrentThumb: (time) => data[time],
  };
}

export { useVttThumbs };
