import { v4 as uuidv4 } from 'uuid';

// Enums
import LOCAL_STORAGE from '../enums/local-storage';
import ROUTES from '../enums/routes';

// Configs
import APP_CONFIG from '../config/app-config.json';

// Utils
import { getLocalStorageItem, setLocalStorageItem } from './local-storage';

/**
 * Gets the current browser name and version
 * https://www.seanmcp.com/articles/how-to-get-the-browser-version-in-javascript/
 * @returns {name: string, version: string} The name and version of the current browser
 */
const getBrowserClientNameAndVersion = () => {
  const { userAgent } = navigator;
  let browserClientNameAndVersion;

  if (userAgent.includes('Firefox/')) {
    browserClientNameAndVersion = {
      name: 'Firefox',
      version: userAgent.split('Firefox/')[1],
    };
  } else if (userAgent.includes('Edg/')) {
    browserClientNameAndVersion = {
      name: 'Edge',
      version: userAgent.split('Edg/')[1],
    };
  } else if (userAgent.includes('Chrome/')) {
    browserClientNameAndVersion = {
      name: 'Chrome',
      version: userAgent.split('Chrome/')[1].split(' ')[0],
    };
  } else if (userAgent.includes('Safari/')) {
    browserClientNameAndVersion = {
      name: 'Safari',
      version: userAgent.split('Safari/')[1],
    };
  }
  return browserClientNameAndVersion;
};

/**
 * Gets the load time for a page in milliseconds
 * @returns {string} The string representation of page load time in milliseconds
 */
const getPageLoadTime = () => {
  const [perfData] = window.performance.getEntriesByType('navigation');
  return perfData && perfData.loadEventStart
    ? Math.round(perfData.loadEventStart - (perfData.startTime || 0)).toString()
    : '';
};

/**
 * Indicates if the current page has finished loading
 * @returns {boolean} Whether the current page has finished loading or not
 */
const isPageLoaded = () =>
  window.performance.getEntriesByType('navigation')[0]
    ? window.performance.getEntriesByType('navigation')[0].duration !== 0
    : document.readyState === 'complete';

/**
 * Gets friendly Page Name from url
 * @returns string - page name
 */
const getUserFriendlyPageName = () => {
  switch (true) {
    case window.location.pathname.includes(ROUTES.MOVIES):
      return 'Movies Page';
    case window.location.pathname.includes(ROUTES.TVSHOWS):
      return 'TV Shows Page';
    case window.location.pathname.includes(ROUTES.CHANNELS):
      return 'Channels Page';
    case window.location.pathname.includes(ROUTES.PINSETTINGS):
      return 'Pin Settings Page';
    case window.location.pathname.includes(ROUTES.SETTINGS):
      return 'Settings Page';
    case window.location.pathname.includes(ROUTES.WATCHLIST):
      return 'Watchlist Page';
    case window.location.pathname.includes(ROUTES.SEARCH):
      return 'Search Page';
    case window.location.pathname.includes(ROUTES.HOME):
      return 'Home Page';
    case window.location.pathname.includes(ROUTES.ACCOUNT):
      return 'Account Page';
    case window.location.pathname.includes('details'):
      return 'Details Page';
    case window.location.pathname.includes('watch'):
      return 'Watch Page';
    case window.location.pathname.includes('all-episodes'):
      return 'All Episodes Page';
    default:
      return null;
  }
};

/**
 * Calculates the Reset Pin, in-case a user forgets their pin and have to validate
 * Resets every month of the year
 * @returns {Number} Reset Pin calculated by the formula ( MM * 55) + YYYY
 */
const getResetPin = () => {
  const date = new Date();
  // Returns a value from 0-11, hence a +1
  const currentMonth = date.getMonth() + 1;
  const currentYear = date.getFullYear();
  return currentMonth * 55 + currentYear;
};

const numbersRange = (start, end) => {
  return new Array(end - start + 1).fill(undefined).map((_, i) => i + start);
};

/**
 * getNumericEnvVar returns the value of the given environment var as a number
 * or the default value if the environment variable isn't set or can't be
 * parse.
 */
const getNumericEnvVar = (name, defaultValue) => {
  if (!APP_CONFIG[name]) return defaultValue;
  const convertedEnv = parseInt(APP_CONFIG[name], 10);
  return isNaN(convertedEnv) ? convertedEnv : defaultValue;
};

/**
 * Compare function to verify if a value lies between two limits
 * @param {number || string} lowerLimit Lower limit for the compare function
 * @param {number || string} upperLimit Upper limit for the compare function
 * @param {number || string} testValue Value to check against the two limits
 * @returns {boolean} True or False value
 */
function inRange(lowerLimit, upperLimit, testValue) {
  return testValue > lowerLimit && testValue < upperLimit;
}

/**
 * Utility function that replaces special characters with '_'
 * This is to accomodate the requirements from freewheel.
 *
 * @param {string} dirtyString string which will need to be sanitized based on below criteria
 *
 * @returns {string} sanitizedString
 */
const sanitizeSponsoredData = (dirtyString) => {
  if (dirtyString) {
    const sanitizedString = dirtyString
      .toLowerCase()
      .replace(/"|�|=|!|\+|#|\*|~|;|:|\^|\(|\)|<|>|\[|\]|,|&|\s/g, '_');

    return sanitizedString;
  }

  return dirtyString;
};

/*
 * Utility function that returns an empty object
 */
function noop() {
  void 0;
}

/**
 * Gets timeout value in milliseconds, using timestamp.
 * Returns null if time is already passed.
 *
 * @param {Date} timestamp value to check against.
 */
function getActivationTimeRemaining(timestamp) {
  if (timestamp) {
    const expiryTime = new Date(timestamp);
    const currentTime = new Date();
    if (currentTime > expiryTime) {
      return null;
    }

    const timeRemaining = Math.round(Math.floor(expiryTime - currentTime));

    return timeRemaining;
  }
  return null;
}

/**
 * Utility to check if it is that key pressed (keyValue) is in the array of keyCodes
 *
 * @param {number} keyValue
 * @param {number|array} keyCodes
 */
function isKey(keyValue, keyCodes) {
  if (Array.isArray(keyCodes)) {
    if (keyCodes.indexOf(keyValue) >= 0) {
      return true;
    }
    return false;
  }
  return keyCodes === keyValue;
}

const scriptTagLoader = (src) => {
  return new Promise((resolve, reject) => {
    let resolved = false;
    let errored = false;
    const body = document.getElementsByTagName('body')[0];
    const tag = document.createElement('script');

    tag.type = 'text/javascript';
    tag.async = false; // Load in order

    const handleLoad = () => {
      resolved = true;
      resolve(src);
    };
    const handleReject = () => {
      errored = true;
      reject(src);
    };

    const handleCallback = () => {
      if (resolved) return handleLoad();
      if (errored) return handleReject();
      const state = tag.readyState;
      if (state === 'complete') {
        handleLoad();
      } else if (state === 'error') {
        handleReject();
      }
    };

    tag.addEventListener('load', handleLoad);
    tag.addEventListener('error', handleReject);

    tag.onreadystatechange = handleCallback;

    tag.src = src;
    body.appendChild(tag);
  });
};

/**
 * Get the nearest parent from a node (html element) that has the property of being scrollable.
 * If it doesn't find any, then returns de body element.
 *  */
const getScrollParent = (node) => {
  const isElement = node instanceof HTMLElement;
  const overflowY = isElement && window.getComputedStyle(node).overflowY;
  const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';

  if (!node) {
    return null;
  }
  if (isScrollable && node.scrollHeight >= node.clientHeight) {
    return node;
  }

  return getScrollParent(node.parentNode) || document.body;
};

/*
  Function to retrieve Device UUID & Device Model name on web platform
*/
const getDeviceInformationForWeb = () => {
  let deviceInfo = JSON.parse(
    getLocalStorageItem(LOCAL_STORAGE.DEVICE_INFO_DEV)
  );
  if (!deviceInfo) {
    const duid = uuidv4();
    deviceInfo = {
      duid,
      modelName: `Web-${duid.slice(0, 8)}`,
    };
    setLocalStorageItem(
      LOCAL_STORAGE.DEVICE_INFO_DEV,
      JSON.stringify(deviceInfo)
    );
  }
  return deviceInfo;
};

// trailer autoplay timeout in seconds
function getTrailerAutoplayTimeOut() {
  return 5000;
}

//
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
}
// add script tag to selected target
const addScript = (url, target) => {
  const tag = document.createElement('script');
  tag.type = 'text/javascript';
  tag.src = url;
  document.querySelector(target).appendChild(tag);
};

// returns the version number with the release candidate number appended to it
// if the release candidate number is 0 or undefined, the version number is returned as is
// if the environment is production, the release candidate number is not appended to the version number
function getAppVersion() {
  const rc = Number(APP_CONFIG.RELEASE_CANDIDATE);
  return rc && APP_CONFIG.ENV !== 'production'
    ? `${APP_CONFIG.VERSION}-rc.${rc}`
    : APP_CONFIG.VERSION;
}

// returns the common version number with the release candidate number appended to it
// if the release candidate number is 0 or undefined, the version number is returned as is
// if the environment is production, the release candidate number is not appended to the version number
function getCommonVersion() {
  const rc = Number(APP_CONFIG.RELEASE_CANDIDATE);
  return rc && APP_CONFIG.ENV !== 'production'
    ? `${APP_CONFIG.COMMON_VERSION}-rc.${rc}`
    : APP_CONFIG.COMMON_VERSION;
}

// scroll to the top of the page
function scrollToTop() {
  if (document.getElementById('base-container'))
    document.getElementById('base-container').scrollTop = 0;
}

export {
  getBrowserClientNameAndVersion,
  getNumericEnvVar,
  getPageLoadTime,
  getResetPin,
  getUserFriendlyPageName,
  inRange,
  isPageLoaded,
  noop,
  numbersRange,
  isKey,
  scriptTagLoader,
  getScrollParent,
  getActivationTimeRemaining,
  sanitizeSponsoredData,
  getDeviceInformationForWeb,
  getTrailerAutoplayTimeOut,
  shuffleArray,
  addScript,
  getAppVersion,
  getCommonVersion,
  scrollToTop,
};
