// Check for more info
// https://crackle.atlassian.net/wiki/spaces/CP/pages/1477509123/Loyalty+and+Rewards#Reward-Redemptions-Error-Codes

const LOYALTY_USER_POINT_STATUS = {
  // user is opted out or signed out
  OPTED_OUT: 'OPTED_OUT',
  // user is opted in and has no points
  OPTED_IN_NO_POINTS: 'OPTED_IN_NO_POINTS',
  // user is opted in and has some points all rewards are locked for different reasons than "not enough points"
  OPTED_IN_NO_AVAILABLE_REWARDS: 'OPTED_IN_NO_AVAILABLE_REWARDS',
  // user is opted in and has some points there a reward will be unlocked when user acummulate enough points
  OPTED_IN_AVAILABLE_REWARDS_NOT_ENOUGH_POINS:
    'OPTED_IN_AVAILABLE_REWARDS_NOT_ENOUGH_POINS',
  // user is opted in and has enough points to redeem a reward
  OPTED_IN_AVAILABLE_REWARDS: 'OPTED_IN_AVAILABLE_REWARDS',
};

const LOYALTY_ANALYTICS = {
  LEARN_MORE: 'Learn More',
  EARN_BY_WATCHING: 'Earn points by watching',
};

export { LOYALTY_USER_POINT_STATUS, LOYALTY_ANALYTICS };
