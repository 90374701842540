import classnames from 'classnames';
import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

// Styles
import './navbar.scss';

// Hooks
import useNavbar from '../../hooks/use-navbar';
import useModalHandler from '../../hooks/use-modal-handler';

// Components
import FocusableSection from '../spatial-navigation/focusable-section';
import { isFeatureFlagEnabled } from '../../utils/feature-flags';
import MODAL_TYPES from '../../enums/modal-types';

const Navbar = memo(
  ({
    children,
    className = '',
    navbarKey,
    openAlways = false,
    openByDefault = false,
    openOnNoneFocused = false,
  }) => {
    const { isModalOn, getModalType } = useModalHandler();
    const { isOpen, setIsOpen } = useNavbar(openByDefault, navbarKey);
    // When there is a modal the menu shouldn't be opened
    const isExitModal = getModalType() === MODAL_TYPES.EXIT_APP;
    const onAnyFocused = () =>
      setIsOpen(!isModalOn() || isExitModal, navbarKey);
    const onNoneFocused = () => {
      setIsOpen(openOnNoneFocused, navbarKey);
    };

    // When there is a modal the menu shouldn't be opened if openOnNoneFocused is false
    useEffect(() => {
      if (isOpen && isModalOn() && !openOnNoneFocused) {
        setIsOpen(false, navbarKey);
      }
    }, [isModalOn, isOpen, openOnNoneFocused, setIsOpen]);

    return (
      <FocusableSection
        className={classnames('navbar', className, {
          'enable-css-transitions': isFeatureFlagEnabled('animations'),
          open: openAlways ? true : isOpen,
        })}
        onAnyFocused={onAnyFocused}
        onNoneFocused={onNoneFocused}
        sectionId={`navbar-${className.replace(' ', '-')}`}
        enterTo="last-focused"
      >
        {children}
      </FocusableSection>
    );
  }
);

Navbar.displayName = 'Navbar';

Navbar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  openAlways: PropTypes.bool,
  openByDefault: PropTypes.bool,
  openOnNoneFocused: PropTypes.bool,
  navbarKey: PropTypes.string,
};

export default Navbar;
