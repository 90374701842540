const PLAY_INITIATION_TYPES = {
  AUTO_HERO: 'auto-hero',
  AUTO_UP_NEXT_MOVIE: 'auto-up-next-movie',
  AUTO_UP_NEXT_EPISODE: 'auto-up-next-episode',
  AUTO_UP_NEXT_SERIES: 'auto-up-next-series',
  PLAYER_AUTO: 'player_auto',
  MANUAL_HERO: 'manual-hero',
  MANUAL_UP_NEXT_MOVIE: 'manual-up-next-movie',
  MANUAL_UP_NEXT_EPISODE: 'manual-up-next-episode',
  MANUAL_UP_NEXT_SERIES: 'manual-up-next-series',
  MANUAL_GENERAL_NAVIGATION: 'manual-general-navigation',
};

export default PLAY_INITIATION_TYPES;
