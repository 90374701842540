// NA = User hasn't been presented with options.
// NP = Notice Provided
// OO = Opted-out of the sale of data
// NOOT = Non Opt Out Transaction
// NONE = User has been presented with options & opted out.
const PRIVACY_OPTIONS = {
  NA: '1---',
  NP_OO_NOOT: '1YYY',
  NP_OO: '1YYN',
  NP: '1YNN',
  NP_NOOT: '1YNY',
  OO_NOOT: '1NYY',
  OO: '1NYN',
  NOOT: '1NNY',
  NONE: '1NNN',
};

export default PRIVACY_OPTIONS;
