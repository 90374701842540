import { makeRequest } from './common/base';
import { getFeatureFlags } from '../configuration';
import { LOYALTY_REDEMPTION_STATUS } from './enums/crackle-api-response-codes';
const flags = getFeatureFlags();
const endpoints = {
  redeemReward: (userId, rewardId) =>
    `/users/${userId}/loyalty/rewards/${rewardId}`,
  rewardsList: () => `/loyalty/rewards`,
  userRewardsList: (userId) => `/users/${userId}/loyalty/rewards`,
  isUserOptIn: (userId) => `/users/${userId}/loyalty/opt-in`,
  optInOptOut: (userId) => `/users/${userId}/loyalty/opt-in`,
  loyaltyContentPoints: (contentId) => `/loyalty/content/${contentId}`,
};

/**
 * Get if user is opt-in / opt-out in rewards system
 *
 * @param {string} userId The user ID
 * @returns {Promise<{}>} A promise containing a JSON data object
 */

const isUserOptIn = async (userId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return makeRequest(endpoints.userProfile(userId), requestOptions);
};

/**
 * Get the points information of a selected content
 *
 * @param {string} contentId
 * @returns {Promise<{}>} A promise containing a JSON data object with points specifications
 */

const getLoyaltyContentPoints = async (contentId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return makeRequest(endpoints.loyaltyContentPoints(contentId), requestOptions);
};

/**
 * Suscribe user to reward system
 *
 * @param {string} userId The user ID
 * @returns {Promise<{}>} A promise containing a JSON data object
 */
const loyaltyUserOptIn = async (userId) => {
  const data = JSON.stringify({
    loyaltyOptIn: true,
  });

  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: data,
  };

  return makeRequest(endpoints.optInOptOut(userId), requestOptions);
};

/**
 * Unsuscribe user from reward system
 *
 * @param {string} userId The user ID
 * @returns {Promise<{}>} A promise containing a JSON data object
 */
const loyaltyUserOptOut = async (userId) => {
  const data = JSON.stringify({
    loyaltyOptIn: false,
  });

  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: data,
  };

  return makeRequest(endpoints.optInOptOut(userId), requestOptions);
};

/**
 * Get available rewards list
 *
 * @returns {Promise<{}>} A promise containing a JSON data object with rewards list
 */

const getRewardsList = async () => {
  if (flags.testLoyaltyApiMock) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(import('./mockData/rewardsListMock.json'));
      }, 500);
    });
  }
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return makeRequest(endpoints.rewardsList(), requestOptions);
};

/**
 * Get the active rewards list of the user
 *
 * @param {string} userId The user ID
 * @returns {Promise<{}>} A promise containing a JSON data object with user rewards list
 */

const getUserRewardsList = async (userId) => {
  if (flags.testLoyaltyApiMock) {
    // A fake reward that expires on 2024-05-12
    return import('./mockData/userRewardMock.json');
  }
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return makeRequest(endpoints.userRewardsList(userId), requestOptions);
};

/**
 * Redeems a reward with given userId and rewardId
 *
 * @param {string} userId The user ID
 * @param {string} rewardId The reward ID
 * @returns {Promise<{}>} A promise
 */

const redeemReward = async (userId, rewardId) => {
  if (flags.testLoyaltyApiMock) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({});
      }, 1000);
    });
  }
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
  };

  return makeRequest(endpoints.redeemReward(userId, rewardId), requestOptions);
};

/** search the most closest reward that can be redeemed by a user acording its points
 * @param {string} userId The user ID
 * @param {string} userPoints user loyalty points
 *
 */
const getFirstRedeemableReward = async (userId) => {
  let nextReward = null;

  if (userId) {
    const rewards = await getRewardsList();

    rewards.items.sort((a, b) => {
      return a.points - b.points;
    });

    [nextReward] = rewards.items.filter(
      (reward) =>
        reward.redemption.status ||
        reward.redemption?.reason ===
          LOYALTY_REDEMPTION_STATUS.NOT_ENOUGH_POINTS
    );
  }

  return nextReward;
};

export {
  isUserOptIn,
  getLoyaltyContentPoints,
  loyaltyUserOptIn,
  loyaltyUserOptOut,
  getRewardsList,
  getUserRewardsList,
  redeemReward,
  getFirstRedeemableReward,
};
