import { useEffect, useRef } from 'react';
import { JsSpatialNavigation } from 'react-js-spatial-navigation';

// Hooks
import useGlobalContext from './use-global-context';

export default function useCursor() {
  const setHoverEnabled = useGlobalContext((state) => state.setHoverEnabled);
  const hoverEnabled = useGlobalContext((state) => state.hoverEnabled);
  const localHoverEnabled = useRef(hoverEnabled);

  useEffect(() => {
    const mouseOn = () => {
      if (!localHoverEnabled.current) {
        JsSpatialNavigation.pause();
        localHoverEnabled.current = true;
        setHoverEnabled(true);
      }
    };

    const mouseOff = () => {
      if (localHoverEnabled.current) {
        JsSpatialNavigation.resume();
        localHoverEnabled.current = false;
        setHoverEnabled(false);
      }
    };

    const cursorVisibilityChange = (event) => {
      const { visibility } = event.detail;
      if (visibility) {
        mouseOn();
      } else {
        mouseOff();
      }
    };

    window.document.addEventListener('mousemove', mouseOn, { once: true });
    window.document.addEventListener('keydown', mouseOff);

    window.document.addEventListener(
      'cursorStateChange',
      cursorVisibilityChange,
      false
    );

    return () => {
      window.document.removeEventListener(
        'cursorStateChange',
        cursorVisibilityChange
      );

      window.document.removeEventListener('mousemove', mouseOn);
      window.document.addEventListener('keydown', mouseOff);
    };
  }, [hoverEnabled, setHoverEnabled]);
}
