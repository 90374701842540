import { getHeaders, getApiUrl } from './vars';
import { getConfig } from '../../configuration';
import { tryRequest } from './base';

const endpoints = {
  token: () => `/auth/token`,
};

/**
 * Gets new auth tokens from the Crackle API using the existing refreshToken
 * @param {string} endpoint The endpoint to send the request to
 * @returns {Promise<{}>} A promise containing a data object
 */
export async function getNewAuthTokens(
  isAppConfigEndpoint = false,
  baseUrl = getApiUrl(),
  headers = null
) {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    headers: {
      ...getHeaders(),
      'x-crackle-refresh-token': getConfig().getAuthRefreshToken(),
    },
  };

  return tryRequest(
    endpoints.token(),
    requestOptions,
    isAppConfigEndpoint,
    baseUrl,
    headers
  );
}
