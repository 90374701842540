import { useLocation } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import { JsSpatialNavigation } from 'react-js-spatial-navigation';
import APP_CONFIG from '../../config/app-config.json';

// Components
import Navbar from '../navbar/navbar';
import NavbarItem from '../navbar-item/navbar-item';

// Config
import { BACK_BUTTON_CONFIG } from '../../config/back-button-config';

// Utils
import mParticle from '../../utils/mparticle';
import { supportCapability } from '../../platform/index';
import { getUrlParamValue } from '../../utils/url';

// Enums
import PLATFORM_CAPABILITIES from '../../enums/platform-capabilities';
import { SETTING_PATHS } from '../../enums/settings-data';
import NAVBAR_KEYS from '../../enums/navbarKeys';

// Styles
import './settings-submenu.scss';

function SettingsSubMenu() {
  const location = useLocation();

  const aboutPath = SETTING_PATHS.ABOUT;
  const infoSharingPath = SETTING_PATHS.PRIVACY;
  const adSettingsPath = SETTING_PATHS.AD_SETTINGS;
  const privacyPolicyPath = SETTING_PATHS.PRIVACY_POLICY;
  const tosPath = SETTING_PATHS.TOS;
  const cpraPath = SETTING_PATHS.CPRA;
  const devPath = SETTING_PATHS.DEV;
  const showDevSettings =
    APP_CONFIG.ENV !== 'production' && APP_CONFIG.ENV !== 'preprod';

  const pageParamValueMatches = (value) =>
    getUrlParamValue('page', location.search) === value;

  return (
    <Navbar
      className="app-submenu__settings"
      openAlways
      openByDefault
      openOnNoneFocused
      navbarKey={NAVBAR_KEYS.SETTINGS}
    >
      <NavbarItem
        navbarKey={NAVBAR_KEYS.SETTINGS}
        back={BACK_BUTTON_CONFIG.SETTINGS_SUBMENU}
        className="app-submenu__item basic-settings"
        id="basic-settings"
        key="basic-settings"
        data-test-id="settings-submenu-basic-settings"
        to={`${location.pathname}`}
        isActiveOverride={location.search === ''}
        isFocusOnPageLoad={location.search === ''}
        aria-label={`${t`SUBMENU`} ${t`BASIC_SETTINGS`}`}
        selectionOverrides={{ up: '', left: '.menu-settings' }}
        onClick={() => {
          mParticle.mParticleMenuClick({
            categoryName: 'Basic Settings',
            categoryPosition: 'Settings Menu',
            pageSection: 'Settings',
          });
        }}
      >
        <Trans>BASIC_SETTINGS</Trans>
      </NavbarItem>

      <NavbarItem
        back={BACK_BUTTON_CONFIG.SETTINGS_SUBMENU}
        className="app-submenu__item"
        id={aboutPath}
        key={aboutPath}
        to={`${location.pathname}?page=${aboutPath}`}
        isActiveOverride={pageParamValueMatches(aboutPath)}
        aria-label={`${t`SUBMENU`} ${t`ABOUT`}`}
        onClick={() => {
          mParticle.mParticleMenuClick({
            categoryName: t`ABOUT`,
            categoryPosition: 'Settings Menu',
            pageSection: 'Settings',
          });
        }}
      >
        <Trans>ABOUT</Trans>
      </NavbarItem>
      {!supportCapability(PLATFORM_CAPABILITIES.CCPA) && (
        <NavbarItem
          back={BACK_BUTTON_CONFIG.SETTINGS_SUBMENU}
          className="app-submenu__item"
          id={infoSharingPath}
          key={infoSharingPath}
          to={`${location.pathname}?page=${infoSharingPath}`}
          isActiveOverride={pageParamValueMatches(infoSharingPath)}
          aria-label={`${t`SUBMENU`} ${t`DO_NOT_SELL_SHARE`}`}
          onClick={() => {
            mParticle.mParticleMenuClick({
              categoryName: 'Info Sharing',
              categoryPosition: 'Settings Menu',
              pageSection: 'Settings',
            });
          }}
        >
          <Trans>DO_NOT_SELL_SHARE</Trans>
        </NavbarItem>
      )}
      {!supportCapability(PLATFORM_CAPABILITIES.LIMIT_AD_TRACKING) && (
        <NavbarItem
          navbarKey={NAVBAR_KEYS.SETTINGS}
          back={BACK_BUTTON_CONFIG.SETTINGS_SUBMENU}
          className="app-submenu__item"
          id={adSettingsPath}
          key={adSettingsPath}
          to={`${location.pathname}?page=${adSettingsPath}`}
          isActiveOverride={pageParamValueMatches(adSettingsPath)}
          aria-label={`${t`SUBMENU`} ${t`INFO_SHARING`}`}
          onClick={() => {
            mParticle.mParticleMenuClick({
              categoryName: 'Ad Preferences',
              categoryPosition: 'Settings Menu',
              pageSection: 'Settings',
            });
            JsSpatialNavigation.focus('.settings__header');
          }}
        >
          <Trans>AD_PREFERENCES</Trans>
        </NavbarItem>
      )}
      <NavbarItem
        navbarKey={NAVBAR_KEYS.SETTINGS}
        back={BACK_BUTTON_CONFIG.SETTINGS_SUBMENU}
        className="app-submenu__item"
        id={privacyPolicyPath}
        key={privacyPolicyPath}
        to={`${location.pathname}?page=${privacyPolicyPath}`}
        isActiveOverride={pageParamValueMatches(privacyPolicyPath)}
        aria-label={`${t`SUBMENU`} ${t`PRIVACY_POLICY`}`}
        selectionOverrides={{ left: '.menu-settings' }}
        onClick={() => {
          mParticle.mParticleMenuClick({
            categoryName: 'Privacy Policy',
            categoryPosition: 'Settings Menu',
            pageSection: 'Settings',
          });
        }}
      >
        <Trans>PRIVACY_POLICY</Trans>
      </NavbarItem>
      <NavbarItem
        navbarKey={NAVBAR_KEYS.SETTINGS}
        back={BACK_BUTTON_CONFIG.SETTINGS_SUBMENU}
        className="app-submenu__item"
        id={tosPath}
        key={tosPath}
        to={`${location.pathname}?page=${tosPath}`}
        isActiveOverride={pageParamValueMatches(tosPath)}
        aria-label={`${t`SUBMENU`} ${t`TERMS_OF_SERVICE`}`}
        selectionOverrides={{ left: '.menu-settings' }}
        onClick={() => {
          mParticle.mParticleMenuClick({
            categoryName: 'Terms of Service',
            categoryPosition: 'Settings Menu',
            pageSection: 'Settings',
          });
        }}
      >
        <Trans>TERMS_OF_SERVICE</Trans>
      </NavbarItem>

      <NavbarItem
        navbarKey={NAVBAR_KEYS.SETTINGS}
        back={BACK_BUTTON_CONFIG.SETTINGS_SUBMENU}
        className="app-submenu__item"
        id={cpraPath}
        key={cpraPath}
        to={`${location.pathname}?page=${cpraPath}`}
        isActiveOverride={pageParamValueMatches(cpraPath)}
        aria-label={`${t`SUBMENU`} ${t`CPRA`}`}
        selectionOverrides={
          showDevSettings
            ? { left: '.menu-settings' }
            : { left: '.menu-settings', down: '' }
        }
        onClick={() => {
          mParticle.mParticleMenuClick({
            categoryName: t`CPRA`,
            categoryPosition: 'Settings Menu',
            pageSection: 'Settings',
          });
        }}
      >
        <Trans>CPRA</Trans>
      </NavbarItem>
      {showDevSettings && (
        <NavbarItem
          navbarKey={NAVBAR_KEYS.SETTINGS}
          back={BACK_BUTTON_CONFIG.SETTINGS_SUBMENU}
          className="app-submenu__item"
          id={devPath}
          key={devPath}
          to={`${location.pathname}?page=${devPath}`}
          isActiveOverride={pageParamValueMatches(devPath)}
          aria-label={`${t`SUBMENU`} ${t`DEV_SETTINGS`}`}
          selectionOverrides={{ left: '.menu-settings', down: '' }}
        >
          <Trans>DEV_SETTINGS</Trans>
        </NavbarItem>
      )}
    </Navbar>
  );
}

export default SettingsSubMenu;
