import { v4 as uuidv4 } from 'uuid';
import { getLocalStorageItem, setLocalStorageItem } from './local-storage';

const AD_CONFIG_STORAGE_KEY = 'ad-configuration';
const temporaryAdID = uuidv4();

const initializeAdConfiguration = () => {
  const adConfig = getAdConfig();

  // if the adConfig in local storage is empty we initialize the ad configuration
  if (!adConfig || !adConfig.AD_ID || !adConfig.LMT) {
    const adConfiguration = { LMT: '0', AD_ID: uuidv4() };
    localStorage.setItem(
      AD_CONFIG_STORAGE_KEY,
      JSON.stringify(adConfiguration)
    );
  }
};

// this function retrieves the ad configuration saved in local storage
const getAdConfig = () => {
  return JSON.parse(getLocalStorageItem(AD_CONFIG_STORAGE_KEY));
};

// this function checks on local storage for the value of LAT, if its 1 it retrieves a temporary id that is changed by every session
// if its 0 it retrieves the same identifier the user has saved in local storage
const getAdId = () => {
  const adConfig = getAdConfig();

  let adId = temporaryAdID;

  if (adConfig.LMT === '0') {
    adId = adConfig.AD_ID;
  }

  return adId;
};

// this function retrieves the lat value saved in local storagem, in case it's not there it initialize the configuration
const getLat = () => {
  const adConfig = getAdConfig();

  return adConfig.LMT;
};

// if enabled set to true, set Limit Ad Tracking to 1, user won't be tracked
// if enabled set to false, set Limit Ad Tracking to 0, user will be tracked
const latEnabled = (enabled) => {
  setLat(enabled ? '1' : '0');
};

// this function retrieves the lat value saved in local storagem, in case it's not there it initialize the configuration
const setLat = (lat) => {
  const adConfig = getAdConfig();

  setLocalStorageItem(
    AD_CONFIG_STORAGE_KEY,
    JSON.stringify({ AD_ID: adConfig.AD_ID, LMT: lat })
  );
};

// this function is to reset the value of Advertisement identifier (just allowed when lat is set to 0)
const resetAdId = () => {
  const newAdId = uuidv4();

  const adConfiguration = { LMT: '0', AD_ID: newAdId };
  setLocalStorageItem(AD_CONFIG_STORAGE_KEY, JSON.stringify(adConfiguration));

  return newAdId;
};

export { initializeAdConfiguration, getAdId, getLat, latEnabled, resetAdId };
