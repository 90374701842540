import CONTENT_ROW_TYPES from '../enums/content-row-types';
import { isKey } from './utils';
import { KEYCODES } from '../platform/index';
import { scaleFrom720p } from './scale-from-720p';

const distanceFromMenu = scaleFrom720p(95);
const marginCard = scaleFrom720p(22);

function getAriaLabelForRowItem(
  contentType,
  contentTitle,
  contentDescription,
  rowTitle,
  index,
  badgeTranslation
) {
  let ariaLabelString = '';
  const isFirstItem = index === 0;
  if (contentType === CONTENT_ROW_TYPES.STANDARD && isFirstItem) {
    ariaLabelString = `${rowTitle}. ${contentTitle}`;
  } else if (contentType === CONTENT_ROW_TYPES.STANDARD && !isFirstItem) {
    ariaLabelString = `${contentTitle}`;
  } else if (contentType === CONTENT_ROW_TYPES.DETAILS && isFirstItem) {
    ariaLabelString = `${rowTitle}. ${contentTitle}. ${contentDescription}`;
  } else if (contentType === CONTENT_ROW_TYPES.DETAILS && !isFirstItem) {
    ariaLabelString = `${contentTitle}. ${contentDescription}`;
  } else if (contentType === CONTENT_ROW_TYPES.ORIGINALS && isFirstItem) {
    ariaLabelString = `${rowTitle}. ${badgeTranslation}. ${contentTitle}`;
  } else if (contentType === CONTENT_ROW_TYPES.ORIGINALS && !isFirstItem) {
    ariaLabelString = `${badgeTranslation}. ${contentTitle}`;
  }
  return ariaLabelString;
}

// Keys movement
function isDirectionalKey(keyValue) {
  return isKey(keyValue, [
    KEYCODES.UP,
    KEYCODES.DOWN,
    KEYCODES.LEFT,
    KEYCODES.RIGHT,
  ]);
}

function getMovementDirection(keyValue) {
  let direction = null;
  switch (keyValue) {
    case KEYCODES.UP:
      direction = 'up';
      break;
    case KEYCODES.DOWN:
      direction = 'down';
      break;
    case KEYCODES.LEFT:
      direction = 'left';
      break;
    case KEYCODES.RIGHT:
      direction = 'right';
      break;
    default:
      return null;
  }

  return direction;
}

function getChildrenWidth(carouselRef, type) {
  const totalElementCount = carouselRef?.current?.childElementCount;
  const cardWidth = carouselRef?.current?.firstElementChild?.offsetWidth;

  const widthCount =
    totalElementCount && cardWidth && totalElementCount * cardWidth;

  if (type === CONTENT_ROW_TYPES.DETAILS) {
    return widthCount;
  }

  if (type === CONTENT_ROW_TYPES.ORIGINALS) {
    return widthCount + marginCard;
  }

  return widthCount + distanceFromMenu;
}

export {
  getAriaLabelForRowItem,
  isDirectionalKey,
  getMovementDirection,
  getChildrenWidth,
};
