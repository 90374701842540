import { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import APP_CONFIG from '../../config/app-config.json';
// Styles
import './loading-spinner.scss';

function LoadingSpinner({ className, voiceLabel = 'Loading Spinner' }) {
  const spinnerRef = useRef(null);

  return (
    <img
      alt={voiceLabel}
      aria-label={voiceLabel}
      className={classnames('spinner', className)}
      ref={spinnerRef}
      src={`${APP_CONFIG.PUBLIC_URL}static/media/spinner-v1.png`}
    />
  );
}

LoadingSpinner.propTypes = {
  className: PropTypes.string,
  voiceLabel: PropTypes.string,
};

export default LoadingSpinner;
