const PLAYER_MODULES = {
  BITMOVIN_ENGINE: 'BitmovinEngine',
  ENGINE: 'EngineNative',
  MSE_RENDERER: 'MSERenderer',
  XML: 'XML',
  DASH: 'Dash',
  HLS: 'Hls',
  ABR: 'ABR',
  CONTAINERTS: 'ContainerTS',
  CONTAINER_MP4: 'ContainerMP4',
  SUBTITLES: 'Subtitles',
  SUBTITLES_CEA608: 'SubtitlesCea608',
  SUBTITLES_NATIVE: 'SubtitlesNative',
  SUBTITLES_TTML: 'SubtitlesTtml',
  SUBTITLES_VTT: 'SubtitlesVtt',
  POLYFILL: 'Polyfill',
  STYLE: 'Style',
  UI: 'UI',
  ADVERTISING_CORE: 'AdvertisingCore',
  ADVERTISING_BITMOVIN: 'AdvertisingBitmovin',
  ANALYTICS: 'Analytics',
  DRM: 'Drm',
  TIZEN: 'Tizen',
  WEBOS: 'Webos',
};

export default PLAYER_MODULES;
