const CONTENT_TYPES = {
  EPISODE: 'Full Episode',
  FEATURED_FILM: 'Feature Film',
  MOVIE: 'Movie',
  SERIES: 'Series',
  TRAILER: 'Trailer',
  SEASON: 'Season',
};

export default CONTENT_TYPES;
