import { useEffect } from 'react';
import { i18n } from '@lingui/core';
import { en } from 'make-plural/plurals';
import { I18nProvider } from '@lingui/react';
import { messages as enMessages } from '../locales/en/messages';
import PropTypes from 'prop-types';

const Translations = { enMessages };
const plurals = { en };

const DynamicSwitcher = async (locale) => {
  let catalog;

  try {
    catalog = await import(`../locales/${locale}/messages`);
  } catch (e) {
    catalog = { messages: {} };
  }

  i18n.loadLocaleData(locale, { plurals: plurals[locale] });
  i18n.load(locale, catalog.messages);
  i18n.activate(locale);
};

// Needed for passing snapshot tests
const StaticSwitcher = (locale) => {
  let messages;

  try {
    messages = Translations[locale + 'Messages'];
  } catch (e) {
    messages = {};
  }

  i18n.loadLocaleData(locale, { plurals: plurals[locale] });
  i18n.load(locale, messages);
  i18n.activate(locale);
};

export default function LanguageContext({
  children,
  locale,
  isForTesting = true,
}) {
  useEffect(() => {
    if (!isForTesting) {
      DynamicSwitcher(locale);
    } else {
      StaticSwitcher(locale);
    }
  }, [locale, isForTesting]);

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
}

LanguageContext.displayName = 'LanguageContext';

LanguageContext.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string.isRequired,
  isForTesting: PropTypes.bool,
};
