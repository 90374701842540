import { makeRequest } from '../common/base';

const BROWSE_ALL = 'all';

/**
 * Gets a list of media items
 * @param {string} channelType The type of channel (i.e. Shows, Movies)
 * @param {number} pageNumber The page number to return
 * @param {string} genre The genre to filter by (i.e. Comedy)
 * @param {string} sort The sort order to use (alpha-asc, alpha-desc, latest)
 * @param {number} pageSize The number of items per page
 * @returns {Promise<{}>} A promise containing a JSON data object
 */
async function fetchBrowse(
  channelType,
  pageNumber,
  genre,
  sort,
  pageSize = 60
) {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  const params = {
    enforcemediaRights: true,
    sortOrder: sort || 'alpha-asc',
    pageNumber,
    pageSize,
  };

  const genreType = genre && genre !== BROWSE_ALL ? genre : null;
  const urlSearchParams = new URLSearchParams({
    ...(genreType && { genreType }),
    ...params,
  });

  return makeRequest(
    `/browse/${channelType}?${urlSearchParams.toString()}`,
    requestOptions
  );
}

export { fetchBrowse };
