import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from '@lingui/macro';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import { JsSpatialNavigation } from 'react-js-spatial-navigation';

// components
import Button from '../button/button';
import LoadingSpinner from '../loading-spinner/loading-spinner';
import FocusableSection from '../spatial-navigation/focusable-section';

// Icons
import { ReactComponent as DiamondIcon } from '../../assets/icons/diamond.svg';

// utils
import { getSignedInUser } from '../../utils/signed-in-user';
import { getUrlParamValue } from '../../utils/url';
import { BACK_BUTTON_CONFIG } from '../../config/back-button-config';

import useLoyaltyUser from '../../hooks/use-loyalty-user';

// enums
import ROUTES from '../../enums/routes';
import BUTTON_TYPES from '../../enums/button-types';
import ELEMENT_TYPES from '../../enums/element-types';

import { LOYALTY_USER_POINT_STATUS } from '../../enums/loyalty';

// Style
import './home-points-bar.scss';

function HomePointsBar({ className, selectionOverrides, ...buttonProps }) {
  const [upperMessage, setUpperMessage] = useState(null);
  const [bottomMessage, setBottomMessage] = useState(null);
  const [buttonLabel, setButtonLabel] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const { search } = location;

  const [loading, setLoading] = useState(true);

  const userId = getSignedInUser()?.userId;

  const { loyalty, loading: loadingLoyalty } = useLoyaltyUser(userId);

  const formatPoints = (points) => {
    if (points === 0) {
      return '0';
    }

    return points?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  useEffect(() => {
    const setMessages = () => {
      const messages = {};

      switch (loyalty.userPointsStatus) {
        case LOYALTY_USER_POINT_STATUS.OPTED_IN_NO_POINTS:
          Object.assign(messages, {
            upperMessage: t({
              id:
                loyalty.userPoints === 1
                  ? 'LOYALTY_YOU_HAVE_POINTS_AVAILABLE'
                  : 'LOYALTY_YOU_HAVE_POINTS_AVAILABLE_PLURAL',
              values: { points: formatPoints(loyalty.userPoints) },
            }),
            bottomMessage: t`LOYALTY_SEE_HOW_TO_EARN_POINTS`,
            buttonLabel: t`LOYALTY_LEARN_MORE`,
          });
          break;

        case LOYALTY_USER_POINT_STATUS.OPTED_IN_AVAILABLE_REWARDS_NOT_ENOUGH_POINS:
          Object.assign(messages, {
            upperMessage: t({
              id:
                loyalty.userPoints === 1
                  ? 'LOYALTY_YOU_HAVE_POINTS_AVAILABLE'
                  : 'LOYALTY_YOU_HAVE_POINTS_AVAILABLE_PLURAL',
              values: { points: formatPoints(loyalty.userPoints) },
            }),
            bottomMessage: t({
              id: 'LOYALTY_NEEDED_POINTS_FOR_NEXT_REWARD',
              values: {
                points: formatPoints(
                  loyalty.firstRedeemableReward.points - loyalty.userPoints
                ),
              },
            }),
            buttonLabel: t`LOYALTY_REWARDS_BRAND`,
          });

          break;

        case LOYALTY_USER_POINT_STATUS.OPTED_IN_NO_AVAILABLE_REWARDS:
          Object.assign(messages, {
            upperMessage: t({
              id:
                loyalty.userPoints === 1
                  ? 'LOYALTY_YOU_HAVE_POINTS_AVAILABLE'
                  : 'LOYALTY_YOU_HAVE_POINTS_AVAILABLE_PLURAL',
              values: { points: formatPoints(loyalty.userPoints) },
            }),
            bottomMessage: t`LOYALTY_EARN_FOR_WATCHING`,
            buttonLabel: t`LOYALTY_REWARDS_BRAND`,
          });

          break;

        case LOYALTY_USER_POINT_STATUS.OPTED_IN_AVAILABLE_REWARDS:
          Object.assign(messages, {
            upperMessage: t({
              id:
                loyalty.userPoints === 1
                  ? 'LOYALTY_YOU_HAVE_POINTS_AVAILABLE'
                  : 'LOYALTY_YOU_HAVE_POINTS_AVAILABLE_PLURAL',
              values: { points: formatPoints(loyalty.userPoints) },
            }),
            bottomMessage: t`LOYALTY_SEE_AVAILABLE_REWARDS`,
            buttonLabel: t`LOYALTY_REWARDS_BRAND`,
          });
          break;

        default:
          Object.assign(messages, {
            upperMessage: t`LOYALTY_JOIN_REWARDS_BRAND`,
            bottomMessage: t`LOYALTY_EARN_FOR_WATCHING`,
            buttonLabel: t`LOYALTY_LEARN_MORE`,
          });
      }

      setUpperMessage(messages.upperMessage);
      setBottomMessage(messages.bottomMessage);
      setButtonLabel(messages.buttonLabel);
    };

    setMessages();
    setLoading(false);
  }, [loyalty]);

  useEffect(() => {
    const focus = getUrlParamValue('focus', search);
    if (!loading && !loadingLoyalty && focus === 'points-bar') {
      JsSpatialNavigation.focus('@points-bar-section');
    }
  }, [loading, loadingLoyalty, search, navigate]);

  return !loading && !loadingLoyalty ? (
    <FocusableSection sectionId="points-bar-section">
      <div
        className={classnames('home-points', className)}
        data-test-id="home-points"
      >
        <div className="home-points__info">
          <div className="home-points__info__upper-text">
            <span data-test-id="home-points-upper-text">{upperMessage}</span>
          </div>
          <div className="home-points__info__bottom-text">
            <span data-test-id="home-points-bottom-text">{bottomMessage}</span>
          </div>
        </div>
        <div className="home-points__button">
          <Button
            back={BACK_BUTTON_CONFIG.HERO_MORE_INFO}
            id="home-points-bar-button"
            icon={DiamondIcon}
            aria-label={buttonLabel}
            className="home-points-bar__button join-rewards"
            elementType={ELEMENT_TYPES.BUTTON}
            type={BUTTON_TYPES.ICONBUTTON}
            onClick={() => {
              navigate(`${ROUTES.HOME}?focus=points-bar`, { replace: true });
              navigate(ROUTES.REWARDS, {
                state: { prevPath: ROUTES.HOME },
              });
            }}
            selectionOverrides={selectionOverrides}
            onFocus={(e) => {
              document
                .querySelector('.focusedElement')
                ?.classList.remove('focusedElement');

              e.target.classList.add('focusedElement');

              scrollIntoViewIfNeeded(e.target, {
                centerIfNeeded: true,
              });
            }}
            {...buttonProps}
          >
            {buttonLabel}
          </Button>
        </div>
      </div>
    </FocusableSection>
  ) : (
    <div className={classnames('home-points', className)}>
      <LoadingSpinner />
    </div>
  );
}

HomePointsBar.propTypes = {
  className: PropTypes.string,
  selectionOverrides: PropTypes.object,
};

export default HomePointsBar;
