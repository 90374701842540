const parentalControlsVersion = 1;

const PARENTAL_CONTROLS = {
  KIDS: 'kids',
  TEENS: 'teens',
  GROWN_UPS: 'grown-ups',
  PIN_RESET: 'pin-reset',
  KIDS_ALLOWED_RATINGS: ['G', 'TV-G', 'TV-Y'],
  TEENS_ALLOWED_RATINGS: [
    'PG-13',
    'G',
    'PG',
    'TV-PG',
    'TV-Y7',
    'TV-14',
    'TV-G',
    'TV-Y',
    'TV-Y7FV',
  ],
  MODE_KEY: `parentalControls_v${parentalControlsVersion}`,
  LOCK_KEY: `parentalLockActive_v${parentalControlsVersion}`,
  PIN_KEY: `parentalControlsPin_v${parentalControlsVersion}`,
};

export default PARENTAL_CONTROLS;
