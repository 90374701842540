/* eslint-disable no-console */
import { getFeatureFlagValue } from './feature-flags';

const LOG_PREFIX_TEXT = 'App';
const LOG_PREFIX = `%c ${LOG_PREFIX_TEXT} `; // the '%c' allows for the color styling, extra spaces are intentional
const LOG_PREFIX_STYLE =
  'background: #ff601c; color: #141414; border-radius: 2px;';

const ANALYTICS_PREFIX_TEXT = 'Analytics';
const ANALYTICS_PREFIX = `%c ${ANALYTICS_PREFIX_TEXT} `; // the '%c' allows for the color styling, extra spaces are intentional
const ANALYTICS_PREFIX_STYLE =
  'background: #1c23ff; color: #ffffff; border-radius: 2px;';

const PLAYER_PREFIX_TEXT = 'Player';
const PLAYER_PREFIX = `%c ${PLAYER_PREFIX_TEXT} `; // the '%c' allows for the color styling, extra spaces are intentional
const PLAYER_PREFIX_STYLE =
  'background: #67009b; color: #ffffff; border-radius: 2px;';

/**
 * Log an error to the console.
 * If `APP_ENV` is not 'production' or if `ENABLE_LOGGING` is
 * set to `"true"`, the log will include the Crackle style/prefix
 */
const error = (...args) => {
  const LOG_LEVEL = getFeatureFlagValue('logLevel');
  if (LOG_LEVEL) console.error(LOG_PREFIX, LOG_PREFIX_STYLE, ...args);
  else console.error(LOG_PREFIX_TEXT, ...args);
};
const errorAnalytics = (...args) => {
  const LOG_LEVEL = getFeatureFlagValue('logLevel');

  if (LOG_LEVEL)
    console.error(ANALYTICS_PREFIX, ANALYTICS_PREFIX_STYLE, ...args);
  else console.error(ANALYTICS_PREFIX_TEXT, ...args);
};
const errorPlayer = (...args) => {
  const LOG_LEVEL = getFeatureFlagValue('logLevel');

  if (LOG_LEVEL) console.error(PLAYER_PREFIX, PLAYER_PREFIX_STYLE, ...args);
  else console.error(PLAYER_PREFIX_TEXT, ...args);
};

/**
 * Log an warning message to the console with the Crackle style/prefix.
 * Only logs if `APP_ENV` is not 'production' or if
 * `ENABLE_LOGGING` is set to `"true"`
 */
const warn = (...args) => {
  const LOG_LEVEL = getFeatureFlagValue('logLevel');

  if (LOG_LEVEL === 'error') return;
  console.warn(LOG_PREFIX, LOG_PREFIX_STYLE, ...args);
};
const warnAnalytics = (...args) => {
  const LOG_LEVEL = getFeatureFlagValue('logLevel');

  if (LOG_LEVEL === 'error') return;
  console.warn(ANALYTICS_PREFIX, ANALYTICS_PREFIX_STYLE, ...args);
};
const warnPlayer = (...args) => {
  const LOG_LEVEL = getFeatureFlagValue('logLevel');

  if (LOG_LEVEL === 'error') return;
  console.warn(PLAYER_PREFIX, PLAYER_PREFIX_STYLE, ...args);
};

/**
 * Log an info message to the console with the Crackle style/prefix.
 * Only logs if `APP_ENV` is not 'production' or if
 * `ENABLE_LOGGING` is set to `"true"`
 */
const info = (...args) => {
  const LOG_LEVEL = getFeatureFlagValue('logLevel');

  if (LOG_LEVEL === 'error' || LOG_LEVEL === 'warn') return;
  console.info(LOG_PREFIX, LOG_PREFIX_STYLE, ...args);
};
const infoAnalytics = (...args) => {
  const LOG_LEVEL = getFeatureFlagValue('logLevel');

  if (LOG_LEVEL === 'error' || LOG_LEVEL === 'warn') return;
  console.info(ANALYTICS_PREFIX, ANALYTICS_PREFIX_STYLE, ...args);
};
const infoPlayer = (...args) => {
  const LOG_LEVEL = getFeatureFlagValue('logLevel');

  if (LOG_LEVEL === 'error' || LOG_LEVEL === 'warn') return;
  console.info(PLAYER_PREFIX, PLAYER_PREFIX_STYLE, ...args);
};

/**
 * Log an debug message to the console with the Crackle style/prefix.
 * Only logs if `APP_ENV` is not 'production' or if
 * `ENABLE_LOGGING` is set to `"true"`
 */
const debug = (...args) => {
  const LOG_LEVEL = getFeatureFlagValue('logLevel');

  if (LOG_LEVEL !== 'debug') return;
  console.debug(LOG_PREFIX, LOG_PREFIX_STYLE, ...args);
};
const debugAnalytics = (...args) => {
  const LOG_LEVEL = getFeatureFlagValue('logLevel');

  if (LOG_LEVEL !== 'debug') return;
  console.debug(ANALYTICS_PREFIX, ANALYTICS_PREFIX_STYLE, ...args);
};
const debugPlayer = (...args) => {
  const LOG_LEVEL = getFeatureFlagValue('logLevel');

  if (LOG_LEVEL !== 'debug') return;
  console.debug(PLAYER_PREFIX, PLAYER_PREFIX_STYLE, ...args);
};

const logger = {
  error,
  debug,
  warn,
  info,
  errorAnalytics,
  warnAnalytics,
  debugAnalytics,
  infoAnalytics,
  errorPlayer,
  warnPlayer,
  debugPlayer,
  infoPlayer,
};
export default logger;
