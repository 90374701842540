import {
  getBrowserClientNameAndVersion,
  getPageLoadTime,
  getUserFriendlyPageName,
} from './utils';
import { getUrlParamValue } from './url';
import {
  isTv,
  getNetworkInformation,
  getPlatformDeviceName,
  getDeviceOS,
  getDeviceOsVersion,
  getDeviceInformation,
} from '../platform/index';
import logger from './logger';
import { getLocalStorageItem } from './local-storage';
import LOCAL_STORAGE from '../enums/local-storage';
import APP_CONFIG from '../config/app-config.json';

/**
 * Sends a custom mParticle platform launch event
 * @param {object} config Crackle App Config
 */
export default async function mParticlePlatformLaunch(isDeeplink, appResumed) {
  try {
    const deviceInfo = getDeviceInformation();
    const { name, version } = getBrowserClientNameAndVersion();

    let referrer = isDeeplink ? 'Global Search' : 'N/A';
    if (isDeeplink && getUrlParamValue('cmpid')) {
      referrer =
        getUrlParamValue('cmpid') === 'Vizio_Remote_Launch'
          ? 'Remote button'
          : 'Paid Ad';
    }

    const defaultEventData = {
      platform_client_ip_v4: '',
      platform_client_ip_v6: '',
      platform_client_os: getDeviceOS(),
      platform_client_os_version: getDeviceOsVersion(),
      platform_customer_id: '',
      platform_customer_type: '',
      platform_deeplink_referrer: referrer,
      platform_launch_state: appResumed ? 'suspended' : 'initial launch',
      platform_version:
        `${APP_CONFIG.PLATFORM}_${APP_CONFIG.PLATFORM_ID}` || null,
      platform_browser_client_name: name,
      platform_browser_client_version: version,
      platform_campaign_tracking_code: getUrlParamValue('cmpid'),
      platform_deeplink_entry: isDeeplink,
      platform_page_load_time: getPageLoadTime(),
      platform_page_name: getUserFriendlyPageName(),
      platform_page_url: window.location.href,
    };
    let newEventData;

    if (isTv()) {
      const networkInfo = await getNetworkInformation();

      const clientRegion = getLocalStorageItem(LOCAL_STORAGE.CLIENT_REGION);

      newEventData = {
        ...defaultEventData,
        platform_device_name: getPlatformDeviceName(),
        platform_name: `${APP_CONFIG.PLATFORM}`,
        platform_device_id: deviceInfo.duid || null,
        platform_client_ip_v4: networkInfo.ip,
        platform_country_code: clientRegion?.toLowerCase() || null,
      };
    }

    window.mParticle.logEvent(
      'Platform Launch',
      window.mParticle.EventType.Navigation,
      isTv() ? newEventData : defaultEventData
    );
  } catch (e) {
    logger.error('Error occurred sending mParticlePlatformLaunch', e);
  }
}
