/* eslint-disable no-underscore-dangle */

class Content {
  constructor({
    id,
    parentId,
    externalId,
    type,
    whyItCrackles,
    title,
    slug,
    shortDescription,
    mediumDescription,
    longDescription,
    releaseDate,
    rating,
    genres,
    credits,
    loyaltyPoints,
    isExclusive,
    isExpiringSoon,
    expireDate,
    isOriginal,
    remainingDays,
    isAvailable,
  }) {
    this._id = id;
    this._parentId = parentId;
    this._externalId = externalId;
    this._type = type;
    this._whyItCrackles = whyItCrackles;
    this._title = title;
    this._slug = slug;
    this._shortDescription = shortDescription;
    this._mediumDescription = mediumDescription;
    this._longDescription = longDescription;
    this._releaseDate = new Date(releaseDate);
    this._rating = rating;
    this._credits = credits;
    this._loyaltyPoints = loyaltyPoints;
    this._isExclusive = isExclusive;
    this._isExpiringSoon = isExpiringSoon;
    this._expireDate = expireDate;
    this._isOriginal = isOriginal;
    this._genres = [];
    this._remainingDays = remainingDays;
    this._isAvailable = isAvailable;

    genres.forEach((genre) => {
      this.addGenre(genre);
    });
  }

  // getters and setters
  get id() {
    return this._id;
  }

  set id(id) {
    this._id = id;
  }

  get parentId() {
    return this._parentId;
  }

  set parentId(parentId) {
    this._parentId = parentId;
  }

  get externalId() {
    return this._externalId;
  }

  set externalId(externalId) {
    this._externalId = externalId;
  }

  get type() {
    return this._type;
  }

  set type(type) {
    this._type = type;
  }

  get whyItCrackles() {
    return this._whyItCrackles;
  }

  set whyItCrackles(whyItCrackles) {
    this._whyItCrackles = whyItCrackles;
  }

  get title() {
    return this._title;
  }

  set title(title) {
    this._title = title;
  }

  get slug() {
    return this._slug;
  }

  set slug(slug) {
    this._slug = slug;
  }

  get shortDescription() {
    return this._shortDescription;
  }

  set shortDescription(shortDescription) {
    this._shortDescription = shortDescription;
  }

  get mediumDescription() {
    return this._mediumDescription;
  }

  set mediumDescription(mediumDescription) {
    this._mediumDescription = mediumDescription;
  }

  get longDescription() {
    return this._longDescription;
  }

  set longDescription(longDescription) {
    this._longDescription = longDescription;
  }

  get releaseDate() {
    return this._releaseDate;
  }

  set releaseDate(releaseDate) {
    this._releaseDate = releaseDate;
  }

  get rating() {
    return this._rating;
  }

  set rating(rating) {
    this._rating = rating;
  }

  get credits() {
    return this._credits;
  }

  set credits(credits) {
    this._credits = credits;
  }

  get loyaltyPoints() {
    return this._loyaltyPoints;
  }

  set loyaltyPoints(loyaltyPoints) {
    this._loyaltyPoints = loyaltyPoints;
  }

  get isExclusive() {
    return this._isExclusive;
  }

  set isExclusive(isExclusive) {
    this._isExclusive = isExclusive;
  }

  get isExpiringSoon() {
    return this._isExpiringSoon;
  }

  set isExpiringSoon(isExpiringSoon) {
    this._isExpiringSoon = isExpiringSoon;
  }

  get expireDate() {
    return this._expireDate;
  }

  set expireDate(expireDate) {
    this._expireDate = expireDate;
  }

  get isOriginal() {
    return this._isOriginal;
  }

  set isOriginal(isOriginal) {
    this._isOriginal = isOriginal;
  }

  get genres() {
    return this._genres;
  }

  addGenre(genre) {
    this._genres.push(genre);
  }

  removeGenre(genreId) {
    const genres = this._genres.filter((genre) => genre.id === genreId);

    this._genres = genres;
  }

  removeGenres() {
    this._genres = [];
  }

  getReleaseYear() {
    return this._releaseDate.getFullYear();
  }

  get isAvailable() {
    return this._isAvailable;
  }

  set isAvailable(isAvailable) {
    this._isAvailable = isAvailable;
  }

  get remainingDays() {
    return this._remainingDays;
  }

  set remainingDays(remainingDays) {
    this._remainingDays = remainingDays;
  }
}

export default Content;
